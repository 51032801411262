"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAnamnesetApi = void 0;
function createAnamnesetApi(api) {
    return {
        // from r4c
        setCharlyAnamneseConfig: (charlyAnameseConfig) => api.r4cInstance.post(`/anamnese/setCharlyAnamneseConfig`, { charlyAnameseConfig }),
        updateCharlyAnamneseConfig: () => api.r4cInstance.post(`/anamnese/updateCharlyAnamneseConfig`),
        assignAnamneseTask: (params) => api.r4cInstance.post(`/anamnese/assignAnamneseTask`, params),
        shareAnamneseTaskPerMail: (params) => api.r4cInstance.post(`/anamnese/shareAnamneseTaskPerMail`, params),
        // Tablet & Anamnese App
        saveAnamneseResponseTablet: (params) => api.r4cInstance.post(`/anamnese/saveAnamneseResponseTablet`, params),
        saveAnamneseResponseShared: (params) => api.r4cInstance.post(`/anamnese/saveAnamneseResponseShared`, params),
        saveAnamneseResponseTakeover: (params) => api.r4cInstance.post(`/anamnese/saveAnamneseResponseTakeover`, params),
        saveAnamneseResponsePublic: (params) => api.r4cDispatch.post(`/anamnese/saveAnamneseResponsePublic`, params), // r4cDispatch, because r4cDispatch created the challenge
        previewAnamneseDocument: (params) => api.r4cInstance.post(`/anamnese/previewAnamneseDocument`, params),
        generateQuestionnairePrintVersion: (params) => api.r4cInstance.post(`/anamnese/generateQuestionnairePrintVersion`, params),
        // Review App
        getAnamneseResponse: (responseId) => api.r4cInstance.post(`/anamnese/getAnamneseResponse`, {
            responseId,
        }),
        getAllAnamneseResponses: () => api.r4cInstance.post(`/anamnese/getAllAnamneseResponses`),
        getQuestionnaireGroups: () => api.r4cInstance.post(`/anamnese/getQuestionnaireGroups`),
        createAnamneseTask: (params) => api.r4cInstance.post(`/anamnese/createAnamneseTask`, params),
        getAnamneseResponseMetaData: (patid) => api.r4cInstance.post(`/anamnese/getAnamneseResponseMetaData`, {
            patid,
        }),
        reviewedAnamneseResponse: (params) => api.r4cInstance.post(`/anamnese/reviewedAnamneseResponse`, params),
        manualAnamneseResponse: (params) => api.r4cInstance.post(`/anamnese/manualAnamneseResponse`, params),
        deleteAnamneseResponse: (responseId) => api.r4cInstance.delete(`/anamnese/deleteAnamneseResponse`, {
            responseId,
        }),
        assignTabletPrefillAnamneseTask: (params) => api.r4cInstance.post(`/anamnese/assignTabletPrefillAnamneseTask`, params),
        sharePrefillAnamneseTaskPerMail: (params) => api.r4cInstance.post(`/anamnese/sharePrefillAnamneseTaskPerMail`, params),
        takeOverTabletTask: (params) => api.r4cInstance.post(`/anamnese/takeOverTabletTask`, params),
        translateStrings: (params) => api.r4cInstance.post(`/anamnese/translateStrings`, params),
        savePrefillAnswers: (params) => api.r4cInstance.post(`/anamnese/savePrefillAnswers`, params),
        findCharlyPatientsForResponse: (params) => api.r4cInstance.post(`/anamnese/findCharlyPatientsForResponse`, params),
        findCharlyPatientById: (params) => api.r4cInstance.post(`/anamnese/findCharlyPatientById`, params),
        // external questionnaire flow without patient context
        createExternQuestionnaireFlow: (questionnaireId) => api.r4cDispatch.get(`/anamnese/createExternQuestionnaireFlow/${questionnaireId}`),
    };
}
exports.createAnamnesetApi = createAnamnesetApi;
