"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4cAppsApi = void 0;
function createR4cAppsApi(api) {
    return {
        editR4cApps: (payload) => api.r4cDispatch.post(`/r4capps/r4c-edit-apps`, payload),
        editRechenzentrum: (r4chost, payload) => api.r4c.post(r4chost, `/r4capps/r4c-edit-rz`, payload),
        loadClientProfile: (cid) => api.r4cDispatch.get(`/r4capps/profile`, { clientIdHeader: cid }),
    };
}
exports.createR4cAppsApi = createR4cAppsApi;
