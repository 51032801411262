"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendCommandToUi = void 0;
function sendCommandToUi(command, { skipLog = false } = {}) {
    var _a;
    if (!skipLog) {
        console.log('[vui] sending', command);
    }
    (_a = window.top) === null || _a === void 0 ? void 0 : _a.postMessage(
    // preserve undefined values: required for addToViewState to remove keys
    JSON.stringify(command, (k, v) => (v === undefined ? null : v)), '*');
}
exports.sendCommandToUi = sendCommandToUi;
