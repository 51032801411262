"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rules = void 0;
const config_1 = require("./config");
const rulesInternal = {
    required: (value) => !!value || 'Benötigt.',
    email: (value) => {
        // empty field is valid
        if (!value) {
            return true;
        }
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Keine gültige E-Mail.';
    },
    emailDelimited: (value) => {
        const pattern = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})(,\s[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})*$/;
        return value
            ? pattern.test(value) ||
                'Keine gültige E-Mail-Liste. Bitte in der Form: "mail@domain.de, mail2@domain.de" eingeben.'
            : true;
    },
    regex: (value) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            new RegExp(value);
            return true;
        }
        catch (e) {
            return 'Kein gültiger Regex! Bitte korrigieren Sie Ihre Eingabe.';
        }
    },
    ip: (value) => {
        const pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const hostnamePattern = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
        return pattern.test(value) || hostnamePattern.test(value) || 'Keine gültige IP-Adresse oder Hostname.';
    },
};
// disable some validation on dev
if (config_1.isDev) {
    rulesInternal.email = () => true;
}
exports.rules = rulesInternal;
