"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAdminApi = void 0;
function createAdminApi(api) {
    return {
        profileLocationLookup: (cid) => api.metrics.get(`/admin/locationlookup/${cid}`),
        mergeR4cToMetricsProfile: (r4cClientid, metricsClientid) => api.metrics.post(`/admin/merger4ctometricsprofile`, {
            r4cClientid,
            metricsClientid,
        }),
        mergeMetricsToR4cProfile: (metricsClientid, r4cClientid) => api.metrics.post(`/admin/mergemetricstor4cprofile`, {
            metricsClientid,
            r4cClientid,
        }),
        charlyanalyticsaboanlegen: (cid) => api.metrics.post(`/admin/charlyanalyticsaboanlegen/${cid}`),
        deleteUserCompletely: (email) => api.metrics.delete(`/admin/user/completely/${email}`),
        allAnamneseSettings: () => api.metrics.get(`/admin/allAnamneseSettings`),
        debugSearch: (params) => api.metrics.post(`/admin/debugsearch`, params),
        failedCharlyUploads: () => api.metrics.get(`/admin/failedCharlyUploads`),
        loadClientSettings: (cid) => api.metrics.get(`api/settings`, { clientIdHeader: cid }),
        updateClientSettings: (cid, settings) => api.metrics.put(`api/settings`, settings, { clientIdHeader: cid }),
        loadDashboard: () => api.metrics.get(`/admin/dashboard`),
    };
}
exports.createAdminApi = createAdminApi;
