"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uhrFilter = void 0;
exports.uhrFilter = {
    filters: {
        uhr(value) {
            if (value.indexOf(':') >= 0) {
                return `${value} Uhr`;
            }
            return `${value}:00 Uhr`;
        },
    },
};
