"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.german = void 0;
exports.german = {
    // for filter panel
    page: 'Seite',
    more: 'mehr',
    to: 'bis',
    of: 'von',
    next: 'nächste',
    last: 'letzte',
    first: 'erste',
    previous: 'vorige',
    // for set filter
    selectAll: 'Alle',
    searchOoo: 'Suche...',
    blanks: 'Leer',
    // for number filter and text filter
    filterOoo: 'Filtern...',
    applyFilter: 'Filter anwenden...',
    equals: 'Gleich',
    notEquals: 'Ungleich',
    // for number filter
    lessThan: 'Kleiner',
    greaterThan: 'Größer',
    lessThanOrEqual: 'Kleiner gleich',
    greaterThanOrEqual: 'Größer gleich',
    inRange: 'Im Bereich',
    // for text filter
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    startsWith: 'Beginnt mit',
    endsWith: 'Endet mit',
    // the header of the default group column
    group: 'Gruppieren',
    // tool panel
    columns: 'Spalten',
    filters: 'Filter',
    rowGroupColumns: 'Gruppierungs Spalten',
    rowGroupColumnsEmptyMessage: 'Spalte draggen zum Gruppieren',
    valueColumns: 'laValue Cols',
    pivotMode: 'laPivot-Mode',
    groups: 'Gruppen',
    values: 'Werte',
    pivots: 'Pivots',
    valueColumnsEmptyMessage: 'Spalte draggen zum Aggregieren',
    pivotColumnsEmptyMessage: 'Spalte draggen für Pivot',
    toolPanelButton: 'Toolbox',
    // other
    noRowsToShow: 'Keine Datensätze vorhanden',
    loadingOoo: 'Lade Datensätze...',
    // enterprise menu
    pinColumn: 'Spalten fix',
    valueAggregation: 'laValue Agg',
    autosizeThiscolumn: 'Spaltenbreite automatisch',
    autosizeAllColumns: 'Alle Spaltenbreiten automatisch',
    groupBy: 'Gruppieren nach',
    ungroupBy: 'Ungruppieren nach',
    resetColumns: 'Spalten zurücksetzen',
    expandAll: 'Alle aufklappen',
    collapseAll: 'Alle zuklappen',
    toolPanel: 'Tool Panel',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
    // enterprise menu pinning
    pinLeft: 'Spalte links festsetzen <<',
    pinRight: 'Spalte rechts festsetzen >>',
    noPin: 'Spalte nicht festsetzen <>',
    // enterprise menu aggregation and status bar
    sum: 'Summe',
    min: 'Min',
    max: 'Max',
    none: 'Nichts',
    count: 'Anzahl',
    average: 'Durchschnitt',
    // standard menu
    copy: 'Kopieren',
    copyWithHeaders: 'Kopieren mit Überschrift',
    ctrlC: 'Strg + C',
    paste: 'Einfügen',
    ctrlV: 'Strg + V',
};
