"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFactoringApi = void 0;
function createFactoringApi(api) {
    return {
        getClientRechenzentren: (r4chost, secretOverride = '', includeVeraltet = false) => api.r4c.get(r4chost, `/factoring/clientrechenzentren`, {
            query: Object.assign({ secret: secretOverride }, (includeVeraltet ? { includeVeraltet: '1' } : {})),
        }),
        getPraxisDaten: (secretOverride = '', includeVeraltet = false) => api.r4cDispatch.get(`/factoring/praxisdaten`, {
            query: Object.assign({ secret: secretOverride }, (includeVeraltet ? { includeVeraltet: '1' } : {})),
        }),
        updateClientRechenzentren: (r4chost, params) => api.r4c.post(r4chost, `/factoring/clientrechenzentren`, params),
        receiveConsentAgreementShared: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreementShared`, params),
        receiveConsentAgreementTakeover: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreementTakeover`, params),
        receiveConsentAgreement: (params) => api.r4cInstance.post(`/factoringinit/receiveConsentAgreement`, params),
        previewEweDocument: (params) => api.r4cInstance.post(`/factoringinit/previewEweDocument`, params, {
            responseType: 'blob',
        }),
        downloadEweDocument: (params) => api.r4cInstance.post(`/factoringinit/downloadEweDocument`, params, {
            responseType: 'blob',
        }),
        initializeDesktopR4c: () => api.r4cDispatch.post(`/factoring/initializeDesktopR4c`),
    };
}
exports.createFactoringApi = createFactoringApi;
