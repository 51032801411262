"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFeatureAlreadySelectedElseWhere = exports.featureMatrix = exports.findAppByFeature = exports.findAppChoiceByFeature = exports.findAppChoice = exports.paketeR4cMap = exports.paketeR4c = void 0;
const lodash_1 = require("lodash");
const types_1 = require("../../../types");
exports.paketeR4c = [
    {
        id: types_1.R4CAPP.ANAMNESE,
        name: 'Anamnese',
        live: true,
        rendertyp: 'CARD',
        beschreibung: `Mit rose4charly Anamnese kannst du Fragebögen und Dokumente einfach selber digital erstellen und direkt aus charly heraus auf einem mobilen Endgerät deinem Patienten anbieten. Antworten werden direkt in charly übernommen und gespeichert, ohne aufwändigen manuellen Abgleich.`,
        hint: `Du kannst Anamnese 30 Tage lang gratis testen!`,
        featureHandling: 'ADITIV',
        choices: [
            // {
            //   feature: FEATURES.ANAMNESE_WHITE,
            //   includesOtherFeatures: [FEATURES.DOCUMENTS_SMALL],
            //   name: 'White',
            //   beschreibung: `rose4charly Anamnese - Fragebögen und Dokumente direkt aus charly heraus auf mobilen Endgerät dem Patienten anbieten. Patienten Antworten werden direkt in charly übernommen und gespeichert, ohne aufwändigen manuellen Abgleich`,
            //   highlights: {
            //     Workflow: [
            //       {
            //         txt: 'Erinnerung an Erneuerung der Anamnese',
            //         i: 'Über die Erinnerungsfunktion wird der Benutzer automatisch an die Erneuerung der Anamnese erinnert, wenn diese nicht mehr aktuell ist (z.B. älter als 24 Monate).',
            //       },
            //       {
            //         txt: 'Antworten automatisch in charly übernehmen',
            //         i: 'Die Antworten im Anamnesebogen werden nach Benutzer-Freigabe automatisch in charly übertragen. Ein aufwändiges händisches Übertragen entfällt.',
            //       },
            //       {
            //         txt: 'Speichern der Anamnese in der Ablage',
            //         i: 'Der Anamnesebogen wird zusätzlich als pdf-Datei in der charly-Ablage gespeichert.',
            //       },
            //       {
            //         txt: 'Dokument per E-Mail verschicken',
            //         i: 'Der Patient kann sich seinen ausgefüllten Bogen per E-Mail zuschicken.',
            //       },
            //     ],
            //     Fragen: [
            //       { txt: 'Übernahme der charly-Fragen', i: 'Die Anamnesefragen aus charly werden automatisch übernommen.' },
            //       {
            //         txt: 'Anzahl der Anamnesefragen pro Seite frei wählbar',
            //         i: 'Die Anamnesefragen werden zur besseren Übersicht auf mehrere Seiten verteilt. Die Anzahl der Fragen je Seite ist frei wählbar.',
            //       },
            //     ],
            //   },
            //   price: 0,
            //   itemPrice: 19,
            //   itemsIncluded: '1',
            //   itemName: 'Fragebogen',
            //   itemNamePlural: 'Fragebögen',
            //   item2Price: 0.79,
            //   items2Included: '10',
            //   item2Name: 'Dokument',
            //   item2NamePlural: 'Dokumente',
            // },
            // {
            //   feature: FEATURES.ANAMNESE_RED,
            //   includesOtherFeatures: [FEATURES.DOCUMENTS_BIG],
            //   name: 'Red',
            //   beschreibung: `Für alle, die ihre Fragebögen und Dokumente weiter individuellisieren möchten. Über eigene Fragen gibt es viele Möglichkeiten direkt und individuell auf den Patienten einzugehen. Bei der Überprüfung des ausgefüllten Bogens werden geänderte Angaben farblich hervorgehoben, bevor diese in charly übernommen werden.`,
            //   highlights: {
            //     Workflow: [
            //       {
            //         txt: 'Darstellung der Änderungen zu vorherigen Fragebögen',
            //         i: 'Bei der Überprüfung des ausgefüllten Bogens, werden geänderte Angaben farblich hervorgehoben, bevor diese in charly übernommen werden.',
            //       },
            //       {
            //         txt: 'Link zur Anamnese per E-Mail verschicken',
            //         i: 'TODO',
            //         explanation: { link: '*', txt: 'Kommt bald, geplant für Q3/23' },
            //       },
            //     ],
            //     'Individuelles Design': [
            //       // { txt: 'Praxisnamen frei editierbar' ,i:''},
            //       {
            //         txt: 'Praxislogo hinterlegen',
            //         i: 'Durch das eigene Praxislogo, können Dokumente weiter individualisiert werden.',
            //       },
            //       {
            //         txt: 'Akzentfarbe konfigurierbar',
            //         i: 'Durch eine einstellbare Farbe, können Dokumente weiter individualisiert werden.',
            //       },
            //       { txt: 'Textfelder frei editierbar', i: 'Bestimmte Felder können als Pflichtfelder definiert werden.' },
            //     ],
            //     Fragen: [
            //       {
            //         txt: 'Vorhandene Anamnesefragen ausblenden',
            //         i: 'Anamnesefragen aus dem Charly können ausgeblendet werden.',
            //       },
            //       {
            //         txt: 'Reihenfolge der Anamnesefragen frei einstellbar',
            //         i: 'Die Reihenfolge der Anamnesefragen wird aus dem Charly übernommen, sie ist frei veränderlich.',
            //       },
            //       {
            //         txt: 'Pflichtfelder definierbar',
            //         i: 'Bestimmte Fragen oder Felder können als Pflichtfelder definiert werden.',
            //       },
            //       {
            //         txt: 'Eigene Anamnese Fragen',
            //         i: 'Zusätzlich zu den in charly hinterlegten Fragen können eigene Fragen erstellt werden.',
            //       },
            //       {
            //         txt: 'Freie Textblöcke',
            //         i: 'Es können freie Textblöcke mit Formatierungen erstellt werden.',
            //       },
            //       {
            //         txt: 'Fragetypen: Text, Anzahl, Auswahl',
            //         i: 'Fragen der Typen Text, Anzahl und Auswahl können genutzt werden.',
            //       },
            //     ],
            //   },
            //   price: 69,
            //   itemPrice: 9,
            //   itemsIncluded: '2',
            //   itemName: 'Fragebogen',
            //   itemNamePlural: 'Fragebögen',
            //   item2Price: 0.49,
            //   items2Included: '100',
            //   item2Name: 'Dokument',
            //   item2NamePlural: 'Dokumente',
            // },
            // {
            //   feature: FEATURES.ANAMNESE_BLACK,
            //   includesOtherFeatures: [FEATURES.DOCUMENTS_BIG],
            //   name: 'Black',
            //   beschreibung: `Das ganze Spektrum an Gesundheits- und anderen Fragen mit unterschiedlichsten Fragetypen, z.B. Auf einer Skala von 1-10: Wie zufrieden sind Sie mit der Farbe Ihrer Zähne. Neben Fragebögen sind bereits eigene Dokumente für z.B. Aufklärung oder Kostenvereinbarungen enthalten.`,
            //   highlights: {
            //     Fragen: [
            //       {
            //         txt: 'Export Auswertung zu den Fragen',
            //         i: 'Mit der Exportfunktion können die Antworten in Fragebögen über einen bestimmten Zeitraum ausgegeben werden. Damit ist es zum Beispiel möglich auszuwerten, wie erfolgreich eine Marketing-Maßnahme um Neupatienten zu gewinnen war.',
            //         explanation: { link: '*', txt: 'Kommt bald, geplant für Q3/23' },
            //       },
            //       {
            //         txt: 'Fragetypen: Scala, Bereich, Leistungen',
            //         i: 'Zusätzlich können weitere Fragentypen genutzt werden, zum Beispiel Bewertung: Wie zufrieden waren Sie mit ihrer heutigen Behandlung (1-5 Sterne) Skala: Auf einer Skale von 1-10 wie wahrscheinlich werden Sie unsere Praxis weiterempfehlen? Bereich: Wie wichtig ist Ihnen Ihre Mundgesundheit (sehr wichtig - nicht so wichtig)',
            //       },
            //     ],
            //   },
            //   price: 119,
            //   itemPrice: 0,
            //   itemsIncluded: 'Unbegerenzt',
            //   itemName: 'Fragebogen',
            //   itemNamePlural: 'Fragebögen',
            //   item2Price: 0,
            //   items2Included: 'Unbegrenzt',
            //   item2Name: 'Dokument',
            //   item2NamePlural: 'Dokumente',
            // },
            {
                feature: types_1.FEATURES.ANAMNESE_DIAMOND,
                includesOtherFeatures: [types_1.FEATURES.DOCUMENTS_FLAT],
                name: 'Diamond',
                beschreibung: `Ein Preis für alle Dokumente, egal woher, aus den charly Vorlagen oder aus rose4charly. Auswählen, ausfüllen, unterschreiben, fertig.`,
                highlights: {},
                price: 119,
                itemPrice: 0,
                itemsIncluded: 'Unbegerenzt',
                itemName: 'Fragebogen',
                itemNamePlural: 'Fragebögen',
                item2Price: 0,
                items2Included: 'Unbegrenzt',
                item2Name: 'Dokument',
                item2NamePlural: 'Dokumente',
            },
        ],
    },
    {
        id: types_1.R4CAPP.FACTORING,
        name: 'Factoring',
        live: true,
        rendertyp: 'CARD',
        beschreibung: `rose4charly Factoring unterstützt die Praxis jederzeit die volle Kontrolle beim Factoring zu behalten, indem wiederkehrende Aufgaben automatisiert werden.

Einwilligungserklärungen und Bonitätsanfragen für Patienten werden direkt aus dem Karteireiter Patient und Behandlungsblatt in charly ausgelöst und verwaltet. Über die digitale Einwilligungserklärung können diese direkt auf einem verbundenen Tablet unterschrieben werden. Der aktuelle Status der Bonitätsanfrage und das verbleibende Budget werden je nach Rechenzentrum tagesaktuell angezeigt. Abgelaufene Anfragen werden vollautomatisch erneuert, ohne dass es eine Benutzerinteraktion erfordert. rose4charly Factoring ist mit den meisten Rechenzentren am deutschen Dentalmarkt verbunden.`,
        hint: `Du kannst Factoring 30 Tage lang gratis testen!`,
        featureHandling: 'ADITIV',
        choices: [
            {
                feature: types_1.FEATURES.FACTORING,
                name: 'Factoring Basic',
                beschreibung: `Mit Factoring Basic können Einwilligungserklärungen (EWE) und Ankaufsvoranfragen für Patienten an zentraler Stelle innerhalb von charly durchgeführt, angezeigt und verwaltet werden. Damit sorgt r4c schnell und auf einen Blick für gültige Factoring Voraussetzungen und schafft Klarheit und Transparenz.`,
                highlights: {
                    Feature: [
                        { txt: 'Jederzeit Klarheit über Factoring-Status' },
                        { txt: 'Direkt in charly integriert' },
                        { txt: 'Gültigkeitsprüfung & Überwachung der EWE' },
                        { txt: 'Durchführen & Überwachung von Bonitätsanfragen' },
                        { txt: 'Budgetüberwachung' },
                        {
                            txt: '2000 Patienten / Monat',
                            explanation: {
                                link: '*',
                                txt: `Für darüber hinaus gehende Aufrufe fallen pro 1.000 Patienten-Aufrufe Gebühren in Höhe von je 20,00 EUR zzgl. der gesetzlichen Umsatzsteuer pro Kalendermonat an. Unter dem Aufruf eines Patienten wird die Bereitstellung und Anzeige von faktoringbezogenen Daten in r4c verstanden, bei denen ein anderer Status als „keine EWE“ angezeigt wird.`,
                            },
                        },
                    ],
                },
                price: 49,
                itemPrice: 0,
                itemName: 'Patientenaufruf',
                itemNamePlural: 'Patientenaufrufe',
                itemsIncluded: '2000',
            },
            {
                feature: types_1.FEATURES.FACTORINGEWE,
                name: 'Factoring EWE',
                nameSpecial: '+ digitale Einwilligungserklärung',
                beschreibung: `Hole noch mehr aus r4c heraus! Mit der digitalen Einwilligungserklärung ist es mit einem Tablet (z.B. iPad oder Android) möglich, Patienten digital unterschreiben zu lassen. Das digitale Dokument wird sofort in der charly Ablage als pdf-Datei hinterlegt und ist über das Behandlungsblatt jederzeit abrufbar. Dies führt zu einer weiteren erheblichen Zeitersparnis und verhindert Fehler bei der Bearbeitung von Einwilligungen auf Papier.`,
                highlights: {
                    Feature: [
                        { txt: 'Digitale Erfassung & Verwaltung der EWE' },
                        { txt: 'Zeitersparnis bis zu 5 Minuten pro Patient' },
                    ],
                },
                price: 79,
                itemPrice: 0,
                itemName: 'Patientenaufruf',
                itemNamePlural: 'Patientenaufrufe',
                itemsIncluded: '2000',
            },
        ],
    },
    {
        id: types_1.R4CAPP.DOCUMENTS,
        name: 'Documents',
        live: true,
        rendertyp: 'CARD',
        beschreibung: `Mit rose4charly Documents kannst du direkt im und aus dem charly heraus Dokumente erstellen und signieren. Briefe und andere Dokumente wie Aufklärungs- oder Mehrkostenvereinbarungen können aus der Ablage mit einem Knopfdruck auf einem verbundenen Tablet angezeigt und direkt unterschrieben werden. Auf Wunsch kann sich der Patient seine Kopie direkt per Mail zuschicken lassen. Im Anschluss wird das unterschriebene Dokument in der charly Ablage archiviert und ist über diese und das Behandlungsblatt jederzeit abrufbar.`,
        hint: `Die ersten 20 Dokumente gratis erstellen!`,
        featureHandling: 'EXKLUSIV',
        choices: [
            {
                feature: types_1.FEATURES.DOCUMENTS_SMALL,
                name: 'Wenig Schreiber',
                beschreibung: `Der Wenig Schreiber ist der perfekte Einstieg, um sich von den Vorteilen des voll digitalen Unterschriften-Prozesses zu überzeugen. Ohne monatliche Grundgebühr und bei voller Kostenkontrolle, können Sie Documents testen und von der enormen Zeitersparnis im Vergleich zum Konventionellen Workflow profitieren.`,
                highlights: {
                    Feature: [
                        { txt: 'Keine Grundgebühr' },
                        { txt: '0,79 € zzgl. MwSt. / Dokument' },
                        { txt: 'Die ersten 20 Dokumente kostenlos testen' },
                    ],
                },
                price: 0,
                itemPrice: 0.79,
                itemName: 'Dokument',
                itemNamePlural: 'Dokumente',
            },
            {
                feature: types_1.FEATURES.DOCUMENTS_BIG,
                name: 'Viel Schreiber',
                beschreibung: `Der Viel Schreiber ist für alle Enthusiasten der digitalen Praxis genau das Richtige. Bereits ab 15 Dokumenten im Monat profitieren Sie im Vergleich zum wenig Schreiber. Ein Wechsel in den Tarif ohne Grundgebühr ist jederzeit zum Monatsende möglich.`,
                highlights: {
                    Feature: [
                        { txt: 'Rechnet sich ab 15 Dokumenten im Monat' },
                        { txt: '0,15 € zzgl. MwSt. / Dokument' },
                        { txt: 'Die ersten 20 Dokumente kostenlos testen' },
                    ],
                },
                price: 10,
                itemPrice: 0.15,
                itemName: 'Dokument',
                itemNamePlural: 'Dokumente',
            },
            {
                feature: types_1.FEATURES.DOCUMENTS_FLAT,
                name: 'Alles Schreiber',
                nameSpecial: 'Flatrate',
                beschreibung: `Mit unserer documents flatrate muss man sich keine Gedanken mehr über die Anzahl der Dokumente pro Monat machen. Es können unbegrenzt Dokumente verarbeitet und unterschrieben werden.`,
                highlights: {
                    Feature: [
                        { txt: 'Keine weiteren Kosten pro Dokument, egal wie viele' },
                        { txt: 'Unbegrenzt Dokumente enthalten' },
                    ],
                },
                price: 79,
                itemPrice: 0,
                itemName: 'Dokument',
                itemNamePlural: 'Dokumente',
                itemsIncluded: 'Unbegrenzt',
            },
        ],
    },
    // {
    //   id: allInclAppId,
    //   name: 'Factoring-, Anamnese- & Documents-Bundle',
    //   live: false,
    //   beschreibung: `TODO`,
    //   hint: 'Einmal a la Chef. Con tutti. Kompletto. All-Inkl. Alles drin eben',
    //   choices: [
    //     {
    //       feature: FEATURES.ANAMNESE_DIAMOND,
    //       name: 'Diamond',
    //       beschreibung: `Ein Preis für alle Dokumente, egal woher, aus den charly Vorlagen oder aus rose4charly. Auswählen, ausfüllen, unterschreiben, fertig.`,
    //       highlights: {
    //         Alles: [{ txt: 'Ja is drin!' }],
    //       },
    //       price: 299,
    //       itemPrice: 0,
    //       itemName: 'Anamnese, Fragebogen',
    //     },
    //   ],
    // },
];
exports.paketeR4cMap = (0, lodash_1.keyBy)(exports.paketeR4c, app => app.id);
function findAppChoice(appId, feature) {
    return (0, lodash_1.find)(exports.paketeR4cMap[appId].choices, c => c.feature === feature);
}
exports.findAppChoice = findAppChoice;
function findAppChoiceByFeature(feature) {
    for (const app of exports.paketeR4c) {
        for (const choice of app.choices) {
            if (choice.feature === feature) {
                return choice;
            }
        }
    }
    return undefined;
}
exports.findAppChoiceByFeature = findAppChoiceByFeature;
function findAppByFeature(feature) {
    for (const app of exports.paketeR4c) {
        for (const choice of app.choices) {
            if (choice.feature === feature) {
                return app;
            }
        }
    }
    return undefined;
}
exports.findAppByFeature = findAppByFeature;
function featureMatrix(app) {
    const matrix = [];
    const groups = (0, lodash_1.chain)(app.choices)
        .map(app => app.highlights)
        .map(h => (0, lodash_1.keys)(h))
        .flatMap()
        .uniq()
        .value();
    const columns = (0, lodash_1.map)(app.choices, (c, idx) => ({
        name: c.name,
        feature: c.feature,
        includesOtherFeatures: c.includesOtherFeatures,
        idx,
    }));
    for (const g of groups) {
        // header
        const line = { typ: 'header', txt: g };
        for (const c of columns) {
            line[`f_${c.name}`] = c.feature;
        }
        matrix.push(line);
        let choiceIdx = 0;
        for (const choice of app.choices) {
            const hgroups = choice.highlights[g];
            if (!(0, lodash_1.isEmpty)(hgroups)) {
                for (const h of hgroups) {
                    const line = { typ: 'feature', txt: h.txt, explanation: h.explanation, i: h.i };
                    for (const c of columns) {
                        line[c.name] = app.featureHandling === 'ADITIV' ? c.idx >= choiceIdx : c.idx === choiceIdx;
                        line[`f_${c.name}`] = c.feature;
                    }
                    matrix.push(line);
                }
            }
            choiceIdx++;
        }
    }
    // add included licences
    const licenceLine = { typ: 'header', txt: 'Enthaltene Lizenzen' };
    for (const c of columns) {
        licenceLine[`f_${c.name}`] = c.feature;
    }
    matrix.push(licenceLine);
    const includedFeaturesByApp = (0, lodash_1.chain)(app.choices)
        .flatMap(c => c.includesOtherFeatures)
        .uniq()
        .compact()
        .groupBy(f => types_1.FEATURE_TO_APP[f])
        .value();
    console.log('includedFeaturesByApp', includedFeaturesByApp);
    for (const app in includedFeaturesByApp) {
        if (Object.prototype.hasOwnProperty.call(includedFeaturesByApp, app)) {
            const features = includedFeaturesByApp[app];
            const appapp = exports.paketeR4cMap[app];
            const licenceLine = { typ: 'licence', txt: appapp.name };
            for (const c of columns) {
                const x = (0, lodash_1.intersection)(c.includesOtherFeatures, features);
                licenceLine[c.name] = (0, lodash_1.chain)(x)
                    .map(f => findAppChoiceByFeature(f))
                    .compact()
                    .map(c => c.name.replace(' ', '<br>'))
                    .join(', ')
                    .value();
            }
            matrix.push(licenceLine);
        }
    }
    // add pricing
    const priceLine = { typ: 'header', txt: 'Preise', price: true };
    for (const c of columns) {
        priceLine[`f_${c.name}`] = c.feature;
    }
    matrix.push(priceLine);
    const itemPrice = { typ: 'price', txt: `Preis pro Nutzung` };
    const item2Price = { typ: 'price', txt: `Preis pro Nutzung` };
    const monthlyPrice = { typ: 'price', txt: `Preis pro Monat` };
    const itemsIncluded = { typ: 'price' };
    const items2Included = { typ: 'price' };
    const cancelInfo = { typ: 'feature', txt: `Monatl. kündbar`, price: true };
    let hasItemPrice = false;
    let hasItem2Price = false;
    for (const choice of app.choices) {
        if (choice.itemPrice) {
            itemPrice.txt = `Je weiterer ${choice.itemName}`;
            itemPrice[choice.name] = choice.itemPrice < 1 ? `${choice.itemPrice * 100} ct` : `${choice.itemPrice} €`;
            hasItemPrice = true;
        }
        itemPrice[`f_${choice.name}`] = choice.feature;
        if (choice.item2Price) {
            item2Price.txt = `Je weiteres ${choice.item2Name}`;
            item2Price[choice.name] = choice.item2Price < 1 ? `${choice.item2Price * 100} ct` : `${choice.item2Price} €`;
            hasItem2Price = true;
        }
        item2Price[`f_${choice.name}`] = choice.feature;
        monthlyPrice[choice.name] = `${choice.price} €`;
        monthlyPrice[`f_${choice.name}`] = choice.feature;
        monthlyPrice.big = true;
        itemsIncluded[choice.name] = choice.itemsIncluded;
        itemsIncluded[`f_${choice.name}`] = choice.feature;
        itemsIncluded.txt = `Enthaltene ${choice.itemNamePlural}`;
        items2Included[choice.name] = choice.items2Included;
        items2Included[`f_${choice.name}`] = choice.feature;
        items2Included.txt = `Enthaltene ${choice.item2NamePlural} pro Monat`;
        cancelInfo[choice.name] = true;
        cancelInfo[`f_${choice.name}`] = choice.feature;
        cancelInfo.last = true;
    }
    matrix.push(itemsIncluded);
    if (hasItemPrice) {
        matrix.push(itemPrice);
    }
    matrix.push(items2Included);
    if (hasItem2Price) {
        matrix.push(item2Price);
    }
    matrix.push(monthlyPrice);
    matrix.push(cancelInfo);
    console.log('featureMatrix', groups, columns, matrix);
    return { matrix, columns };
}
exports.featureMatrix = featureMatrix;
function isFeatureAlreadySelectedElseWhere(appId, feature2check, selectedFeatures) {
    const otherApps = (0, lodash_1.chain)(exports.paketeR4cMap)
        .keys()
        .filter(k => k !== appId)
        .value();
    const otherAppSelectedFeatures = (0, lodash_1.chain)(otherApps)
        .map(oa => selectedFeatures[oa])
        .compact()
        .filter(f => f !== types_1.FEATURES.NONE)
        .value();
    const otherAppCheck = (0, lodash_1.chain)(otherAppSelectedFeatures)
        .flatMap(f => findAppChoiceByFeature(f))
        .compact()
        .flatMap(c => c.includesOtherFeatures)
        .compact()
        .value();
    const where = (0, lodash_1.intersection)([feature2check], otherAppCheck);
    const whereNames = (0, lodash_1.map)(where, f => { var _a, _b; return `${(_a = findAppByFeature(f)) === null || _a === void 0 ? void 0 : _a.name} ${((_b = findAppChoiceByFeature(f)) === null || _b === void 0 ? void 0 : _b.name) || f}`; });
    const ret = { included: !(0, lodash_1.isEmpty)(where), where, whereNames };
    console.log('isFeatureAlreadySelectedElseWhere', appId, feature2check, otherApps, otherAppSelectedFeatures, otherAppCheck, ret);
    return ret;
}
exports.isFeatureAlreadySelectedElseWhere = isFeatureAlreadySelectedElseWhere;
