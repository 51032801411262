"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHeuteApi = void 0;
function createHeuteApi(api) {
    return {
        getHeute: (tag, letztesUpdate) => api.metrics.get(`/api/heute`, { query: { tag, letztesupodate: letztesUpdate } }),
        getAlarmCounts: ({ alarmkey, from, to }) => api.metrics.get(`/api/heute/alarm/${alarmkey}`, { query: { from, to } }),
        getScore: ({ bereich, from, to }) => api.metrics.get(`/api/heute/score/${bereich}`, { query: { from, to } }),
    };
}
exports.createHeuteApi = createHeuteApi;
