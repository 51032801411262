"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSponsoringApi = void 0;
function createSponsoringApi(api) {
    return {
        // Sponsoren
        getSponsoren: () => api.metrics.get(`/admin/sponsoren`),
        createSponsor: (sponsor, paymentMethod) => api.metrics.put(`/admin/sponsoren`, { sponsor, paymentMethod }),
        updateSponsor: (sponsor) => api.metrics.post(`/admin/sponsoren`, { sponsor }),
        deleteSponsor: (sponsor) => api.metrics.delete(`/admin/sponsoren`, { sponsor }),
        // Gutscheine
        getGutscheine: () => api.metrics.get(`/admin/gutscheine`),
        createGutschein: (gutschein) => api.metrics.put(`/admin/gutscheine`, { gutschein }),
        updateGutschein: (gutschein) => api.metrics.post(`/admin/gutscheine`, { gutschein }),
        deleteGutschein: (gutschein) => api.metrics.delete(`/admin/gutscheine`, { gutschein }),
        assignGutschein: (gutschein, subscriptionid) => api.metrics.post(`/admin/addgutscheintosubscription`, { gutscheincode: gutschein.code, subscriptionid }),
    };
}
exports.createSponsoringApi = createSponsoringApi;
