"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCollectorApi = void 0;
const botApi_1 = require("./collector/botApi");
const collectorAdminApi_1 = require("./collector/collectorAdminApi");
const importApi_1 = require("./collector/importApi");
const triggerApi_1 = require("./collector/triggerApi");
function createCollectorApi(roseApi) {
    return {
        bot: (0, botApi_1.createBotApi)(roseApi),
        newClient: (0, botApi_1.createConnectorCheckApi)(roseApi),
        trigger: (0, triggerApi_1.createCollectorTriggerApi)(roseApi),
        import: (0, importApi_1.createImportApi)(roseApi),
        admin: (0, collectorAdminApi_1.createCollectorAdminApi)(roseApi),
    };
}
exports.createCollectorApi = createCollectorApi;
