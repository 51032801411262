"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createOnboardingApi = void 0;
function createOnboardingApi(api) {
    return {
        getOnboardingEntries: () => api.metrics.get('/admin/onboarding'),
        patchOnboardingEntry: (id, entry) => api.metrics.patch(`/admin/onboarding/entries/${id}`, entry),
        patchOnboardingEntries: (entries) => api.metrics.patch('/admin/onboarding/entries', entries),
    };
}
exports.createOnboardingApi = createOnboardingApi;
