"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPaRoentgen = exports.opgRoentgenLeistungenSet = exports.sixPackRoentgenLeistungenSet = exports.paRoentgenLeistungenSet = exports.opgRoentgenLeistungen = exports.sixPackRoentgenLeistungen = exports.paRoentgenLeistungen = void 0;
exports.paRoentgenLeistungen = ['ä925c', 'ä925d', 'ä935d', 'ä5004'];
exports.sixPackRoentgenLeistungen = ['ä5000'];
exports.opgRoentgenLeistungen = ['Ä935d', 'Ä5004'];
exports.paRoentgenLeistungenSet = new Set(exports.paRoentgenLeistungen);
exports.sixPackRoentgenLeistungenSet = new Set(exports.sixPackRoentgenLeistungen);
exports.opgRoentgenLeistungenSet = new Set(exports.opgRoentgenLeistungen);
function isPaRoentgen(nummer, anzahl) {
    if (!nummer) {
        return false;
    }
    const ln = nummer.toLocaleLowerCase();
    if (exports.paRoentgenLeistungenSet.has(ln)) {
        return true;
    }
    if (exports.sixPackRoentgenLeistungenSet.has(ln) && anzahl >= 6) {
        return true;
    }
    return false;
}
exports.isPaRoentgen = isPaRoentgen;
