"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.euroFilter = void 0;
const lodash_1 = require("lodash");
const numeral_1 = require("../numeral");
exports.euroFilter = {
    filters: {
        euro(value, showDecimal = false, fallback = '-') {
            if ((0, lodash_1.isNil)(value)) {
                return fallback;
            }
            if ((0, lodash_1.isNumber)(value)) {
                value += 0.00001;
            }
            return (0, numeral_1.numeral)(value, { fractionDigits: showDecimal ? 2 : 0, style: 'currency', currency: 'EUR' });
        },
    },
};
