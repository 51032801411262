"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMainApi = void 0;
function createMainApi(api) {
    return {
        collectionStats: (params) => {
            var _a, _b;
            return api.metrics.get(`/api/collectionstats`, {
                query: Object.assign(Object.assign({}, params), { from: (_a = params.from) === null || _a === void 0 ? void 0 : _a.toISOString(), to: (_b = params.to) === null || _b === void 0 ? void 0 : _b.toISOString() }),
            });
        },
        importProtocol: (id) => api.metrics.get(`/api/importprotocol/${id}`),
        getApiKey: () => api.metrics.get(`/api/apikey`),
        getViews: (all) => api.metrics.get(`/api/views`, { query: { all } }),
    };
}
exports.createMainApi = createMainApi;
