"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateKeysSet = exports.isInvalidEmail = exports.isValidEmail = void 0;
// credit https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
exports.isValidEmail = isValidEmail;
function isInvalidEmail(email) {
    return !isValidEmail(email);
}
exports.isInvalidEmail = isInvalidEmail;
function validateKeysSet(object, keys) {
    keys.forEach(key => {
        if (!(key in object)) {
            throw new Error(`key ${key} is missing`);
        }
    });
}
exports.validateKeysSet = validateKeysSet;
