var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "10",
      "md": "8",
      "lg": "6"
    }
  }, [_c('v-card', [_c('v-card-text', [_c('v-form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    }
  }, [_c('h3', [_vm._v("Du bist schon rose-Benutzer und nutzt ein weiteres rose-Produkt?")]), _c('span', [_vm._v("Dann nutze hier deine Zugangsdaten:")]), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "E-Mail",
      "required": ""
    },
    model: {
      value: _vm.roseAccount.email,
      callback: function callback($$v) {
        _vm.$set(_vm.roseAccount, "email", $$v);
      },
      expression: "roseAccount.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": ""
    },
    model: {
      value: _vm.roseAccount.password,
      callback: function callback($$v) {
        _vm.$set(_vm.roseAccount, "password", $$v);
      },
      expression: "roseAccount.password"
    }
  }), _vm.loginError ? _c('v-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.loginError))]) : _vm._e(), _c('div', {
    staticClass: "login-buttons"
  }, [!_vm.roseAccount.cid ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "type": "submit",
      "disabled": !_vm.roseAccount.email || !_vm.roseAccount.password || _vm.loading || _vm.roseAccount.cid || _vm.clientIds.length > 0,
      "elevation": "2",
      "raised": ""
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Anmelden")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading || !_vm.clientIds || _vm.clientIds.length <= 0,
      "elevation": "2",
      "raised": ""
    },
    on: {
      "click": _vm.resetLogin
    }
  }, [_vm._v("Zurücksetzen")])], 1), _vm.clientIds.length > 1 ? _c('v-select', {
    attrs: {
      "items": _vm.clientIds,
      "item-text": "name",
      "item-value": "cid",
      "label": "Praxis auswählen"
    },
    model: {
      value: _vm.roseAccount.cid,
      callback: function callback($$v) {
        _vm.$set(_vm.roseAccount, "cid", $$v);
      },
      expression: "roseAccount.cid"
    }
  }) : _vm._e(), _vm.roseAccount.cid ? _c('v-btn', {
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.nextWithRoseAccount
    }
  }, [_vm._v("Weiter")]) : _vm._e(), _c('div', {
    staticClass: "some-top-space"
  }, [_c('h3', [_vm._v("Nein ich bin noch kein rose-Benutzer")])]), _c('div', [_vm._v("Dann gehts hier weiter:")]), _c('v-btn', {
    staticClass: "next-button",
    attrs: {
      "color": "primary",
      "data-testid": "nextNoRoseAccount"
    },
    on: {
      "click": _vm.nextNoRoseAccount
    }
  }, [_vm._v("Ohne Anmeldung fortfahren")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }