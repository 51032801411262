"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTerminlabelsApi = void 0;
function createTerminlabelsApi(api) {
    return {
        saveLabelConfig: (labelConfig) => api.metrics.post(`/api/terminlabels/savelabelconfig`, { labelConfig }),
        saveTerminLabels: (terminId, terminLabels) => api.metrics.post(`/api/terminlabels/saveterminlabels`, {
            terminId,
            selectedLabels: terminLabels,
        }),
    };
}
exports.createTerminlabelsApi = createTerminlabelsApi;
