
import Vue, { defineComponent } from 'vue';
import { onboardingStore } from '@/state/onboardingStore';

export default defineComponent({
  name: 'App',
  computed: {
    warning(): string | null {
      return onboardingStore.state.warning;
    },
  },
});
