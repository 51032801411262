"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const _ = __importStar(require("lodash"));
const onboardingStore_1 = require("@/state/onboardingStore");
const base_1 = require("../../../base");
exports.default = (0, vue_1.defineComponent)({
    components: {},
    computed: {
        avvHtml() {
            var _a, _b, _c, _d, _e;
            _.templateSettings.interpolate = /{{([\s\S]+?)}}/g; // enable {{ variable }} syntax
            let compiled = onboardingStore_1.onboardingStore.state.isR4cOnboarding ? _.template(base_1.r4cAVVHtml) : _.template(base_1.metricsAVVHtml);
            let pd = (onboardingStore_1.onboardingStore.state && onboardingStore_1.onboardingStore.state.praxisDaten) ||
                { auftraggeber: {} };
            let ap = (onboardingStore_1.onboardingStore.state && onboardingStore_1.onboardingStore.state.technischerAnprechpartner) ||
                {};
            let admin = (onboardingStore_1.onboardingStore.state && onboardingStore_1.onboardingStore.state.adminBenutzer) || {};
            return compiled({
                praxisname: `${(_a = pd.auftraggeber) === null || _a === void 0 ? void 0 : _a.name} ${(_b = pd.auftraggeber) === null || _b === void 0 ? void 0 : _b.vorname}` || 'Die Praxis',
                praxisstrasse: ((_c = pd.auftraggeber) === null || _c === void 0 ? void 0 : _c.straße) || 'Die Strasse',
                praxisplz: ((_d = pd.auftraggeber) === null || _d === void 0 ? void 0 : _d.plz) || 'Die PLZ',
                praxisort: ((_e = pd.auftraggeber) === null || _e === void 0 ? void 0 : _e.stadt) || 'Die Stadt',
                ansprechpartnervorname: ap.vorname || 'Der ansprechpartnervorname',
                ansprechpartnernachname: ap.name || 'Der ansprechpartnernachname',
                adminvorname: admin.vorname || 'Der adminvorname',
                adminnachname: admin.name || 'Der adminnachname',
            });
        },
    },
});
