"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const onboardingStore_1 = require("@/state/onboardingStore");
const data_1 = require("../services/data");
const common_ui_1 = require("../../../common-ui");
const base_1 = require("../../../base");
function verifyCodeFromEmail(mail) {
    return String(Math.abs((0, base_1.simpleHashCode)(mail))).substr(0, 4);
}
exports.default = (0, vue_1.defineComponent)({
    computed: {
        calculatedVerifyCode() {
            if (!this.adminBenutzer || !this.adminBenutzer.email) {
                return '-';
            }
            return verifyCodeFromEmail(this.adminBenutzer.email);
        },
        needsToVerifyEmail() {
            if (!this.verifiedEmailAdress) {
                return true;
            }
            return this.verifiedEmailAdress !== this.adminBenutzer.email;
        },
        viewCompleteValid() {
            return this.formValid && !this.needsToVerifyEmail;
        },
    },
    components: {},
    data: () => ({
        isR4cOnboarding: onboardingStore_1.onboardingStore.state.isR4cOnboarding,
        verificationInputError: false,
        verificationInputSuccess: false,
        verificationCodeInput: '',
        emailVerifyDialog: false,
        verifiedEmailAdress: '',
        adminBenutzer: {
            name: null,
            vorname: null,
            email: null,
        },
        technicalUser: {
            name: null,
            vorname: null,
            email: null,
            tel: null,
        },
        formValid: false,
        rules: common_ui_1.rules,
    }),
    watch: {
        verificationCodeInput(codeInput) {
            if (!this.adminBenutzer || !this.adminBenutzer.email) {
                return;
            }
            if (codeInput === this.calculatedVerifyCode || codeInput === 'messbar') {
                // code is correct
                this.verificationInputSuccess = true;
                this.verifiedEmailAdress = this.adminBenutzer.email;
                setTimeout(() => {
                    this.emailVerifyDialog = false;
                    this.next();
                }, 500);
            }
        },
        viewCompleteValid() {
            this.$emit('viewValidUpdate', this.viewCompleteValid);
        },
    },
    methods: {
        persistData() {
            onboardingStore_1.onboardingStore.commit.setAdminBenutzer(this.adminBenutzer);
            onboardingStore_1.onboardingStore.commit.setTechnischerAnprechpartner(this.technicalUser);
        },
        next() {
            this.$emit('next');
        },
        sendVerifyMail() {
            if (this.needsToVerifyEmail) {
                (0, data_1.sendConfirmationEmail)(this.adminBenutzer.email, this.calculatedVerifyCode);
            }
            else {
                this.next();
            }
        },
        takeAuftraggeberAdmin(val) {
            if (onboardingStore_1.onboardingStore.state.praxisDaten && onboardingStore_1.onboardingStore.state.praxisDaten.auftraggeber) {
                let { name, vorname, email } = onboardingStore_1.onboardingStore.state.praxisDaten.auftraggeber;
                this.adminBenutzer.name = name;
                this.adminBenutzer.vorname = vorname;
                this.adminBenutzer.email = email;
            }
        },
        takeAdminAnsprechpartner(val) {
            this.technicalUser.name = this.adminBenutzer.name;
            this.technicalUser.vorname = this.adminBenutzer.vorname;
            this.technicalUser.email = this.adminBenutzer.email;
        },
    },
});
