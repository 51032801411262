
import Vue, { defineComponent } from 'vue';
import Main from '../components/Main.vue';
import { IFactoringOnboardingStart } from '../../../types';
import { sendOnboardingStarted } from '../services/data';
import { parseInfoString } from '../services/parseInfoString';
import { onboardingStore } from '@/state/onboardingStore';
import { OnboardingR4cTask } from '@/state/tasks';

// NEU
// http://localhost:8083/r4c?info=N4IgDgTghgHglgZwCJQC5RALlFA1quANwFMw05iA7VKrARgFYAGFgGhCkoC8oALAGzIEqNSlgBMzNhzBgAtgHsAJsSwgkAUQCyAeRDsoEAEbE4qBGH5RiqLqsxN2J3pyX9iEeo5DE5UOPxqUAACRgB0KvogAGawakx0UX7wSmoAzCwsUQoQqGpaAK4INBDFUEp57JZcanTiaQAsDFGQsIhqACrExSSlcAqUAAQASgoIxIMA4nJGABIt0PAIiZggXcUACouIiVXbCOKd3ahbbQdR4xBwUPyUnqsAtBc0cmDEt1ByxCtrxwDKLze-F2IGKviBlE+xEOq3WqAB4Peh3YYNe7xyeVWhTBpXQFWeiME-BqqzqjWaKMB72K0AQ43yRRKAHMoHSJnQapTafSsYyPCy2YM6GAojR3NEBvEABwsIX1BqijxyOCUZyudz3cTeCAkgDaoDgqVJUUoBTkX3uIBBkK+agAQgAxP5RXAFSgqSh3WpRM0INAkuiZFgAXwAuuwSsrKKQIAoAFZ8e76kAJ3iWrV0ACcUU4PAE9AyTDD7F9-sg8cTWGTqfTCWzBm4fECmBlQbDwYMYDguGIAE81H2AFK8IyTADGcB0cEHAEkAKpcGd0ABycBnCBnlGGDDHAGEZwA2Ge4MAADQAarvB5mwkOCkZxMSAFp-Q8zuTDfhGADqmdwT+-OhCCfSZz1QB9Px-P8AKAkCwIgr9f3-QDCE3Jgwl7aJeGIKViAaB4wFQDI0iMXA6AAdgAQRgYZZgKABNMdcC4Lg0jkDYOQYaJCDHaIjDjU9olmeiLmIMcIBsNRxnEySUV4BQAHc4WGElUAgApiGDIA

// EDIT
// http://localhost:8083/edit-r4c-apps?info=N4IghgrgLgFgKgewNYFMB2IBcIUE8BSMARgOIDGAlgPIX4CSAqgF50CMAchXQM51oBKAVjIBhOgDY6SAA4ANAGoj8ATgB0efABsiAdQBi0omMlp2AJwCKJfgDcAJiWUUAMgFt%20muhQDuFAFqyMN50AFYIAB7sIWQALACycEgx7AAiAOaszkredrL43kQAzOyeYRSkymhgOjEuStIAmrIW1CEAogBM7ExkrFRwcR1UKfJq7IVwhrJMrviyAAxIes4I3B1tDW0x9ABCcXE7rgxwhVDk8jA7zjEAghZxABwgADQglHZYIAhoRAhgZnYKGg0lAUNwoAB9QoAMxihU0CHESBAAF8gA

// {"praxisData":{"aktivepatienten":15000,"anzahlpatienten":25000,"appmode":"DEMO","arbeitsplaetze":0,"behandler":10,"bank1":"Weltbank","bic1":"DAAEDEDD","email":"a@b.de","fax":"01","iban1":"DE27100777770209299700","kzvidnr":"1","kzvname":"KZV Deutschland","kzvnummer":"1","license":"1234","maxid":"30000","ort":"Musterstadt","plz":"12345","praxis":"Testversion Rose GmbH","praxis1":"TestPraxis1","praxis2":"TestPraxis2","serialnr":"-","stempelname1":"TestStempel1","stempelname2":"TestStempel2","stempelort":"Musterstadt","stempelplz":"12345","stempelstrasse":"Mustergasse 1z","strasse":"Mustergasse 1p","telefon":"0800 1234","terminbehandler":20,"rz":[{"id":"1","nummer":"1","name":"BFS","kundennr":"1","umsatz":100000},{"id":"2","nummer":"2","name":"DZR","kundennr":"1","umsatz":100000}],"termineprojahr":[{"jahr":"2016","anzahl":1000},{"jahr":"2017","anzahl":1100},{"jahr":"2018","anzahl":1200},{"jahr":"2019","anzahl":1300}],"umsatzprojahr":[{"jahr":"2016","anzahl":500000},{"jahr":"2017","anzahl":600000},{"jahr":"2018","anzahl":700000},{"jahr":"2019","anzahl":800000}]},"apikey":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXQiOiJkZW1vIn0.IZIFEatDm2xDcgGckennuQoCTQsc5fTmNHkLwe57CWo","secret":"demodemodemodemodemodemodemodemo"}

export default defineComponent({
  data: () => ({
    initialized: false,
    urlError: false,
  }),
  components: {
    Main,
  },
  mounted() {
    try {
      let info = parseInfoString<IFactoringOnboardingStart>(this.$route.query.info);

      onboardingStore.commit.hydrateR4cOnboardingWithCharlyInfo(info);
      onboardingStore.commit.initializeAppTask(OnboardingR4cTask);

      this.initialized = true;
      // just dont fail....
      try {
        sendOnboardingStarted({ ...info, url: location.href });
      } catch (e) {}
    } catch (e) {
      console.error('error while parsing query info:', e.message);
      this.urlError = e.message;
    }
  },
  methods: {},
});
