"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBenchmarkApi = void 0;
function createBenchmarkApi(api) {
    return {
        getBenchmarkRaw: (params) => api.metrics.post(`/api/benchmarkraw`, params),
        getBenchmarkRawByPatids: (params) => api.metrics.post(`/api/benchmark/patienten`, params),
        getBenchmark: (params) => api.metrics.get(`/api/benchmark`, { query: params }),
        getBenchmarkFreeRange: (params) => api.metrics.post(`/api/benchmark/freerange`, params),
        getBenchmarkDetailsFreeRange: (params) => api.metrics.get(`/api/benchmark/details/freerange`, { query: params }),
        getBenchmarkByPatids: (params) => api.metrics.post(`/api/benchmark/patienten`, params),
        getBenchmarkDetails: (params) => api.metrics.get(`/api/benchmark/details`, { query: params }),
        getBenchmarkHistory: (params) => api.metrics.post(`/api/benchmark/history`, params),
    };
}
exports.createBenchmarkApi = createBenchmarkApi;
