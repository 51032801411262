"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4cDebugApi = void 0;
function createR4cDebugApi(api) {
    return {
        htmlToPdfDebug: (htmlString, options) => api.r4cDispatch.post(`/debug/htmlToPdf`, Object.assign({ htmlString }, options)),
        htmlToPdfDebugR4cHost: (htmlString, r4chost, options) => api.r4c.post(r4chost, `/debug/htmlToPdf`, Object.assign({ htmlString }, options)),
    };
}
exports.createR4cDebugApi = createR4cDebugApi;
