"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientDebugApi = void 0;
function createPatientDebugApi(roseApi) {
    return {
        getPatientLeistungen: (params) => roseApi.metrics.get(`api/patient/debug/leistungen/${params.patid}`),
        getPatids: () => roseApi.metrics.get(`api/patient/debug/patids`),
    };
}
exports.createPatientDebugApi = createPatientDebugApi;
