"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const onboardingStore_1 = require("@/state/onboardingStore");
const common_ui_1 = require("../../../common-ui");
const lodash_1 = require("lodash");
exports.default = (0, vue_1.defineComponent)({
    data: () => ({
        isR4cOnboarding: onboardingStore_1.onboardingStore.state.isR4cOnboarding,
        abweichendeVersandadresse: false,
        praxisDaten: {
            auftraggeber: {
                name: null,
                vorname: null,
                email: null,
                straße: null,
                stadt: null,
                plz: null,
            },
            praxis: {
                name: null,
                nameKurz: null,
                tel: null,
                straße: null,
                stadt: null,
                plz: null,
            },
        },
        formValid: false,
        rules: common_ui_1.rules,
        pvs: 'unknown',
        pvsValid: false,
        empfehlungsAuswahl: [
            'Kollege',
            'Mitarbeiter',
            'Factoring-Anbieter',
            'Social-Media',
            'Websuche',
            'solutio',
            'EVIDENT',
            'Sonstiges',
        ],
        empfehlungSonstiges: '',
    }),
    computed: {
        viewIsValid() {
            return this.formValid && (this.pvsValid || onboardingStore_1.onboardingStore.state.isR4cOnboarding);
        },
        activeStep() {
            return onboardingStore_1.onboardingStore.state.activeStep;
        },
        empfehlung() {
            return this.praxisDaten.empfehlung;
        },
    },
    watch: {
        viewIsValid() {
            this.$emit('viewValidUpdate', this.viewIsValid);
        },
        pvs() {
            if (this.pvs && this.pvs !== 'unknown') {
                this.pvsValid = true;
            }
        },
        empfehlung() {
            if (this.praxisDaten.empfehlung !== 'Sonstiges') {
                this.empfehlungSonstiges = '';
            }
        },
        activeStep() {
            var _a;
            if (((_a = this.activeStep) === null || _a === void 0 ? void 0 : _a.name) === 'Praxisdaten' && !(0, lodash_1.isEmpty)(this.empfehlungSonstiges)) {
                this.praxisDaten.empfehlung = 'Sonstiges';
            }
        },
    },
    methods: {
        next() {
            this.$emit('next');
        },
        persistData() {
            if (this.praxisDaten.empfehlung === 'Sonstiges') {
                this.praxisDaten.empfehlung = this.empfehlungSonstiges;
            }
            onboardingStore_1.onboardingStore.commit.setPraxisdaten(this.praxisDaten);
            onboardingStore_1.onboardingStore.commit.setLizenzInfo({ pvs: this.pvs });
        },
        refreshHook() {
            var _a;
            if (onboardingStore_1.onboardingStore.state.isR4cOnboarding && onboardingStore_1.onboardingStore.state.charlyPraxisInfo) {
                let praxisDatenForm = this.praxisDaten.praxis;
                praxisDatenForm.name = praxisDatenForm.name || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.praxis;
                praxisDatenForm.nameKurz = praxisDatenForm.nameKurz || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.praxis;
                praxisDatenForm.tel = praxisDatenForm.tel || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.telefon;
                praxisDatenForm.straße = praxisDatenForm.straße || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelstrasse;
                praxisDatenForm.stadt = praxisDatenForm.stadt || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelort;
                praxisDatenForm.plz = praxisDatenForm.plz || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelplz;
                let auftraggeberForm = this.praxisDaten.auftraggeber;
                auftraggeberForm.name = auftraggeberForm.name || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelname1;
                auftraggeberForm.vorname = auftraggeberForm.vorname || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelname2;
                auftraggeberForm.straße = auftraggeberForm.straße || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelstrasse;
                auftraggeberForm.stadt = auftraggeberForm.stadt || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelort;
                auftraggeberForm.plz = auftraggeberForm.plz || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.stempelplz;
                auftraggeberForm.email = auftraggeberForm.email || onboardingStore_1.onboardingStore.state.charlyPraxisInfo.email;
                this.praxisDaten.empfehlung = (_a = onboardingStore_1.onboardingStore.state.praxisDaten) === null || _a === void 0 ? void 0 : _a.empfehlung;
            }
        },
    },
});
