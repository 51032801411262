"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const onboardingStore_1 = require("@/state/onboardingStore");
const common_ui_1 = require("../../../common-ui");
const types_1 = require("../../../types");
const lodash_1 = require("lodash");
exports.default = (0, vue_1.defineComponent)({
    components: {},
    data: () => ({
        demo: true,
        pakete: types_1.metricsPakete,
        behandlerCount: 2,
        prophylaxeCount: 4,
        saveSetupFee: true,
        gutscheinCode: '',
    }),
    mounted() {
        // this view cannot be invalid
        this.$emit('viewValidUpdate', true);
        console.log('this.$route.query.paket', this.$route.query.paket);
        const byId = (0, lodash_1.keyBy)(this.pakete, 'id');
        if (this.$route.query.paket === 'white') {
            this.behandlerCount = byId[types_1.PACKAGES.WHITE].min;
            this.prophylaxeCount = 1;
        }
        else if (this.$route.query.paket === 'red') {
            this.behandlerCount = byId[types_1.PACKAGES.RED].min;
            this.prophylaxeCount = 2;
        }
        else if (this.$route.query.paket === 'black') {
            this.behandlerCount = byId[types_1.PACKAGES.BLACK].min;
            this.prophylaxeCount = 4;
        }
        else if (this.$route.query.paket === 'diamond') {
            this.behandlerCount = byId[types_1.PACKAGES.DIAMOND].min;
            this.prophylaxeCount = 6;
        }
    },
    watch: {
        behandlerCount() {
            let c = +this.behandlerCount;
            if (isNaN(c)) {
                c = 1;
            }
            this.behandlerCount = Math.max(1, this.behandlerCount);
        },
        prophylaxeCount() { },
    },
    computed: {
        progressBarColor() {
            switch (this.selectedPaket.id) {
                case 'White':
                    return '#f5f5f5';
                case 'Red':
                    return '#e96382';
                case 'Black':
                    return 'black';
                case 'Diamond':
                    return '#c0c0c0';
                default:
                    return '';
            }
        },
        roseLizenzen() {
            return +this.behandlerCount + +this.prophylaxeCount * 0.5;
        },
        selectedPaket() {
            if (this.roseLizenzen <= 2) {
                return this.pakete[0];
            }
            let lizenzen = Math.ceil(this.roseLizenzen);
            return this.pakete.find(paket => paket.min <= lizenzen && paket.max > lizenzen) || this.pakete[0];
        },
        zusatzLizenzen() {
            if (this.selectedPaket.id === 'Diamond') {
                return 0;
            }
            return Math.ceil(Math.max(this.roseLizenzen - this.selectedPaket.min, 0));
        },
        zusatzLizenzenGesamtPreis() {
            return this.zusatzLizenzen * this.selectedPaket.zusatzLizenzPreis;
        },
    },
    methods: {
        persistData() {
            onboardingStore_1.onboardingStore.commit.setLizenzInfo({
                paket: this.selectedPaket,
                behandlerCount: this.behandlerCount,
                prophylaxeCount: this.prophylaxeCount,
                saveSetupFee: this.saveSetupFee,
                gutscheinCode: this.gutscheinCode,
            });
        },
        next() {
            this.$emit('next');
            // since this was the first interaction add unload handler here
            if (common_ui_1.isProd || common_ui_1.isStaging) {
                window.onbeforeunload = () => {
                    return 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen?';
                };
            }
        },
    },
});
