"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMetricsApi = void 0;
const authApi_1 = require("./metrics/authApi");
const contactApi_1 = require("./metrics/contactApi");
const leistungenApi_1 = require("./metrics/leistungenApi");
const profileApi_1 = require("./metrics/profileApi");
const sponsoringApi_1 = require("./metrics/sponsoringApi");
const staticDataApi_1 = require("./metrics/staticDataApi");
const stripeApi_1 = require("./metrics/stripeApi");
const rechnungenApi_1 = require("./metrics/rechnungenApi");
const patientenUmsaetzeApi_1 = require("./metrics/patientenUmsaetzeApi");
const patientenKarteApi_1 = require("./metrics/patientenKarteApi");
const settingsApi_1 = require("./metrics/settingsApi");
const mainApi_1 = require("./metrics/mainApi");
const hkpLabelApi_1 = require("./metrics/hkpLabelApi");
const caLicenceApi_1 = require("./metrics/caLicenceApi");
const termineApi_1 = require("./metrics/termineApi");
const adminApi_1 = require("./metrics/adminApi");
const imageUploadApi_1 = require("./metrics/imageUploadApi");
const r2csvLicenceApi_1 = require("./metrics/r2csvLicenceApi");
const agbApi_1 = require("./metrics/agbApi");
const feedbackApi_1 = require("./metrics/feedbackApi");
const abrechnungsvorschlaegeApi_1 = require("./metrics/abrechnungsvorschlaegeApi");
const abrechnungslabelsApi_1 = require("./metrics/abrechnungslabelsApi");
const terminlabelsApi_1 = require("./metrics/terminlabelsApi");
const heuteApi_1 = require("./metrics/heuteApi");
const umsatzApi_1 = require("./metrics/umsatzApi");
const hkpApi_1 = require("./metrics/hkpApi");
const paApi_1 = require("./metrics/paApi");
const complianceApi_1 = require("./metrics/complianceApi");
const extendedApi_1 = require("./metrics/extendedApi");
const recallApi_1 = require("./metrics/recallApi");
const befundeApi_1 = require("./metrics/befundeApi");
const praxisKPIApi_1 = require("./metrics/praxisKPIApi");
const benchmarkApi_1 = require("./metrics/benchmarkApi");
const patientApi_1 = require("./metrics/patientApi");
const uiStatusApi_1 = require("./metrics/uiStatusApi");
const hvmApi_1 = require("./metrics/hvmApi");
const patientDebugApi_1 = require("./metrics/patientDebugApi");
const shopApi_1 = require("./metrics/shopApi");
const helpDeskApi_1 = require("./metrics/helpDeskApi");
const onboarding_1 = require("./metrics/onboarding");
const dashboardsApi_1 = require("./metrics/dashboardsApi");
const dsoApi_1 = require("./metrics/dsoApi");
function createMetricsApi(roseApi) {
    return {
        abrechnungslabels: (0, abrechnungslabelsApi_1.createAbrechnungslabelsApi)(roseApi),
        abrechnungsvorschlaege: (0, abrechnungsvorschlaegeApi_1.createAbrechnungsvorschlaegeApi)(roseApi),
        admin: (0, adminApi_1.createAdminApi)(roseApi),
        agb: (0, agbApi_1.createAGBApi)(roseApi),
        auth: (0, authApi_1.createAuthApi)(roseApi),
        befunde: (0, befundeApi_1.createBefundeApi)(roseApi),
        benchmark: (0, benchmarkApi_1.createBenchmarkApi)(roseApi),
        calicences: (0, caLicenceApi_1.createCALicenceApi)(roseApi),
        compliance: (0, complianceApi_1.createComplianceApi)(roseApi),
        contact: (0, contactApi_1.createContactApi)(roseApi),
        dashboards: (0, dashboardsApi_1.createDashboardsApi)(roseApi),
        extended: (0, extendedApi_1.createExtendedApi)(roseApi),
        feedback: (0, feedbackApi_1.createFeedbackApi)(roseApi),
        helpdesk: (0, helpDeskApi_1.createHelpdeskApi)(roseApi),
        heute: (0, heuteApi_1.createHeuteApi)(roseApi),
        hkp: (0, hkpApi_1.createHKPApi)(roseApi),
        hkpLabels: (0, hkpLabelApi_1.createHkpLabelApi)(roseApi),
        imageUpload: (0, imageUploadApi_1.createImageUploadApi)(roseApi),
        leistungen: (0, leistungenApi_1.createLeistungenApi)(roseApi),
        dso: (0, dsoApi_1.createDsoApi)(roseApi),
        hvm: (0, hvmApi_1.createHVMApi)(roseApi),
        main: (0, mainApi_1.createMainApi)(roseApi),
        onboarding: (0, onboarding_1.createOnboardingApi)(roseApi),
        pa: (0, paApi_1.createPAApi)(roseApi),
        patienten: (0, patientApi_1.createPatientApi)(roseApi),
        patientenDebug: (0, patientDebugApi_1.createPatientDebugApi)(roseApi),
        patientenKarte: (0, patientenKarteApi_1.createPatientenKarteApi)(roseApi),
        patientenUmsaetze: (0, patientenUmsaetzeApi_1.createPatientenUmsaetzeApi)(roseApi),
        praxisKPI: (0, praxisKPIApi_1.createPraxisKPIApi)(roseApi),
        profile: (0, profileApi_1.createProfileApi)(roseApi),
        r2csvlicences: (0, r2csvLicenceApi_1.createR2CsvLicenceApi)(roseApi),
        recall: (0, recallApi_1.createRecallApi)(roseApi),
        rechnungen: (0, rechnungenApi_1.createRechnungenApi)(roseApi),
        settings: (0, settingsApi_1.createSettingsApi)(roseApi),
        shop: (0, shopApi_1.createShopApi)(roseApi),
        sponsoring: (0, sponsoringApi_1.createSponsoringApi)(roseApi),
        staticData: (0, staticDataApi_1.createStaticDataApi)(roseApi),
        stripe: (0, stripeApi_1.createStripeApi)(roseApi),
        termine: (0, termineApi_1.createTermineApi)(roseApi),
        terminlabels: (0, terminlabelsApi_1.createTerminlabelsApi)(roseApi),
        uiStatus: (0, uiStatusApi_1.createUiStatusApi)(roseApi),
        umsatz: (0, umsatzApi_1.createUmsatzApi)(roseApi),
    };
}
exports.createMetricsApi = createMetricsApi;
