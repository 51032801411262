"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markdownify = void 0;
const marked_1 = require("marked");
function markdownify(text) {
    if (!text) {
        return '';
    }
    return marked_1.marked.parse(text);
}
exports.markdownify = markdownify;
