"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createImageUploadApi = void 0;
function createImageUploadApi(api) {
    return {
        upload: (image, { originalImage, source, purpose } = {}) => api.metrics.post(`/imageUploads/upload`, { image, originalImage, source, purpose }),
        getImageDataUri: (imageId) => api.metrics.get(`/imageUploads/image/${imageId}`),
        getImagePath: (imageId) => `/imageUploads/image/${imageId}`,
    };
}
exports.createImageUploadApi = createImageUploadApi;
