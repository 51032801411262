"use strict";
// tslint:disable:no-console
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonLoggerService = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["TRACE"] = 10] = "TRACE";
    LogLevel[LogLevel["DEBUG"] = 20] = "DEBUG";
    LogLevel[LogLevel["INFO"] = 30] = "INFO";
    LogLevel[LogLevel["WARN"] = 40] = "WARN";
    LogLevel[LogLevel["ERROR"] = 50] = "ERROR";
    LogLevel[LogLevel["FATAL"] = 60] = "FATAL";
})(LogLevel || (exports.LogLevel = LogLevel = {}));
class CommonLoggerService {
    constructor() {
        this.noop = () => { };
        this.logLevel = LogLevel.INFO;
    }
    getConsoleMethod(logName) {
        let c = console;
        if (c[logName]) {
            return c[logName].bind(c);
        }
        // fallback to console.log
        if (console.log) {
            return console.log.bind(console);
        }
        return this.noop;
    }
    get trace() {
        if (this.logLevel <= LogLevel.TRACE) {
            // console.trace prints a stacktrace
            return this.getConsoleMethod('log');
        }
        return this.noop;
    }
    get debug() {
        if (this.logLevel <= LogLevel.DEBUG) {
            // console.debug is deprecated
            return this.getConsoleMethod('log');
        }
        return this.noop;
    }
    get log() {
        if (this.logLevel <= LogLevel.INFO) {
            return this.getConsoleMethod('log');
        }
        return this.noop;
    }
    get info() {
        if (this.logLevel <= LogLevel.INFO) {
            return this.getConsoleMethod('info');
        }
        return this.noop;
    }
    get table() {
        if (this.logLevel <= LogLevel.INFO) {
            return this.getConsoleMethod('table');
        }
        return this.noop;
    }
    get warn() {
        if (this.logLevel <= LogLevel.WARN) {
            return this.getConsoleMethod('warn');
        }
        return this.noop;
    }
    get error() {
        if (this.logLevel <= LogLevel.ERROR) {
            return this.getConsoleMethod('error');
        }
        return this.noop;
    }
    get fatal() {
        if (this.logLevel <= LogLevel.ERROR) {
            return this.getConsoleMethod('error');
        }
        return this.noop;
    }
}
exports.CommonLoggerService = CommonLoggerService;
