"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRechnungenApi = void 0;
function createRechnungenApi(api) {
    return {
        getRechnungen: (params) => api.metrics.get(`/api/rechnungen`, { query: params }),
        saveRechnungMahninfo: (info) => api.metrics.post(`/api/rechnungen/mahnung`, info),
    };
}
exports.createRechnungenApi = createRechnungenApi;
