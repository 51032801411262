"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigStichworte = void 0;
const types_1 = require("../../../types");
const util_1 = require("../utils/util");
function benchmarkConfigStichworte(topStichworte) {
    const childs = {};
    for (const s of topStichworte.sort((a, b) => a.name.localeCompare(b.name))) {
        const n = s.name || s.kuerzel || '??';
        const swSlugified = (0, util_1.slugifyStichwort)(s.name);
        childs[n] = {
            name: n,
            unit: types_1.BenchmarkConfigUnitType.PATIENTEN,
            virtual: true,
            value: `b.patientenStichworte${swSlugified}`,
            smartValue: 's / b.patienten * 100',
            smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
            help: `Patienten, die ${n} als Stichwort haben`,
            debug: [`patientenStichworte${swSlugified}Ids`],
            canFilterByPatid: true,
            color: s.farbe,
        };
    }
    return {
        name: 'Stichworte',
        isHeader: true,
        childs,
    };
}
exports.benchmarkConfigStichworte = benchmarkConfigStichworte;
