"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAuthApi = void 0;
const lodash_1 = require("lodash");
function createAuthApi(api) {
    return {
        // no auth
        login: (creds, longLogin = true) => api.metrics.post(`/auth/login`, Object.assign(Object.assign({}, creds), { longLogin })),
        logout: () => api.metrics.post(`/auth/logout`),
        createFirstUser: (token, firstName, lastname, password) => api.metrics.post(`/auth/createfirstuser`, { token, firstName, lastname, password }),
        checkFirstUserToken: (token) => api.metrics.post(`/auth/checkfirstusertoken`, { token }),
        getUserFromToken: (jwt, cid) => api.metrics.post(`/auth/userfromtoken`, { jwt, cid }),
        adminLogin: (tokenString) => api.metrics.post(`/auth/adminlogin`, { jwt: tokenString }),
        resetPassword: (email) => api.metrics.post(`/auth/resetpassword`, { email }, { responseType: 'text' }),
        acceptInvitation: (token) => api.metrics.post(`/auth/acceptinvite`, { token }),
        setNewPassword: (token, currentPassword, newPassword) => api.metrics.post(`/auth/setnewpassword`, {
            token,
            currentPassword,
            newPassword,
        }),
        checkPassword: (password) => api.metrics.post(`/auth/checkpassword`, { password }),
        allNews: (tags) => api.metrics.get(`/auth/news`, { query: { tags: (0, lodash_1.join)(tags) } }),
        news: (file) => api.ui.get(`/assets/news/${file}`, { responseType: 'text' }),
        // auth
        tokens: () => api.metrics.get(`/auth/tokens`),
        deleteToken: (id) => api.metrics.delete(`/auth/token/${id}`),
        secret: () => api.metrics.get(`/auth/secret`),
        users: () => api.metrics.get(`/auth/users`),
        usersInfo: () => api.metrics.get(`/auth/usersinfo`),
        user: () => api.metrics.get(`/auth/user`),
        updateUserSetting: (setting) => api.metrics.put(`/auth/user/setting`, setting),
        updateUserEmail: (userMail, newUserMail) => api.metrics.post(`/auth/changeusermail`, { userMail, newUserMail }),
        updateUser: (user) => api.metrics.put(`/auth/user`, user),
        createUser: (user) => api.metrics.post(`/auth/user`, user),
        deleteUser: (email) => api.metrics.delete(`/auth/user/${email}`),
        refreshToken: () => api.metrics.post(`/auth/refreshToken`),
        createMidSessionToken: () => api.metrics.post(`/auth/createMidSessionToken`),
        loadApiKey: () => api.metrics.get(`/api/apikey`),
        // mfa
        loginOtp: (otp) => api.metrics.post(`/auth/login/otp`, { otp }),
        enableMfaEmail: () => api.metrics.post(`/auth/enable-mfa-email`),
        setupMfaAuthenticator: () => api.metrics.post(`/auth/setup-mfa-authenticator`),
        enableMfaAuthenticator: (data) => api.metrics.post(`/auth/enable-mfa-authenticator`, data),
        disableMfa: (params) => api.metrics.post(`/auth/disable-mfa`, params),
        requestDisableMfaCode: () => api.metrics.post(`/auth/request-disable-mfa-code`),
        // unsubscribe Mail
        unsubscribeFromMail: (params) => api.metrics.post(`/auth/unsubscribeFromMail`, params),
        revokeSuppressionRequest: (params) => api.metrics.post(`/auth/revokeSuppressionRequest`, params),
        mailSuppressionStatus: (params) => api.metrics.post(`/auth/mailSuppressionStatus`, params),
    };
}
exports.createAuthApi = createAuthApi;
