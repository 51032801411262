"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAdminApi = void 0;
function createAdminApi(api) {
    return {
        getCharlyTemplates: (cid, r4chost) => api.r4c.get(r4chost, `/admin/charlytemplatesdocs`, { clientIdHeader: cid }),
        getCharlyTemplateRtf: (cid, r4chost, group, entry) => api.r4c.get(r4chost, `/admin/charlytemplate/${group}/${entry}`, {
            clientIdHeader: cid,
        }),
        renderRtf: (rtf, cid, r4chost, filename, options) => api.r4c.post(r4chost, `/admin/renderRtf`, {
            rtf,
            cid,
            filename,
            options,
        }),
        overrideCharlyTemplate: (cid, r4chost, group, entry, rtfText) => api.r4c.post(r4chost, `/admin/overrideCharlyTemplate`, {
            cid,
            group,
            entry,
            rtfText,
        }),
        restoreCharlyTemplate: (cid, r4chost, group, entry) => api.r4c.post(r4chost, `/admin/restoreCharlyTemplate`, {
            cid,
            group,
            entry,
        }),
        getRecentData: (cid) => api.r4cDispatch.get(`/admin/recentdata/${cid}`),
        retryuploadtask: (r4chost, id) => api.r4c.post(r4chost, `/factoringinit/retryuploadtask/${id}`),
        getFailedUploadsStats: (r4chost, params) => api.r4c.post(r4chost, `/factoringinit/getFailedUploadsStats`, params),
        retryFailedUploads: (r4chost, params) => api.r4c.post(r4chost, `/factoringinit/retryFailedUploads`, params),
        proxyCharlyRequest: ({ r4chost, cid, request, }) => api.r4c.post(r4chost, `/admin/proxyCharlyRequest`, { request, cid }),
        queryCharlySql: ({ r4chost, cid, request }) => api.r4c.post(r4chost, `/admin/queryCharlySql`, { request, cid }),
        // admin debug
        getEventTargetDebugInfo: (r4chost) => api.r4c.get(r4chost, `/admin-debug/events-debug`),
    };
}
exports.createAdminApi = createAdminApi;
