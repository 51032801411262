"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseResolverService = void 0;
const lodash_1 = require("lodash");
const types_1 = require("../../../types");
const config_1 = require("../config");
const logger_service_1 = require("./logger.service");
/**
 * ein resolver holt infos live aus der praxis, die wir beio uns nicht in der datenbank haben
 *
 * der prozess geht so: der augment function werden objekte übergeben, per extractDataObjects werden daraus objecte extrahiert. mit extractIds werden dann aus diesen objekten ids geholt mit denene man im server zusatzinfos anfragen kann.
 *
 * für datensätze vom typ TAUGMENT mittels einer schlüssel ID zusatzinformationen vom server vom typ TINFO, mit denen dann die TAUGMENT datensätze erweitert werden. dabei kümmert sich dieser base resolver um das laden, caching, blacklisting von fehlerhaften items etc.
 */
class BaseResolverService {
    constructor() {
        this.infoCache = {};
        this.blacklist = {}; // remember how often we where unsuccessful retrieving an info
        this.maxRetries = 10;
        this.fasterRetryTimeOutInSeconds = 0.5;
        this.retryTimeOutInSeconds = 5;
        this.verboseLogging = false;
        this.logger = new logger_service_1.CommonLoggerService();
        this.fakeData = types_1.UserPrivacyLevel.NONE;
    }
    clearCache() {
        this.infoCache = {};
        console.log(`cleared resolver cache of ${this.name}`);
    }
    augment(entries, runAfterAugmenting, options) {
        this.stop();
        // empty blacklist to get a fresh start
        this.blacklist = {};
        return this.augmentInfos(entries, runAfterAugmenting, options);
    }
    stop() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    /**
     * sometime it is necessary to have a different cache id than fetchid use this for that
     * @param cacheId is used fo caching
     */
    extractFetchId(cacheId) {
        return cacheId;
    }
    /**
     * this method tries to fetch termin comments directly from collector. infos will be cached and first tried to resolve from cache.
     * if there are infos which could not be resolved it is retried
     * @param entries entries with kommentar fields
     * @param retry is this a retry (only touch server if cache misses some names)
     */
    async augmentInfos(entries, runAfterAugmenting, options) {
        // fetch infos
        if (!(0, lodash_1.isEmpty)(entries)) {
            try {
                let dataObjects = (0, lodash_1.chain)(entries)
                    .flatMap((e) => this.extractDataObjects(e, options))
                    .compact()
                    .uniqBy((o) => this.extractId(o, options))
                    .value();
                let ids = (0, lodash_1.chain)(dataObjects)
                    .flatMap((e) => this.extractId(e, options))
                    .compact()
                    .uniq()
                    .value();
                // donot touch server in first try, use cache, if cache has entries
                let cachedIds = (0, lodash_1.keys)(this.infoCache);
                let blackListIds = (0, lodash_1.chain)(this.blacklist)
                    .toPairs()
                    .filter((x) => x[1] > this.maxRetries)
                    .map((x) => x[0])
                    .value();
                const limit = (options === null || options === void 0 ? void 0 : options.limit) || 10000;
                const diffIds = (0, lodash_1.difference)(ids, cachedIds, blackListIds);
                const limited = limit < (0, lodash_1.size)(diffIds);
                let fetchIds = (0, lodash_1.take)(diffIds, limit);
                if (!(0, lodash_1.isEmpty)(fetchIds)) {
                    const idsToFetch = (0, lodash_1.map)(fetchIds, id => this.extractFetchId(id));
                    let infos = [];
                    switch (this.fakeData) {
                        case types_1.UserPrivacyLevel.NONE:
                            infos = await this.loadInfo(idsToFetch, options);
                            break;
                        case types_1.UserPrivacyLevel.FAKENAMES:
                            infos = await this.fakeInfo(dataObjects, options);
                            break;
                        case types_1.UserPrivacyLevel.BLURNAMES:
                        case types_1.UserPrivacyLevel.BLURNAMESBUTNOTBEHANDLER:
                            infos = await this.blurInfo(dataObjects, options);
                            break;
                        default:
                            break;
                    }
                    if (config_1.isLocalhost && (0, lodash_1.size)(idsToFetch) > (0, lodash_1.size)(infos) && this.verboseLogging) {
                        this.logger.warn(`${this.name}: got less infos than we wanted: ${(0, lodash_1.size)(idsToFetch)} > ${(0, lodash_1.size)(infos)}`, fetchIds, infos);
                    }
                    if (!(0, lodash_1.isEmpty)(infos)) {
                        (0, lodash_1.assign)(this.infoCache, (0, lodash_1.keyBy)(infos, (i) => this.extractInfoKey(i, options)));
                    }
                }
                // console.log(
                //   `${this.name}: cache`,
                //   _(this.infoCache)
                //     .keys()
                //     .size(),
                //   this.infoCache,
                // );
                let emptyInfosIds = [];
                let changedIds = [];
                for (let e of entries) {
                    // TODO use global cache
                    let result = this.setResolvedInfo(e, id => this.infoCache[id], options);
                    changedIds = [...changedIds, ...result.changedIds];
                    // when an item could be changed no need to have it on the blacklist anymore
                    for (const id of (0, lodash_1.compact)(result.changedIds)) {
                        delete this.blacklist[id];
                    }
                    for (const id of result.failedIds) {
                        if (id) {
                            // first retry should be immediate
                            if (!this.blacklist[id]) {
                                this.blacklist[id] = 0;
                            }
                            if (this.blacklist[id] < this.maxRetries) {
                                emptyInfosIds.push(id);
                            }
                            this.blacklist[id]++;
                        }
                    }
                }
                // console.log('resolver result', emptyInfosCount, this.blacklist);
                if (runAfterAugmenting && !(0, lodash_1.isEmpty)(changedIds)) {
                    await runAfterAugmenting((0, lodash_1.chain)(changedIds).uniq().sort().value(), this.name);
                }
                if (!(0, lodash_1.isEmpty)(emptyInfosIds)) {
                    if (this.verboseLogging) {
                        this.logger.warn(`${this.name}: got ${(0, lodash_1.size)(emptyInfosIds)} missing info to resolve, try again in ${this.retryTimeOutInSeconds}s, tries ${(0, lodash_1.chain)(this.blacklist).values().max().value()}/${this.maxRetries}`, (0, lodash_1.sortBy)(emptyInfosIds), this.blacklist);
                    }
                    void this.tryToAugmentAgain(entries, runAfterAugmenting, options, limited);
                }
            }
            catch (e) {
                this.logger.info(`error in resolver: ${this.name}`, e);
                // first retry should be immediate
                void this.tryToAugmentAgain(entries, runAfterAugmenting, options);
            }
        }
    }
    tryToAugmentAgain(entries, runAfterAugmenting, options, tryFaster = false) {
        try {
            let timeout = 1000 * (tryFaster ? this.fasterRetryTimeOutInSeconds : this.retryTimeOutInSeconds);
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => this.augmentInfos(entries, runAfterAugmenting, options), timeout);
        }
        catch (err) {
            this.logger.error(err, `error while tryToAugmentAgain`);
        }
    }
}
exports.BaseResolverService = BaseResolverService;
