var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.viewNeedsEdit ? _c('v-btn', {
    attrs: {
      "depressed": "",
      "color": "error"
    },
    on: {
      "click": _vm.jumpToView
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-alert")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Ändern")])], 1) : _c('v-btn', {
    attrs: {
      "depressed": ""
    },
    on: {
      "click": _vm.jumpToView
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Ändern")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }