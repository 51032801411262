var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "onboarding-outer-wrap"
  }, [_c('div', {
    staticClass: "onboarding-content"
  }, [_vm.urlError ? _c('div', {
    staticClass: "url-error"
  }, [_c('v-card-title', [_c('div', {
    staticClass: "done"
  }, [_c('span', [_vm._v("Fehler!")]), _c('span', [_vm._v("Der von Ihnen benutzte Link sieht nicht richtig aus.")]), _c('span', [_vm._v("Zurück zur "), _c('a', {
    attrs: {
      "href": "https://www.rose.dental"
    }
  }, [_vm._v("rose-Homepage")]), _c('span', [_vm._v(".")])]), _c('pre', [_vm._v(_vm._s(_vm.urlError))])])])], 1) : _vm.initialized ? _c('Main') : _vm._e()], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "help"
  }, [_vm._v("Probleme? Hier melden: "), _c('a', {
    attrs: {
      "href": "tel:024189437924"
    }
  }, [_vm._v("0241-89437924")]), _vm._v(".")]);

}]

export { render, staticRenderFns }