import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { euroFilter, decimalFilter, modifyFaviconIfStaging, isProd } from '@rose/common-ui';
import LogRocket from 'logrocket';
import VueClipboard from 'vue-clipboard2';

import '@rose/common-ui/src/fonts/roboto';
import '@rose/common-ui/src/fonts/mdi';
import '@rose/common-ui/src/fonts/font-awesome';

if (isProd) {
  LogRocket.init('nyxxty/rose-onboarding');
}

modifyFaviconIfStaging();
Vue.config.productionTip = false;
Vue.filter('euro', euroFilter.filters.euro);
Vue.filter('decimal', decimalFilter.filters.decimal);

Vue.use(VueClipboard);

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
