"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeItem = void 0;
class TimeItem {
    constructor(h, m) {
        if (h > 24) {
            throw new Error(`Stunde (${h}) darf nicht größer als 24 sein`);
        }
        if (h < 0) {
            throw new Error(`Stunde (${h}) darf nicht kleiner als 0 sein`);
        }
        if (m > 60) {
            throw new Error(`Minute (${m}) darf nicht größer als 60 sein`);
        }
        if (m < 0) {
            throw new Error(`Minute (${m}) darf nicht kleiner als 0 sein`);
        }
        if (m < 60) {
            this.hour = h;
            this.minute = m;
        }
        else {
            this.hour = h + 1;
            this.minute = 0;
        }
    }
    static Now() {
        let now = new Date();
        let minutes = now.getMinutes() - (now.getMinutes() % 15);
        return new TimeItem(now.getHours(), minutes);
    }
    compare(other) {
        if (!other) {
            return 1;
        }
        let hourCompare = this.compareNumbers(this.hour, other.hour);
        if (hourCompare === 0) {
            return this.compareNumbers(this.minute, other.minute);
        }
        return hourCompare;
    }
    compareNumbers(a, b) {
        if (a === b) {
            return 0;
        }
        return a > b ? 1 : -1;
    }
    isBetween(from, to) {
        return this.compare(from) > 0 && this.compare(to) < 0;
    }
    static parse(s) {
        if (s && s.length > 0) {
            let parts = s.split(':').map(p => p.trim());
            if (parts.length > 0) {
                let hour = parseInt(parts[0], 10);
                if (parts.length > 1) {
                    let min = parseInt(parts[1], 10);
                    return new TimeItem(hour, min);
                }
                else {
                    if (hour > 100 && hour <= 2400) {
                        let hs = hour + '';
                        return TimeItem.parse(hs.length === 3 ? `${hs.substring(0, 1)}:${hs.substring(2)}` : `${hs.substring(0, 2)}:${hs.substring(3)}`);
                    }
                    else {
                        return new TimeItem(hour, 0);
                    }
                }
            }
        }
        return undefined;
    }
    static addHours(ti, hours) {
        let partBeforeKomma = Math.trunc(hours);
        let partAfterKomma = hours - partBeforeKomma;
        let minutes = Math.round(partAfterKomma * 60) + ti.minute;
        if (minutes >= 60) {
            partBeforeKomma++;
            minutes -= 60;
        }
        return new TimeItem(ti.hour + partBeforeKomma, minutes);
    }
    static subtractHours(ti, hours) {
        let partBeforeKomma = Math.trunc(hours);
        let partAfterKomma = hours - partBeforeKomma;
        let minutes = ti.minute - Math.round(partAfterKomma * 60);
        if (minutes < 0) {
            partBeforeKomma++;
            minutes += 60;
        }
        return new TimeItem(ti.hour - partBeforeKomma, minutes);
    }
    static diff(a, b) {
        if (a != null && b != null) {
            if (a.compare(b) === 0) {
                return 0;
            }
            if (a.compare(b) > 0) {
                return TimeItem.diff(b, a);
            }
            // do the real math, we know a is smaller than b here
            if (a.hour === b.hour) {
                return (b.minute - a.minute) / 60;
            }
            let minutes = 60 - a.minute + b.minute;
            let hours = b.hour - (a.hour + 1); // +1 because we took the minutes from the started hour into minutes
            return (hours * 60 + minutes) / 60;
        }
        return 0;
    }
    toString() {
        return this.minute < 10 ? `${this.hour}:0${this.minute}` : `${this.hour}:${this.minute}`;
    }
}
exports.TimeItem = TimeItem;
