"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETagService = void 0;
const config_1 = require("./config");
const ETAG_HEADER_NAME = 'etag';
/**
 * Usage:
 * let service = new ETagService();
 * service.startCheckForNewUiVersionInterval(60, () => { ... });
 */
class ETagService {
    constructor() {
        this.intervalId = 0;
        this.startupEtag = '';
    }
    async init() {
        this.startupEtag = await this.getCurrentRootEtag();
    }
    async getCurrentRootEtag() {
        let response = await fetch('/versions.json');
        let versions = await response.json();
        return versions.build;
        // old version: use actual etag
        // let etag = response.headers.get(ETAG_HEADER_NAME) || '';
        // // strip compression/ssl information
        // // following are all possible and the same
        // // c05e8354a5c5cefe79a5270361b00004-ssl-df
        // // c05e8354a5c5cefe79a5270361b00004-ssl
        // // c05e8354a5c5cefe79a5270361b00004
        // etag = etag.split('-')[0];
        // return etag;
    }
    async startCheckForNewUiVersionInterval(checkIntervalInSec = 60, changedCallBack) {
        try {
            await this.init();
        }
        catch (e) {
            console.warn('%c etagService etag not available ', 'background: darkorange; color: white', e.message);
            throw e;
        }
        if (config_1.isDev) {
            console.info('Not checking etag in dev.');
            return;
        }
        if (checkIntervalInSec > 0) {
            this.intervalId = window.setInterval(async () => {
                let outOfDate = await this.checkIfOutOfDate();
                if (outOfDate.outofdate) {
                    console.warn('%c etag has changed ', 'background: darkorange; color: white', outOfDate);
                    clearInterval(this.intervalId);
                    if (changedCallBack) {
                        changedCallBack(outOfDate);
                    }
                }
            }, checkIntervalInSec * 1000);
        }
    }
    /**
     * this method fetches the current etag and compares it with the etag retrieved from the app startup
     * only returns true if a new etag can be confirmed
     */
    async checkIfOutOfDate() {
        // return { outofdate: true };
        let startupEtag = this.startupEtag;
        if (!startupEtag) {
            return { outofdate: false, oldetag: startupEtag, newetag: null };
        }
        try {
            let current = await this.getCurrentRootEtag();
            if (current !== startupEtag) {
                return { outofdate: true, oldetag: startupEtag, newetag: current };
            }
            else {
                return { outofdate: false, oldetag: startupEtag, newetag: current };
            }
        }
        catch (e) {
            return { outofdate: false, oldetag: startupEtag, newetag: null };
        }
    }
}
exports.ETagService = ETagService;
