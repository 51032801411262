"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modifyFavicon = exports.modifyFaviconIfStaging = void 0;
const config_1 = require("./config");
function modifyFaviconIfStaging() {
    if (config_1.isStaging || config_1.isDev) {
        modifyFavicon();
    }
}
exports.modifyFaviconIfStaging = modifyFaviconIfStaging;
// settings
const barHeightInPercent = 25;
const colorOne = config_1.isDev ? 'red' : 'yellow';
const colorTwo = config_1.isDev ? 'white' : 'black';
const howManyStripes = 10; // this should be even
const faviconSize = 32;
function modifyFavicon() {
    let queryFavicon = document.querySelector('link[rel="icon"], link[rel="shortcut icon"]');
    if (!queryFavicon) {
        console.warn('[modifyFavicon] Favicon link element was not found');
        return;
    }
    let favicon = queryFavicon;
    let canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;
    let context = canvas.getContext('2d');
    let img = document.createElement('img');
    img.src = favicon.href;
    img.onload = () => {
        // Draw Original Favicon as Background
        context.drawImage(img, 0, 0, faviconSize, faviconSize);
        // drawStripedBar(context);
        drawTriangle(context);
        // Replace favicon
        favicon.href = canvas.toDataURL('image/png');
    };
}
exports.modifyFavicon = modifyFavicon;
function drawTriangle(context) {
    const triangleWidth = (barHeightInPercent / 100) * 2.5;
    context.beginPath();
    context.fillStyle = colorOne;
    context.moveTo(faviconSize * (1 - triangleWidth), faviconSize);
    context.lineTo(faviconSize, faviconSize);
    context.lineTo(faviconSize, faviconSize * (1 - triangleWidth));
    context.fill();
}
function drawStripedBar(context) {
    const barHeight = barHeightInPercent / 100;
    context.beginPath();
    context.fillStyle = colorOne;
    context.rect(0, faviconSize * (1 - barHeight), faviconSize, faviconSize * barHeight);
    context.fill();
    const barWidth = (faviconSize * 1) / howManyStripes;
    context.fillStyle = colorTwo;
    for (let i = 0; i <= howManyStripes / 2; i++) {
        context.fillRect(2 * i * barWidth, faviconSize * (1 - barHeight), barWidth, faviconSize * barHeight);
    }
}
