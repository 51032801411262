"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPraxisKPIApi = void 0;
function createPraxisKPIApi(api) {
    return {
        // TODO: Type response
        getPraxisKPI: (queryParams) => api.metrics.get(`/api/praxiskpi`, { query: queryParams }),
        getPraxisKPIExcel: (queryParams) => api.metrics.get(`/api/praxiskpi/excel`, { query: queryParams }),
    };
}
exports.createPraxisKPIApi = createPraxisKPIApi;
