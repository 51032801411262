"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4WorkflowsApi = void 0;
function createR4WorkflowsApi(api) {
    return {
        assignTabletWorkflow: (params) => api.r4cInstance.post(`/tablet/task/assignTabletWorkflow`, params),
        shareWorkflowPerMail: (params) => api.r4cInstance.post(`/tablet/task/shareWorkflowPerMail`, params),
    };
}
exports.createR4WorkflowsApi = createR4WorkflowsApi;
