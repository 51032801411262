"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientenUmsaetzeApi = void 0;
function createPatientenUmsaetzeApi(api) {
    return {
        getPatientenUmsaetze: (queryParams) => api.metrics.get(`/api/patientenumsaetze`, {
            query: queryParams,
        }),
    };
}
exports.createPatientenUmsaetzeApi = createPatientenUmsaetzeApi;
