"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.factoryViewSettings = exports.hkpFilters = exports.hkpFilterGroups = void 0;
/* eslint-disable @typescript-eslint/no-unsafe-argument */
const lodash_1 = require("lodash");
const types_1 = require("../../types");
const hkpUtils_1 = require("./utils/hkpUtils");
const rose_dayjs_1 = require("./rose-dayjs");
const quartalStart = rose_dayjs_1.roseDayjs.utc().startOf('quarter');
const quartalEnde = rose_dayjs_1.roseDayjs.utc().endOf('quarter');
function checkHkpTerminMismatch(hkp, days) {
    let hkpTermine = (0, lodash_1.filter)(hkp.folgetermine, ft => ft.nomatch && (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween((0, rose_dayjs_1.roseDayjs)(), (0, rose_dayjs_1.roseDayjs)().add(days, 'days')));
    return !(0, lodash_1.isEmpty)(hkpTermine);
}
let hkpFilterGroups = (labelConfig) => {
    let fg = {
        // TERMINE
        termine: {
            order: 10,
            selected: 'termine:ohneFolgetermine',
            lastSelected: 'termine:ohneFolgetermine',
            filters: [
                {
                    key: 'termine:ohneFolgetermine',
                    name: 'Ohne Folgetermine',
                    rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN),
                    help: 'Patienten mit HKP, die keinen Folgetermin vereinbart haben',
                },
                {
                    key: 'termine:ohnePassendenFolgeterminezeimpla',
                    name: 'Ohne passende Folgetermine (nur ZE und Impla)',
                    rowFilter: rd => rd.nomatchingterminzeimpla,
                    help: 'Patienten mit ZE/Impla-HKP, die keinen zum genehmigten HKP passenden Folgetermin vereinbart haben',
                },
                {
                    key: 'termine:ohnePassendenFolgetermine',
                    name: 'Ohne passende Folgetermine',
                    rowFilter: rd => rd.nomatchingtermin,
                    help: 'Patienten mit HKP, die keinen zum genehmigten HKP passenden Folgetermin vereinbart haben',
                },
                {
                    key: 'termine:mitFolgtermine',
                    name: 'Mit Folgeterminen',
                    rowFilter: rd => (0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN),
                    help: 'Patienten mit HKP, die Folgetermine vereinbart haben',
                },
                {
                    key: 'termine:hkpterminein7',
                    name: 'HKP-Termin binnen 7 Tagen, ohne genehmigten HKP',
                    rowFilter: rd => checkHkpTerminMismatch(rd, 7),
                    help: 'Alle Patienten, die einen HKP-Termin zwischen heute und in 7 Tagen haben, aber keinen genehmigten HKP',
                },
                {
                    key: 'termine:hkpterminein14',
                    name: 'HKP-Termin binnen 14 Tagen, ohne genehmigten HKP',
                    rowFilter: rd => checkHkpTerminMismatch(rd, 14),
                    help: 'Alle Patienten, die einen HKP-Termin zwischen heute und in 14 Tagen haben, aber keinen genehmigten HKP',
                },
                {
                    key: 'termine:hkpterminein30',
                    name: 'HKP-Termin binnen 30 Tagen, ohne genehmigten HKP',
                    rowFilter: rd => checkHkpTerminMismatch(rd, 30),
                    help: 'Alle Patienten, die einen HKP-Termin zwischen heute und in 30 Tagen haben, aber keinen genehmigten HKP',
                },
                {
                    key: 'termine:baldtermine1wo',
                    name: 'Termin binnen 7 Tagen',
                    rowFilter: rd => (0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween((0, rose_dayjs_1.roseDayjs)(), (0, rose_dayjs_1.roseDayjs)().add(7, 'days'))),
                    help: 'Alle Patienten, die einen Termin zwischen heute und in sieben Tagen haben',
                },
                {
                    key: 'termine:baldtermine3wo',
                    name: 'Termin binnen 3 Wochen',
                    rowFilter: rd => (0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween((0, rose_dayjs_1.roseDayjs)(), (0, rose_dayjs_1.roseDayjs)().add(3, 'weeks'))),
                    help: 'Alle Patienten, die einen Termin zwischen heute und in drei Wochen haben',
                },
                {
                    key: 'termine:ohneOffeneTermine',
                    name: 'Ohne offene Termine',
                    rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status === types_1.TerminStatusType.OFFEN),
                    help: 'Patienten mit HKP, die keinen offenen Termin haben',
                },
                {
                    key: 'termine:ohneOffeneUndFolgeTermine',
                    name: 'Ohne offene und Folgetermine',
                    rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status === types_1.TerminStatusType.OFFEN),
                    help: 'Patienten mit HKP, die keinen offenen und keinen Folgetermin haben',
                },
                {
                    key: 'termine:mitOffenenTerminen',
                    name: 'Mit offenen Terminen',
                    rowFilter: rd => !(0, lodash_1.some)(rd.folgetermine, ft => ft.status !== types_1.TerminStatusType.OFFEN) &&
                        (0, lodash_1.some)(rd.folgetermine, ft => ft.status === types_1.TerminStatusType.OFFEN),
                    help: 'Patienten mit HKP, die offene Termin haben',
                },
                {
                    key: 'termine:ohneTerminImGanzenQuartal',
                    name: 'Ohne Termin im ganzen Quartal',
                    rowFilter: rd => !(0, lodash_1.some)(rd.termine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween(quartalStart, quartalEnde, undefined, '[]')),
                    help: 'Patienten mit HKP, die keinen Termin im ganzen Quartal haben',
                },
                {
                    key: 'termine:ohneTerminImQuartalBisHeute',
                    name: 'Ohne Termin im Quartal bis heute',
                    rowFilter: rd => !(0, lodash_1.some)(rd.termine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween(quartalStart, (0, rose_dayjs_1.roseDayjs)(), undefined, '[]')),
                    help: 'Patienten mit HKP, die keinen Termin in diesem Quartal bis heute haben',
                },
                {
                    key: 'termine:mitTerminImGanzenQuartal',
                    name: 'Mit Termin im ganzen Quartal',
                    rowFilter: rd => (0, lodash_1.some)(rd.termine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween(quartalStart, quartalEnde, undefined, '[]')),
                    help: 'Patienten mit HKP, die einen Termin im ganzen Quartal haben',
                },
                {
                    key: 'termine:mitTerminImQuartalBisHeute',
                    name: 'Mit Termin im Quartal bis heute',
                    rowFilter: rd => (0, lodash_1.some)(rd.termine, ft => ft.status !== types_1.TerminStatusType.OFFEN &&
                        (0, rose_dayjs_1.roseDayjs)(ft.tag).isBetween(quartalStart, (0, rose_dayjs_1.roseDayjs)(), undefined, '[]')),
                    help: 'Patienten mit HKP, die einen Termin in diesem Quartal bis heute haben',
                },
            ],
        },
        // ABGELAUFEN
        abgelaufen: {
            order: 20,
            selected: 'abgelaufen:abgelaufen',
            lastSelected: 'abgelaufen:abgelaufen',
            filters: [
                {
                    key: 'abgelaufen:abgelaufen',
                    name: 'Abgelaufen',
                    rowFilter: rd => rd.abgelaufen,
                    help: 'HKPs deren Gültigkeit abgelaufen ist',
                },
                {
                    key: 'abgelaufen:baldAbgelaufen',
                    name: 'Läuft bald ab',
                    rowFilter: rd => !!rd.gueltigBis && (0, rose_dayjs_1.roseDayjs)(rd.gueltigBis).isBetween((0, rose_dayjs_1.roseDayjs)(), (0, rose_dayjs_1.roseDayjs)().add(2, 'weeks')),
                    help: 'HKPs deren Gültigkeit in den nächsten 2 Wochen abläuft',
                },
                {
                    key: 'abgelaufen:abgelaufenOderBaldAbgelaufen',
                    name: 'Abg. oder läuft bald ab',
                    rowFilter: rd => rd.abgelaufen ||
                        (!!rd.gueltigBis && (0, rose_dayjs_1.roseDayjs)(rd.gueltigBis).isBetween((0, rose_dayjs_1.roseDayjs)(), (0, rose_dayjs_1.roseDayjs)().add(2, 'weeks'))),
                    help: 'HKPs deren Gültigkeit abgelaufen ist oder in den nächsten 2 Wochen abläuft',
                },
            ],
        },
        // ABZURECHNEN
        abzurechnen: {
            order: 30,
            selected: 'abzurechnen:abzurechnen',
            lastSelected: 'abzurechnen:abzurechnen',
            filters: [
                {
                    key: 'abzurechnen:abzurechnen',
                    name: 'Abzurechnen',
                    rowFilter: rd => rd.abzurechnen > types_1.HKPAbzurechnenFlag.NICHT_ABRECHNEN,
                    help: 'Nicht abgerechnete HKPs mit abgeschlossener Behandlung oder deren Leistungen ins Behandlungsblatt übernommen worden sind',
                },
                {
                    key: 'abzurechnen:baldabzurechnen',
                    name: 'Fällig abzurechnen',
                    rowFilter: rd => rd.baldabzurechnen,
                    help: 'Nicht abgerechnete HKPs mit abgeschlossener Behandlung oder deren Leistungen ins Behandlungsblatt übernommen worden sind und die bald nicht mehr abzurechnen sind',
                },
            ],
        },
        // LEISTUNGEN ERBRACHT
        leistungenrot: {
            order: 31,
            selected: 'leistungenrot:erbracht',
            lastSelected: 'leistungenrot:erbracht',
            filters: [
                {
                    key: 'leistungenrot:erbracht',
                    name: 'Leistungen erbracht',
                    rowFilter: rd => rd.betragleistungenerbracht > 0 && rd.begonnen,
                    help: 'HKPs aus denen bereits Leistungen ins Behandlungsblatt übernommen worden sind',
                },
                {
                    key: 'leistungenrot:abzurechnen',
                    name: 'Leistungen abzurechnen',
                    rowFilter: rd => rd.betragleistungenerbracht > 0 &&
                        rd.begonnen &&
                        (!hkpUtils_1.hkpArtIds.PA.artIds.includes(rd.art) || rd.parstatus?.phase >= types_1.HKPPARPhaseType.UPT),
                    help: 'HKPs aus denen bereits Leistungen ins Behandlungsblatt übernommen worden sind und die abgerechnet werden können',
                },
            ],
        },
        // FRIST
        frist: {
            order: 40,
            selected: 'frist:frist',
            lastSelected: 'frist:frist',
            filters: [
                {
                    key: 'frist:frist',
                    name: 'Frist gesetzt',
                    rowFilter: rd => !!rd.meta && rd.meta.fristaktiv,
                    help: 'HKPs für die unter Details eine Frist gesetzt wurde, unabhängig davon ob das Fälligkeitsdatum in der Zukunft liegt oder bereits überschritten worden ist',
                },
                {
                    key: 'frist:fristabgelaufen',
                    name: 'Frist fällig',
                    rowFilter: rd => !!rd.meta && rd.meta.fristaktiv && !!rd.meta.frist && rd.meta.frist < new Date(),
                    help: 'HKPs für die unter Details eine Frist gesetzt wurde, deren Fälligkeitsdatum überschritten worden ist',
                },
            ],
        },
        // MIT LABEL
        mitlabel: {
            order: 50,
            selected: 'mitlabel:alle',
            lastSelected: 'mitlabel:alle',
            filters: [
                {
                    key: 'mitlabel:alle',
                    name: 'Mit Label',
                    rowFilter: rd => !(0, lodash_1.isEmpty)(rd.labels),
                    help: 'Alle HKPs mit Label',
                },
                ...(0, lodash_1.map)(labelConfig, l => ({
                    key: (l.colorId + ''),
                    name: l.title,
                    rowFilter: (rd) => (0, lodash_1.some)(rd.labels, lbl => lbl.colorId === l.colorId),
                    help: `Alle HKP mit dem Label: ${l.title}`,
                })),
            ],
        },
        // OHNE LABEL
        ohnelabel: {
            order: 60,
            selected: 'ohnelabel:alle',
            lastSelected: 'ohnelabel:alle',
            filters: [
                {
                    key: 'ohnelabel:alle',
                    name: 'Ohne Label',
                    rowFilter: rd => (0, lodash_1.isEmpty)(rd.labels),
                    help: 'Alle HKPs ohne Label',
                },
                ...(0, lodash_1.map)(labelConfig, l => ({
                    key: (l.colorId + ''),
                    name: l.title,
                    rowFilter: (rd) => !(0, lodash_1.some)(rd.labels, lbl => lbl.colorId === l.colorId),
                    help: `Alle HKP ohne Label: ${l.title}`,
                })),
            ],
        },
        // PAR-STATUS
        parstatus: {
            order: 80,
            selected: 'parstatus:alle',
            lastSelected: 'parstatus:alle',
            filters: [
                {
                    key: 'parstatus:alle',
                    name: 'Mit PAR',
                    rowFilter: rd => !!(0, lodash_1.first)(rd.par)?.parstatus,
                    help: 'Alle HKPs mit PAR',
                },
                {
                    key: 'parstatus:par',
                    name: 'Status PAR',
                    rowFilter: rd => {
                        const par = (0, lodash_1.first)(rd.par);
                        return !!par && par?.parstatus?.phase <= types_1.HKPPARPhaseType.PAR_BEFUNDEVALUATION;
                    },
                    help: 'Alle HKPs in der Hauptbehandlung',
                },
                {
                    key: 'parstatus:cpt',
                    name: 'Status CPT',
                    rowFilter: rd => {
                        const par = (0, lodash_1.first)(rd.par);
                        return (!!par &&
                            par?.parstatus?.phase > types_1.HKPPARPhaseType.PAR_BEFUNDEVALUATION &&
                            par?.parstatus?.phase < types_1.HKPPARPhaseType.UPT);
                    },
                    help: 'Alle HKPs im CPT Stadium',
                },
                {
                    key: 'parstatus:upt',
                    name: 'Status UPT',
                    rowFilter: rd => {
                        const par = (0, lodash_1.first)(rd.par);
                        return !!par && par?.parstatus?.phase >= types_1.HKPPARPhaseType.UPT;
                    },
                    help: 'Alle HKPs im UPT Stadium',
                },
                {
                    key: 'parstatus:grada',
                    name: 'Grad A',
                    rowFilter: rd => (0, lodash_1.first)(rd.par)?.parstatus?.grad === types_1.PAGradType.A,
                    help: 'Alle HKPs mit Grad A',
                },
                {
                    key: 'parstatus:gradb',
                    name: 'Grad B',
                    rowFilter: rd => (0, lodash_1.first)(rd.par)?.parstatus?.grad === types_1.PAGradType.B,
                    help: 'Alle HKPs mit Grad B',
                },
                {
                    key: 'parstatus:gradc',
                    name: 'Grad C',
                    rowFilter: rd => (0, lodash_1.first)(rd.par)?.parstatus?.grad === types_1.PAGradType.C,
                    help: 'Alle HKPs mit Grad C',
                },
                {
                    key: 'parstatus:hatcpt',
                    name: 'Mit chirurgischer Therapie',
                    rowFilter: rd => {
                        const par = (0, lodash_1.first)(rd.par);
                        return (!!par &&
                            [types_1.CPTPlanStatus.NUR_ANTRAG, types_1.CPTPlanStatus.CPT_GEPLANT, types_1.CPTPlanStatus.CPT_DURCHGEFUEHRT].includes(par?.cptPlanStatus));
                    },
                    help: 'Alle HKPs mit chirurgischer Therapie',
                },
            ],
        },
        // PAR-FEHLER
        parfehler: {
            order: 90,
            selected: 'parfehler:alle',
            lastSelected: 'parfehler:alle',
            filters: [
                {
                    key: 'parfehler:alle',
                    name: 'Mit PAR-Fehlern',
                    rowFilter: rd => {
                        const par = (0, lodash_1.first)(rd.par);
                        return !!par && hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) && par?.fehleranzahl?.all > 0;
                    },
                    help: 'Alle HKPs mit PAR Problemen',
                },
                {
                    key: 'parfehler:abrechnung',
                    name: 'Abrechnungshinweise',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            e.fehler?.typ === types_1.PAREreignisFehlerTyp.ABRECHNUNG)
                            .value(),
                    help: 'Alle HKPs mit Abrechnungshinweisen',
                },
                {
                    key: 'parfehler:terminierungalle',
                    name: 'Terminierungsfehler alle',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [
                                types_1.PAREreignisFehlerTyp.BEV_FRISTVERLETZUNG,
                                types_1.PAREreignisFehlerTyp.KEINE_BEV_GEPLANT,
                                types_1.PAREreignisFehlerTyp.KEINE_UPT_GEPLANT,
                                types_1.PAREreignisFehlerTyp.KEIN_PA_TERMIN_GEPLANT,
                                types_1.PAREreignisFehlerTyp.UPT_ENTFALLEN,
                                types_1.PAREreignisFehlerTyp.UPT_FRISTVERLETZUNG,
                            ].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs mit Terminierungshinweisen',
                },
                {
                    key: 'parfehler:terminierungfolge',
                    name: 'Kein PAR/UPT Folgetermin',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [types_1.PAREreignisFehlerTyp.KEINE_UPT_GEPLANT, types_1.PAREreignisFehlerTyp.KEIN_PA_TERMIN_GEPLANT].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs ohne PAR/UPT-Folgetermine',
                },
                {
                    key: 'parfehler:terminierungplanung',
                    name: 'Fehlerhafte Planung',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [types_1.PAREreignisFehlerTyp.KEINE_UPT_GEPLANT, types_1.PAREreignisFehlerTyp.KEIN_PA_TERMIN_GEPLANT].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs mit fehlerhafter Terminplanung',
                },
                {
                    key: 'parfehler:terminierungbev',
                    name: 'BEV',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [
                                types_1.PAREreignisFehlerTyp.BEV_FRISTVERLETZUNG,
                                types_1.PAREreignisFehlerTyp.KEIN_BEV_TERMIN,
                                types_1.PAREreignisFehlerTyp.KEINE_BEV_GEPLANT,
                            ].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs mit Terminierungsfehlern bzgl. BEV',
                },
                {
                    key: 'parfehler:terminierungupt',
                    name: 'UPT',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => (!!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [
                                types_1.PAREreignisFehlerTyp.KEINE_UPT_GEPLANT,
                                types_1.PAREreignisFehlerTyp.UPT_ENTFALLEN,
                                types_1.PAREreignisFehlerTyp.UPT_FRISTVERLETZUNG,
                            ].includes(e.fehler?.typ)) ||
                            (!!e.fehler?.typ && [types_1.PAREreignisFehlerTyp.UPTG_ERBRINGBAR].includes(e.fehler?.typ)))
                            .value(),
                    help: 'Alle HKPs mit Terminierungsfehlern bzgl. UPT',
                },
                {
                    key: 'parfehler:terminierungupterbringbar',
                    name: 'UPTg erbringbar',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.typ && [types_1.PAREreignisFehlerTyp.UPTG_ERBRINGBAR].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs bei denen die UPTg erbringbar wäre, in einem späteren Termin',
                },
                {
                    key: 'parfehler:terminierungverfallen',
                    name: 'Verfallene Termine',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [types_1.PAREreignisFehlerTyp.KEIN_BEV_TERMIN, types_1.PAREreignisFehlerTyp.UPT_ENTFALLEN].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs mit verfallenen Terminen',
                },
                {
                    key: 'parfehler:abrechnung',
                    name: 'Abrechnung',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            [types_1.PAREreignisFehlerTyp.ABRECHNUNG, types_1.PAREreignisFehlerTyp.UPT_ENTFALLEN].includes(e.fehler?.typ))
                            .value(),
                    help: 'Alle HKPs mit Abrechnungshinweisen',
                },
                {
                    key: 'parfehler:cpt',
                    name: 'CPT',
                    rowFilter: rd => hkpUtils_1.hkpArtIds.KassePA.artIds.includes(rd.art) &&
                        (0, lodash_1.chain)((0, lodash_1.first)(rd.par)?.gruppen)
                            .flatMap(g => g.ereignisse)
                            .some(e => !!e.fehler?.countValue &&
                            e.fehler?.countValue > 0 &&
                            ([types_1.PAREreignisFehlerTyp.CPT_PLANUNG].includes(e.fehler?.typ) ||
                                (0, lodash_1.first)(rd.par)?.currentview === types_1.PAREreignisPhaseTyp.CPT))
                            .value(),
                    help: 'Alle HKPs mit CPT-Fehlern',
                },
            ],
        },
        // UNTERSCHRIFT
        unterschrift: {
            order: 100,
            selected: 'unterschrift:ohne',
            lastSelected: 'unterschrift:ohne',
            filters: [
                {
                    key: 'unterschrift:mit',
                    name: 'Mit Unterschrift',
                    rowFilter: rd => !(0, lodash_1.isNil)(rd.unterschriebenAm),
                    help: 'Alle HKPs mit Unterschrift',
                },
                {
                    key: 'unterschrift:ohne',
                    name: 'Ohne Unterschrift',
                    rowFilter: rd => (0, lodash_1.isNil)(rd.unterschriebenAm),
                    help: 'Alle HKPs ohne Unterschrift',
                },
                {
                    key: 'unterschrift:genehmigtohne',
                    name: 'Genehmigt ohne Unterschrift',
                    rowFilter: rd => !!rd.statusgenehmigt && (0, lodash_1.isNil)(rd.unterschriebenAm),
                    help: 'Alle genehmigten HKPs ohne Unterschrift',
                },
            ],
        },
    };
    (0, lodash_1.mapValues)(fg, v => {
        v.helpAll = (0, lodash_1.chain)(v.filters)
            .map(f => `- **${f.name}:** ${f.help}`)
            .join('\n\n')
            .value();
    });
    return fg;
};
exports.hkpFilterGroups = hkpFilterGroups;
let hkpFilters = () => ({
    // REST
    ignorefiltered: {
        name: 'Nachgeladen',
        rowFilter: rd => !!rd.ignoreByFilters,
        help: 'HKPs, die nachgeladen wurden und von anderen Filtern ignoriert werden',
    },
    additionallyloaded: {
        name: 'Zusätzlich geladen',
        rowFilter: (rd, showAdditionalLoads) => showAdditionalLoads && rd.additionallyLoaded,
        help: 'HKPs, die zusätzlich geladen wurden',
    },
    mitkommentaren: {
        name: 'Mit Kommentaren',
        rowFilter: rd => !!rd.meta && !(0, lodash_1.isEmpty)(rd.meta.kommentare),
        help: 'HKPs mit Kommentaren',
    },
    veraltet: {
        name: 'Veraltete Patienten',
        rowFilter: rd => !!rd.veraltet,
        help: 'HKPs von veraltetetn Patienten',
    },
    ebzstatusmissmatch: {
        name: 'EBZ-Status anders',
        rowFilter: rd => rd.ebzstatus === 'GENEHMIGT' && rd.status < types_1.HKPStatusType.GENEHMIGT,
        help: 'Zeige HKPs, deren EBZ-Status als genehmigt markiert sind, aber im PVS nicht als genehmigt markiert sind',
    },
});
exports.hkpFilters = hkpFilters;
let i = 0;
exports.factoryViewSettings = {
    'Genehmigt, ohne Termin': {
        key: 'fav:genehmigtOhneTermin',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: {
            hkpstatus: '5',
            'filterByTermine:ohneFolgetermine': 'true',
        },
        order: i++,
    },
    'Eingabe oder Genehmigt, ohne Termin': {
        key: 'fav:eingabeOderGenehmigtOhneTermin',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '0,5', 'filterByTermine:ohneFolgetermine': 'true' },
        order: i++,
    },
    'Genehmigt, ohne passenden Termin': {
        key: 'fav:genehmigtOhnePassendenTermin',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '5', 'filterByTermine:ohnePassendenFolgetermine': 'true' },
        order: i++,
    },
    'Eingabe oder Genehmigt, ohne passenden Termin': {
        key: 'fav:eingabeOderGenehmigtOhnePassendenTermin',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '0,5', 'filterByTermine:ohnePassendenFolgetermine': 'true' },
        order: i++,
    },
    'Mit HKP-Termin in den nächsten 7 Tagen, ohne Genehmigung': {
        key: 'fav:hkpTerminIn7TagenOhneGenehmigung',
        hkpManagerSortModel: [{ sort: 'desc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '0', 'filterByTermine:hkpterminein7': 'true' },
        order: i++,
    },
    'PA-Pläne mit Problemen': {
        key: 'fav:paPlaeneMitProblemen',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpart: (0, lodash_1.join)(hkpUtils_1.hkpArtIds.PA.artIds), 'filterByParfehler:alle': 'true' },
        order: i++,
    },
    'Nicht abgerechnet, mit abgeschlossener Behandlung': {
        key: 'fav:nichtAbgerechnetMitAbgeschlossenerBehandlung',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '5', 'filterByAbzurechnen:abzurechnen': 'true' },
        order: i++,
    },
    'Begonnen ohne Genehmigung': {
        key: 'fav:begonnenOhneGenehmigung',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '0', 'filterByLeistungenrot:erbracht': 'true' },
        order: i++,
    },
    Fristsachen: {
        key: 'fav:fristsachen',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'meta' }],
        hkpManagerViewState: { hkpstatus: '0,5', 'filterByFrist:frist': 'true' },
        order: i++,
    },
    'Fällige Fristsachen': {
        key: 'fav:faelligeFristsachen',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'meta' }],
        hkpManagerViewState: { hkpstatus: '0,5', 'filterByFrist:fristabgelaufen': 'true' },
        order: i++,
    },
    Abgelaufen: {
        key: 'fav:abgelaufen',
        hkpManagerSortModel: [{ sort: 'asc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '5', 'filterByAbgelaufen:abgelaufen': 'true' },
        order: i++,
    },
    'Offen, älter als 1 Jahr': {
        key: 'fav:offenAelterAls1Jahr',
        hkpManagerSortModel: [{ sort: 'desc', colId: 'datum' }],
        hkpManagerViewState: { hkpstatus: '0,5', range: 'olderThanOneYear', filterdate: 'datum' },
        order: i++,
    },
};
