"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExtendedApi = void 0;
function createExtendedApi(api) {
    return {
        getLeistungenExtended: (params) => api.metrics.post('/api/extended/leistungen', params),
        getTerminartenExtended: (params) => api.metrics.post('/api/extended/terminarten', params),
        getTerminartenUmsaetzeExtended: (params) => api.metrics.post('/api/extended/terminartenumsaetze', params),
        getKZVAbrechnungExtended: (params) => api.metrics.post('/api/extended/kzvabrechnung', params),
        getBehandlerMitKontrolleExtended: () => api.metrics.get('/api/extended/behandlermitkontrolle'),
        getTerminkontrollenExtended: (params) => api.metrics.post('/api/extended/terminkontrolle', params),
        getPatientenvorlaufExtended: (params) => api.metrics.post('/api/extended/patientenvorlauf', params),
        getHKPReportExtended: (params) => api.metrics.post('/api/extended/hkpreport', params),
        getMwStReportExtended: (params) => api.metrics.post('/api/extended/mwstreport', params),
        getFaktorenReportExtended: (params) => api.metrics.post('/api/extended/faktorenreport', params),
        getPzrPatientsNoFollowups: (params) => api.metrics.post('/api/extended/pzrpatients-nofollowups', params),
    };
}
exports.createExtendedApi = createExtendedApi;
