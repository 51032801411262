"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBetaFlagActive = exports.isBetaFlagActive = void 0;
let betaFlagActive = false;
function isBetaFlagActive() {
    return betaFlagActive;
}
exports.isBetaFlagActive = isBetaFlagActive;
function setBetaFlagActive(active) {
    betaFlagActive = active;
}
exports.setBetaFlagActive = setBetaFlagActive;
