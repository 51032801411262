"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStaticDataApi = void 0;
function createStaticDataApi(api) {
    return {
        getBehandler: (all) => api.metrics.get(`/api/staticdata/behandler`, { query: { all } }),
        getBemaGoz: () => api.metrics.get(`/api/staticdata/bemagoz`, {}),
        getTerminarten: () => api.metrics.get(`/api/staticdata/terminarten`, {}),
        getTermingruppen: () => api.metrics.get(`/api/staticdata/termingruppen`, {}),
        getZimmer: () => api.metrics.get(`/api/staticdata/zimmer`, {}),
        getTeams: (options) => api.metrics.get(`/api/staticdata/teams`, {
            query: options,
        }),
        getStichworte: () => api.metrics.get(`/api/staticdata/stichworte`, {}),
        getTopStichworte: (limit) => api.metrics.get(`/api/staticdata/stichworte/top`, { query: { limit } }),
        getParams: () => api.metrics.get(`/api/staticdata/params`, {}),
        getStempel: (clientIdOverride) => api.metrics.get(`/api/staticdata/stempel`, { clientIdHeader: clientIdOverride }),
    };
}
exports.createStaticDataApi = createStaticDataApi;
