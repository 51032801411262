"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUmsatzApi = void 0;
function createUmsatzApi(api) {
    return {
        chartMonth: (params) => api.metrics.get(`/api/umsatz/chart/month`, { query: params }),
        chartQuarter: (params) => api.metrics.get(`/api/umsatz/chart/quarter`, { query: params }),
        chartYear: (params) => api.metrics.get(`/api/umsatz/chart/year`, { query: params }),
        umsatzPlain: (params) => api.metrics.get(`/api/umsatz/plain`, { query: params }),
    };
}
exports.createUmsatzApi = createUmsatzApi;
