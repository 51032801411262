"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAGBApi = void 0;
function createAGBApi(api) {
    return {
        getAGBStatus: () => api.metrics.get(`/api/agb`),
        agreeAGB: (user) => api.metrics.post(`/api/agb`, { user }),
    };
}
exports.createAGBApi = createAGBApi;
