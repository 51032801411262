"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4cApi = void 0;
const patientApi_1 = require("./r4c/patientApi");
const adminApi_1 = require("./r4c/adminApi");
const anamneseApi_1 = require("./r4c/anamneseApi");
const documentsApi_1 = require("./r4c/documentsApi");
const factoringApi_1 = require("./r4c/factoringApi");
const r4cAppsApi_1 = require("./r4c/r4cAppsApi");
const r4cDebugApi_1 = require("./r4c/r4cDebugApi");
const papoApi_1 = require("./r4c/papoApi");
const tabletApi_1 = require("./r4c/tabletApi");
const workflowApi_1 = require("./r4c/workflowApi");
function createR4cApi(roseApi) {
    return {
        documents: (0, documentsApi_1.createDocumentsApi)(roseApi),
        r4cApps: (0, r4cAppsApi_1.createR4cAppsApi)(roseApi),
        admin: (0, adminApi_1.createAdminApi)(roseApi),
        factoring: (0, factoringApi_1.createFactoringApi)(roseApi),
        tablet: (0, tabletApi_1.createR4TabletApi)(roseApi),
        anamnese: (0, anamneseApi_1.createAnamnesetApi)(roseApi),
        debug: (0, r4cDebugApi_1.createR4cDebugApi)(roseApi),
        papo: (0, papoApi_1.createPapoApi)(roseApi),
        workflows: (0, workflowApi_1.createR4WorkflowsApi)(roseApi),
        patient: (0, patientApi_1.createPatientApi)(roseApi),
    };
}
exports.createR4cApi = createR4cApi;
