"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.highchartsOptions = void 0;
exports.highchartsOptions = {
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        loading: 'Daten werden geladen...',
        months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        shortWeekdays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        rangeSelectorFrom: 'Von',
        rangeSelectorTo: 'Bis',
        rangeSelectorZoom: 'Zeitraum',
    },
    credits: {
        enabled: false,
    },
};
