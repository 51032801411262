"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHkpLabelApi = void 0;
function createHkpLabelApi(api) {
    return {
        saveLabelConfig: (labelConfig) => api.metrics.post(`/api/hkplabels/savelabelconfig`, { labelConfig }),
        saveHkpLabels: (hkpid, labelConfig) => api.metrics.post(`/api/hkplabels/saveHkplabels`, { hkpid, selectedLabels: labelConfig }),
        getHkpLabelsForClient: () => api.metrics.get(`/api/hkplabels/allClientLabels`),
    };
}
exports.createHkpLabelApi = createHkpLabelApi;
