import { render, staticRenderFns } from "./EditR4cApps.vue?vue&type=template&id=ff5b57b8&lang=pug&"
import script from "./EditR4cApps.vue?vue&type=script&lang=ts&"
export * from "./EditR4cApps.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports