"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createShopApi = void 0;
function createShopApi(api) {
    return {
        shopItemsTouchPens: () => api.metrics.get(`/api/shopitems/touchpens`),
        orderTouchPens: (cart) => api.metrics.post(`/api/order/touchpens`, { cart }),
    };
}
exports.createShopApi = createShopApi;
