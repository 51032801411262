"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigLeistungen = void 0;
const types_1 = require("../../../types");
const util_1 = require("../utils/util");
function benchmarkConfigLeistungen() {
    const aaCfg = {};
    for (const aa of util_1.abrechnungsArten) {
        const aKey = `leistungenArt${aa.short}`;
        aaCfg[aKey] = {
            name: aa.name,
            smartValue: 's / b.leistungenArtGesamt * 100',
            smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
            debug: [`leistungenArt${aa}Ids`],
        };
    }
    return {
        name: 'Leistungen',
        unit: types_1.BenchmarkConfigUnitType.EURO,
        help: 'Im ausgewählten Zeitraum erbrachte Leistungen.',
        smartValue: '100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        value: 'b.leistungenGesamt',
        isHeader: true,
        virtual: true,
        childs: {
            _goz: {
                name: 'GOZ',
                isHeader: true,
                value: 'b.leistungenGOZGesamt',
                unit: types_1.BenchmarkConfigUnitType.EURO,
                virtual: true,
                childs: {
                    leistungenGOZA: {
                        name: 'A. Allgemeine zahnärztliche Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **0010 bis 0110**`,
                    },
                    leistungenGOZB: {
                        name: 'B. Prophylaktische Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **1000 bis 1040**`,
                    },
                    leistungenGOZC: {
                        name: 'C. Konservierende Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **2000 bis 2440**`,
                    },
                    leistungenGOZD: {
                        name: 'D. Chirurgische Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **3000 bis 3999**`,
                    },
                    leistungenGOZE: {
                        name: 'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **4000 bis 4150**`,
                    },
                    leistungenGOZF: {
                        name: 'F. Prothetische Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **5000 bis 5340**`,
                    },
                    leistungenGOZG: {
                        name: 'G. Kieferorthopädische Leistungen ',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **6000 bis 6260**`,
                    },
                    leistungenGOZH: {
                        name: 'H. Eingliederung von Aufbißbehelfen und Schienen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **7000 bis 7100**`,
                    },
                    leistungenGOZJ: {
                        name: 'J. Funktionsanalytische und funktionstherapeutische Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **8000 bis 8100**`,
                    },
                    leistungenGOZK: {
                        name: 'K. Implantologische Leistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **9000 bis 9090**`,
                    },
                    leistungenGOZL: {
                        name: 'L. Zuschläge',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **0500 bis 0530**`,
                    },
                    leistungenGOZGAE: {
                        name: 'Ä. Leistungen nach GOÄ',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `**GOZ** Leistungen der Gebührenziffern **ä1 bis ä5300**`,
                    },
                    leistungenGOZV: {
                        name: 'V. Verlangensleistungen',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenGOZGU: {
                        name: 'Ohne Zurechnung',
                        smartValue: 's / b.leistungenGOZGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['leistungenGOZGUIds'],
                    },
                    leistungenGOZGesamt: {
                        name: 'Gesamt',
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `GOZ Gesamt: Summe aller GOZ Leistungen bei **PKV und GKV** Versicherten`,
                    },
                },
            },
            _bema: {
                name: 'BEMA',
                isHeader: true,
                value: 'b.leistungenBEMAGesamt',
                unit: types_1.BenchmarkConfigUnitType.EURO,
                virtual: true,
                childs: {
                    leistungenBEMA1: {
                        name: 'Teil 1 - Konservierende und chirurgische Leistungen und Röntgenleistungen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMA2: {
                        name: 'Teil 2 - Kieferbruch, Kiefergelenkserkrankungen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMA3: {
                        name: 'Teil 3 - Kieferorthopädische Behandlung',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMA4: {
                        name: 'Teil 4 - Systematische Behandlung von Parodontopathien',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMA5: {
                        name: 'Teil 5 - Versorgung mit Zahnersatz und Zahnkronen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    // leistungenBEMABAE: {
                    //   name: 'Ä. Leistungen nach GOÄ',
                    //   smartValue: 's / b.leistungenBEMAGesamt * 100',
                    //   smartUnit: BenchmarkConfigUnitType.PERCENT,
                    // },
                    leistungenBEMABP: {
                        name: 'Pauschalen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMABUKBG: {
                        name: 'Unfallkasse und Berufsgenossenschaft',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                    leistungenBEMABU: {
                        name: 'Ohne Zurechnung',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        help: `Leistungen, die keinem Teil 1-5 der BEMA oder GOÄ zugerechnet werden können, werden als "ohne Zurechnung" subsummiert.`,
                        debug: ['leistungenBEMABUIds'],
                    },
                    leistungenBEMAGesamt: {
                        name: 'Gesamt',
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                },
            },
            _loz: {
                name: 'Ohne Zurechnung',
                isHeader: true,
                value: 'b.leistungenOhneZurechnung',
                unit: types_1.BenchmarkConfigUnitType.EURO,
                virtual: true,
                childs: {
                    leistungenOhneZurechnung: {
                        name: 'Gesamt',
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                    },
                },
            },
            leistungenGesamt: {
                name: 'Gesamt (BEMA + GOZ + Ohne Zurechnung)',
                smartValue: '100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
            },
        },
    };
}
exports.benchmarkConfigLeistungen = benchmarkConfigLeistungen;
