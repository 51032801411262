"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasAlarm = exports.skipHideOptionList = exports.getHeuteConditionsFromFeldKey = exports.getHelpTextFromFeldKey = exports.getHeuteFieldSettings = exports.getAlarmSettings = exports.betterAlarmFieldNames = exports.getOeffnungszeiten = void 0;
const lodash_1 = require("lodash");
const uuid = __importStar(require("uuid"));
const utils_1 = require("./utils");
function getOeffnungszeiten(oeffnungszeiten) {
    let oeffnungszeitenArrayTmp = [];
    const wtMap = (0, lodash_1.keyBy)(utils_1.wochentage, wt => wt.id);
    for (let tag in oeffnungszeiten) {
        if (Object.prototype.hasOwnProperty.call(oeffnungszeiten, tag)) {
            let tagName = wtMap[tag] ? wtMap[tag].name : tag;
            let tagNr = wtMap[tag] ? wtMap[tag].nr : 0;
            let oz = (0, lodash_1.map)(oeffnungszeiten[tag], onDayOz => (Object.assign(Object.assign({}, onDayOz), { id: uuid.v4(), tagNr,
                tagName, tag: tag, stunden: (0, utils_1.getStunden)(onDayOz) })));
            oeffnungszeitenArrayTmp = [...oeffnungszeitenArrayTmp, ...oz];
        }
    }
    return (0, lodash_1.sortBy)(oeffnungszeitenArrayTmp, ['tagNr', 'von']);
}
exports.getOeffnungszeiten = getOeffnungszeiten;
exports.betterAlarmFieldNames = {
    letzteAnamnese: 'Anamnese',
    naechstePZR: 'Nächste PZR',
    telefon: 'Telefon/Mobil',
    hkp: 'HKP',
    leistunge: 'Erbrachte Leistungen Heute',
    roentgen: 'Röntgen',
    paRoentgen: 'Röntgen bei PA-Therapie',
    naechsterpzrrecall: 'Nächster Recall',
    letzteNullEins: 'Letzte 01',
    psiBefund: 'PSI-Befund',
    folgeTermine: 'Folgetermine',
};
function getAlarmSettings(alarme) {
    let cfg = [
        {
            name: 'Patient',
            fields: ['alter', 'email', 'geschlecht', 'karte', 'letzteAnamnese', 'telefon', 'versicherung'],
        },
        {
            name: 'Behandlung und Termine',
            fields: [
                'assistenz',
                'behandlerLeistungen',
                'heuteStatus',
                'hkp',
                'letzteNullEins',
                'letztePA',
                'letztePSI',
                'letztePZR',
                'naechstePZR',
                'folgeTermine',
                'offeneTermine',
                'roentgen',
                'paRoentgen',
                'psiBefund',
                'naechsterpzrrecall',
            ],
        },
        {
            name: 'Abrechnung und Leistungserfassung',
            fields: [
                'kontrolliert',
                'leistungen',
                'nichtAbgerechneteLeistungen',
                'rechenzentrum',
                'offeneRechnungen',
                'vorschlaege',
            ],
        },
    ];
    let alarmeSettingsNeu = [];
    for (const group of cfg) {
        alarmeSettingsNeu.push({
            name: group.name,
            alarme: (0, lodash_1.sortBy)(group.fields.map(f => (Object.assign({ feldKey: f, feldName: exports.betterAlarmFieldNames[f] || (0, lodash_1.startCase)(f) }, alarme[f]))), v => v.feldKey),
        });
    }
    return alarmeSettingsNeu;
}
exports.getAlarmSettings = getAlarmSettings;
function getHeuteFieldSettings(settings) {
    return [
        ...settings,
        {
            name: 'Felder ohne Alarme',
            noAlarms: true,
            alarme: [
                { feldName: 'Beginn', feldKey: 'beginn' },
                { feldName: 'Score', feldKey: 'score' },
                { feldName: 'Stammbehandler', feldKey: 'stammbehandler' },
                { feldName: 'Prophylaxe', feldKey: 'prophylaxe' },
                { feldName: 'Terminspalte', feldKey: 'terminspalte' },
                { feldName: 'Termintreue', feldKey: 'termintreue' },
                { feldName: 'Terminezeiten', feldKey: 'terminzeiten' },
                { feldName: 'Bewertung', feldKey: 'bewertung' },
                { feldName: 'Zusatzversicherung', feldKey: 'zusatzversicherung' },
                { feldName: 'PopUps', feldKey: 'popups' },
            ],
        },
    ];
}
exports.getHeuteFieldSettings = getHeuteFieldSettings;
function getHelpTextFromFeldKey(key) {
    switch (key) {
        case 'letzteNullEins':
            return `
Die Abrechnungsfähigkeit der **Eingehenden Untersuchung** (01 / 0010) unterscheidet sich in BEMA und GOZ:

**01 BEMA (GKV)**: je Kalenderhalbjahr einmal frühestens nach Ablauf von 4 Monaten

**0010 GOZ (PKV)**: je nach Notwendigkeit ohne Einhaltung irgendwelcher Fristen

Der Hinweis in **rose** richtet sich nach den Regeln der Gebührenordnung entsprechend nach dem Versicherungsstatus des Patienten:

Für GKV Patienten erscheint der Hinweis einmal je Kalenderhalbjahr jedoch frühestens nach 4 Monaten.

Für PKV Patienten erscheint der Hinweis einmal je Quartal jedoch frühestens nach 30 Tagen (**0010 je Quartal**). Alternativ kann für den Hinweis bei PKV Patienten die Regelung der GKV angewendet werden (**0010 wie 01**).
`;
        case 'alter':
            return 'Das Patienten Alter muss eingegeben werden.';
        case 'assistenz':
            return 'Im Karteieintrag des freien Dokumentationsblocks muss mindestens eine Assistenz eingetragen sein.';
        case 'behandlerLeistungen':
            return 'Es wird überprüft, ob zum Terminbehandler passende Leistungen erbracht wurden. Dieser Fehler kann auftreten, wenn der Stammbehandler nicht dem Behandler im Termin entspricht und der Leistungserbringer nicht korrekt verändert worden ist.';
        case 'email':
            return 'Die E-Mail Adress des Patienten muss als Kontaktinformation eingetragen werden.';
        case 'folgeTermine':
            return 'Der Patient verfügt über Termine nach dem heutigen Termin.';
        case 'geschlecht':
            return 'Das Geschlecht des Patienten muss eingetragen werden.';
        case 'heuteStatus':
            return 'Der Terminstatus muss eingetragen sein: *kurzfristig abgesagt*, *nicht erschienen*, *ok*, *ok mit Kontrolle* Der Patient darf nicht mehr in der Praxis sein: *Warten*, *Zimmer*, *Behandlung*';
        case 'hkp':
            return `Der Patient verfügt über HKPs, die den Status nicht durchgeführt aufweisen.

Der Alarm kann ausgelöst werden, wenn ein HKP entweder den Status *eingabe* oder *abgelaufen* oder beide aufweist.
        `;
        case 'karte':
            return 'Die Versichertenkarte muss bei gesetzlich Versicherten im aktuellen Quartal mindestens einmal eingelesen worden sein.';
        case 'kontrolliert':
            return 'Die Leistungskontrolle muss entsprechend der Rolle Behandler oder Abrechnung durchgeführt worden sein.';
        case 'leistungen':
            return 'Zu jedem stattgefundenen Termin müssen Leistungen erbracht werden. Wenn heute keine Leistungen eingetragen wurden, wird ein Alarm angezeigt.';
        case 'letzteAnamnese':
            return 'Die Anamnese darf nicht älter als die konfigurierte Anzahl Monate sein.';
        case 'letztePA':
            return 'Es wird ein Hinweis ausgegeben, wenn ein PSI-Befund mit Therapie-Indikation (PSI 3-4) besteht und binnen der letzten 24 Monate und in der Zukunft keine PA-Behandlung durchgeführt worden oder geplant ist.';
        case 'letztePSI':
            return 'Der PSI wurde binnen der letzten 24 Monate abgerechnet.';
        case 'letztePZR':
            return 'Das Datum des letzten PZR-Termins liegt länger als 6 Monate zurück. [Parameter wandert als Freitexteingabe in die Zeile von weiter unten "Intervall für nächste PZR spätestens nach 6 Monaten"]';
        case 'naechstePZR':
            return 'Es besteht ein Termin für eine PZR-Behandlung.';
        case 'offeneRechnungen':
            return 'Es bestehen Rechnungen, deren Fälligkeitsdatum überschritten wurde (Mahnung).';
        case 'offeneTermine':
            return 'Offene Termine müssen vereinbart werden.';
        case 'paRoentgen':
            return `Der Patient muss über ein aktuelles Röntgenbild verfügen, wenn eine PA-Behandlung durchgeführt wird.
Der Patient verfügt über kein aktuelles Röntgenbild, das *neuer als 6 Monate* ist.`;
        case 'psiBefund':
            return 'Der Patient verfügt über einen eingetragenen PSI-Befund. Der PSI muss für alle Patienten ab *6 Jahren* oder ab *18 Jahren* erhoben werden.';
        case 'naechsterpzrrecall':
            return `Der Patient verfügt über einen gesetzten Recall. Es wird ein Alarm angezeigt wenn:

- **Kein Recall gesetzt** wurde.
- **mindestens ein Recall fällig ist**, weil das Recall-Datum überschritten wurde.
- **Kein Recall gesetzt wurde ODER mindestens ein Recall fällig ist**, weil das Recall-Datum überschritten wurde.

Um *keinen Recall-Alarm* anzuzeigen, muss die Bewertung für den Recall in der entsprechenden Rolle auf 0 gesetzt werden.`;
        case 'telefon':
            return 'Der Patient muss über Kontaktinformationen für *Telefon*, *Mobil* oder beides verfügen.';
        case 'versicherung':
            return 'Die Versicherungsart des Patienten muss gesetzt sein.';
        case 'nichtAbgerechneteLeistungen':
            return `
Nicht abgerechnete Leistungen werden in Kategorien von
- **€ < 1.000€**
- **€€ 1.000€ - 5.000€**
- **€€€ > 5.000€**
dargestellt. Die Fälligkeit der Abrechnung kann zwischen 6-24 Monaten gewählt werden.
Ein Alarm wird angezeigt, wenn die Leistungen zu Abrechnung überfällig sind.`;
        case 'roentgen':
            return 'Röntgen-Einträge müssen als Leistung und im Röntgenbuch eingetragen sein';
    }
    return '-';
}
exports.getHelpTextFromFeldKey = getHelpTextFromFeldKey;
function getHeuteConditionsFromFeldKey(key) {
    console.log('getHeuteConditionsFromFeldKey', key);
    switch (key) {
        case 'telefon':
            return [
                { key: 'TELEFON_ODER_MOBIL', value: 'Telefon oder Mobil' },
                { key: 'TELEFON_UND_MOBIL', value: 'Telefon und Mobil' },
                { key: 'NUR_MOBIL', value: 'Nur Mobil' },
            ];
        case 'letzteNullEins':
            return [
                { key: 'PKV_KURZ', value: '0010 je Quartal' },
                { key: 'PKV_WIE_GKV', value: '0010 wie 01' },
            ];
        case 'psiBefund':
            return [
                { key: '6', value: 'Älter als 6' },
                { key: '18', value: 'Älter als 18' },
            ];
        case 'hkp':
            return [
                { key: 'ABGELAUFEN', value: 'Bei Abgelaufen' },
                { key: 'EINGABE', value: 'Bei Eingabe' },
                { key: 'EINGABE_UND_ABGELAUFEN', value: 'Bei Eingabe und Abgelaufen' },
            ];
        case 'nichtAbgerechneteLeistungen':
            return [
                { key: 'UEBERFAELLIG_NACH_6_MONATEN', value: 'Überfällig nach 6 Monaten' },
                { key: 'UEBERFAELLIG_NACH_12_MONATEN', value: 'Überfällig nach 12 Monaten' },
                { key: 'UEBERFAELLIG_NACH_18_MONATEN', value: 'Überfällig nach 18 Monaten' },
                { key: 'UEBERFAELLIG_NACH_24_MONATEN', value: 'Überfällig nach 24 Monaten' },
            ];
        case 'letzteAnamnese':
            return [
                { key: 'ERNEUERN_NACH_6_MONATEN', value: 'Erneuern nach 6 Monaten' },
                { key: 'ERNEUERN_NACH_12_MONATEN', value: 'Erneuern nach 12 Monaten' },
                { key: 'ERNEUERN_NACH_18_MONATEN', value: 'Erneuern nach 18 Monaten' },
                { key: 'ERNEUERN_NACH_24_MONATEN', value: 'Erneuern nach 24 Monaten' },
            ];
        case 'rechenzentrum':
            return [
                { key: 'AVA_FEHLT_ALARM', value: 'Fehlende AVA ist ein Alarm' },
                { key: 'AVA_FEHLT_WARN', value: 'Fehlende AVA ist eine Warnung' },
            ];
        case 'naechsterpzrrecall':
            return [
                { key: 'RECALL_NICHT_GESETZT', value: 'Recall nicht gesetzt' },
                { key: 'RECALL_FAELLIG', value: 'Mind. ein Recall fällig' },
                { key: 'RECALL_NICHT_GESETZT_ODER_FAELLIG', value: 'Kein Recall oder mind. ein Recall fällig' },
            ];
        case 'naechstePZR':
            return [
                { key: 'NUR_WENN_PZR_IN_DER_VERGANGENHEIT', value: 'Es gab PZR-Termine' },
                { key: 'IMMER_WENN_KEIN_PZR', value: 'Kein PZR-Termin' },
            ];
    }
    return null;
}
exports.getHeuteConditionsFromFeldKey = getHeuteConditionsFromFeldKey;
exports.skipHideOptionList = ['alter', 'geschlecht', 'karte', 'versicherung'];
function hasAlarm(rolle, key) {
    let notInEmpfang = [
        'assistenz',
        'kontrolliert',
        'leistungen',
        'letztePSI',
        'paRoentgen',
        'psiBefund',
        'letztePA',
        'behandlerLeistungen',
    ];
    if (rolle === 'empfang') {
        return !notInEmpfang.includes(key);
    }
    return true;
}
exports.hasAlarm = hasAlarm;
