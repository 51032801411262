"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTMK = void 0;
function handleTMK(tmk, getResolvedInfo, result) {
    let tid = tmk && (tmk.extid || tmk.terminid);
    if (tid) {
        let tinfo = getResolvedInfo(tid);
        if (tinfo) {
            if (tmk.kommentar !== tinfo.kommentar) {
                tmk.kommentar = tinfo.kommentar;
                return tid;
            }
        }
        else {
            result.failedIds.push(tid);
        }
    }
    return undefined;
}
exports.handleTMK = handleTMK;
