import Vue from 'vue';
import Router from 'vue-router';
import OnboardingMetrics from './views/OnboardingMetrics.vue';
import OnboardingR4C from './views/OnboardingR4C.vue';
import EditR4cAppsVue from './views/EditR4cApps.vue';
import EditRechenzentren from './views/EditRechenzentren.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'onboard-metrics',
      component: OnboardingMetrics,
    },
    {
      path: '/r4c',
      name: 'onboard-r4c',
      component: OnboardingR4C,
    },
    {
      path: '/edit-r4c-apps',
      name: 'edit-r4c-apps',
      component: EditR4cAppsVue,
    },
    {
      path: '/edit-r4c-rz',
      name: 'edit-r4c-rz',
      component: EditRechenzentren,
    },
  ],
});
