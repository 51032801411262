"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigTermine = void 0;
const types_1 = require("../../../types");
function benchmarkConfigTermine() {
    return {
        name: 'Termine',
        isHeader: true,
        unit: types_1.BenchmarkConfigUnitType.TERMINE,
        value: 'b.termine',
        smartValue: '100',
        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
        virtual: true,
        childs: {
            termine: {
                name: 'Alle',
                smartValue: '100',
                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                childs: {
                    termineWahrgenommen: {
                        name: 'Wahrgenommen (show)',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineWahrgenommenIds'],
                    },
                    termineNichtErschienenUndAbgesagt: {
                        name: 'Nicht wahrgenommen (no show)',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineNichtErschienenUndAbgesagtIds'],
                        childs: {
                            termineAbgesagt: {
                                name: 'Abgesagt',
                                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                                smartValue: 's / b.termine * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineAbgesagtIds'],
                            },
                            termineNichtErschienen: {
                                name: 'Nicht erschienen',
                                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                                smartValue: 's / b.termine * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineNichtErschienenIds'],
                            },
                        },
                    },
                    _termineFristgerechtAbgesagt: {
                        name: 'Fristgerecht abgesagte Termine',
                        trendType: types_1.IBenchmarkConfigTrendType.nonebutabsolut,
                        value: 'b.termineFristgerechtAbgesagt + b.terminePraxisAbgesagt',
                        virtual: true,
                        collapsed: true,
                        childs: {
                            termineFristgerechtAbgesagt: {
                                name: 'Fristgerecht Abgesagt',
                                trendType: types_1.IBenchmarkConfigTrendType.nonebutabsolut,
                                debug: ['termineFristgerechtAbgesagtIds'],
                            },
                            terminePraxisAbgesagt: {
                                name: 'Praxisabgesagt',
                                trendType: types_1.IBenchmarkConfigTrendType.nonebutabsolut,
                                debug: ['terminePraxisAbgesagtIds'],
                            },
                        },
                    },
                    termineOhneTerminEntlassen: {
                        name: 'Ohne Termin entlassen',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineOhneTerminEntlassenIds'],
                        collapsed: true,
                        childs: {
                            termineOhneTerminEntlassenOhneUeberweiser: {
                                name: 'Ohne Überweiser Patienten',
                                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                                smartValue: 's / b.termine * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineOhneTerminEntlassenOhneUeberweiserIds'],
                            },
                        },
                    },
                },
            },
            _termineCheck: {
                name: 'Terminstatus und Leistungskontrolle',
                isHeader: true,
                value: 'b.termine',
                virtual: true,
                childs: {
                    termineStatusFehler: {
                        name: 'Terminstatus unvollständig',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        debug: ['termineStatusFehlerIds'],
                        help: 'Der Terminstatus wurde eingetragen aber nicht mit ok abgeschlossen. Der Patient befindet sich also z.B. noch im Wartezimmer oder im Behandlungszimmer.',
                    },
                    termineOhneStatus: {
                        name: 'Terminstatus nicht eingetragen',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        debug: ['termineOhneStatusIds'],
                        help: 'Der Terminstatus wurde nicht eingetragen. Wenn der Patient erschienen ist, muss dieser auf ok gesetzt werden. Wenn der Patient nicht erschienen ist, muss der Terminstatus auf abgesagt oder nicht erschienen gesetzt werden.',
                    },
                    termineOK: {
                        name: 'Terminstatus OK, nicht kontrolliert',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineOKIds'],
                        help: 'Der Terminstatus wurde auf **ok** gesetzt. Er wurde aber noch nicht durch den Behandler und die Abrechnung kontrolliert.',
                    },
                    termineKontrolliertBehandler: {
                        name: 'Kontrolliert nur Behandler',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineKontrolliertBehandlerIds'],
                        help: 'Der Terminstatus wurde auf **ok** gesetzt. Er wurde durch den Behandler aber nicht durch die Abrechnung kontrolliert.',
                    },
                    termineKontrolliertAbrechnung: {
                        name: 'Kontrolliert nur Abrechnung',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineKontrolliertAbrechnungIds'],
                        help: 'Der Terminstatus wurde auf **ok** (blau) gesetzt. Er wurde durch die Abrechnung aber nicht durch den Behandler kontrolliert.',
                    },
                    termineKontrolliertAlle: {
                        name: 'Kontrolliert Behandler und Abrechnung',
                        smartValue: 's / b.termine * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineKontrolliertAlleIds'],
                        help: 'Der Terminstatus wurde auf **ok** (grün) gesetzt. Er wurde durch die Abrechnung und durch den Behandler kontrolliert.',
                    },
                    _termineKontrolliertShowOnly: {
                        name: 'Nur Shows',
                        value: 'b.termineWahrgenommen',
                        virtual: true,
                        collapsed: true,
                        smartValue: 's / b.termineWahrgenommen * 100',
                        smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                        debug: ['termineKontrolliertAlleIds'],
                        help: 'Der Terminstatus wurde auf **ok** (grün) gesetzt. Er wurde durch die Abrechnung und durch den Behandler kontrolliert.',
                        childs: {
                            termineStatusFehlerShowOnly: {
                                name: 'Terminstatus unvollständig',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                                debug: ['termineStatusFehlerShowOnlyIds'],
                                help: 'Der Terminstatus wurde eingetragen aber nicht mit ok abgeschlossen. Der Patient befindet sich also z.B. noch im Wartezimmer oder im Behandlungszimmer.',
                            },
                            termineOhneStatusShowOnly: {
                                name: 'Terminstatus nicht eingetragen',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                                debug: ['termineOhneStatusShowOnlyIds'],
                                help: 'Der Terminstatus wurde nicht eingetragen. Wenn der Patient erschienen ist, muss dieser auf ok gesetzt werden. Wenn der Patient nicht erschienen ist, muss der Terminstatus auf abgesagt oder nicht erschienen gesetzt werden.',
                            },
                            termineOKShowOnly: {
                                name: 'Terminstatus OK, nicht kontrolliert',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineOKShowOnlyIds'],
                                help: 'Der Terminstatus wurde auf **ok** gesetzt. Er wurde aber noch nicht durch den Behandler und die Abrechnung kontrolliert.',
                            },
                            termineKontrolliertBehandlerShowOnly: {
                                name: 'Kontrolliert nur Behandler',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineKontrolliertBehandlerShowOnlyIds'],
                                help: 'Der Terminstatus wurde auf **ok** gesetzt. Er wurde durch den Behandler aber nicht durch die Abrechnung kontrolliert.',
                            },
                            termineKontrolliertAbrechnungShowOnly: {
                                name: 'Kontrolliert nur Abrechnung',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineKontrolliertAbrechnungShowOnlyIds'],
                                help: 'Der Terminstatus wurde auf **ok** (blau) gesetzt. Er wurde durch die Abrechnung aber nicht durch den Behandler kontrolliert.',
                            },
                            termineKontrolliertAlleShowOnly: {
                                name: 'Kontrolliert Behandler und Abrechnung',
                                smartValue: 's / b.termineWahrgenommen * 100',
                                smartUnit: types_1.BenchmarkConfigUnitType.PERCENT,
                                debug: ['termineKontrolliertAlleShowOnlyIds'],
                                help: 'Der Terminstatus wurde auf **ok** (grün) gesetzt. Er wurde durch die Abrechnung und durch den Behandler kontrolliert.',
                            },
                        },
                    },
                },
            },
        },
    };
}
exports.benchmarkConfigTermine = benchmarkConfigTermine;
