"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseI18nMessages = void 0;
exports.anamneseI18nMessages = {
    de: {
        // AnamnesePatientDetailsForm
        'Kein Titel': 'Kein Titel',
        Patientendaten: 'Patientendaten',
        optional: 'optional',
        Vorname: 'Vorname',
        Nachname: 'Nachname',
        Geburtstag: 'Geburtstag',
        Geschlecht: 'Geschlecht',
        Straße: 'Straße',
        Postleitzahl: 'Postleitzahl',
        Stadt: 'Stadt',
        Land: 'Land',
        Geburtsort: 'Geburtsort',
        Handy: 'Handy',
        Mobilfunk: 'Mobilfunk',
        Festnetz: 'Festnetz',
        'Straße, Hausnummer': 'Straße, Hausnummer',
        Telefon: 'Telefon',
        Email: 'Email',
        männlich: 'männlich',
        weiblich: 'weiblich',
        divers: 'divers',
        unbekannt: 'unbekannt',
        'Hier ausfüllen': 'Hier ausfüllen',
        'Am Handy ausfüllen': 'Am Handy ausfüllen',
        Patient: 'Patient',
        'Ich möchte keine Email angeben': 'Ich möchte keine Email angeben',
        'Wollen Sie wirklich keine Email angeben?': 'Wollen Sie wirklich keine Email angeben?',
        // AnamnesePatientFlow
        Fragebogen: 'Fragebogen',
        für: 'für',
        'Tippen um zu starten': 'Tippen um zu starten',
        'Fragebogen starten': 'Fragebogen starten',
        'Hiermit bestätige ich, dass ich {name} bin': 'Hiermit bestätige ich, dass ich {name} bin',
        'Sie haben Ihren Namen geändert, sind Sie sicher dass sich Ihr Name von {nameOld} zu {nameNew} geändert hat?': 'Sie haben Ihren Namen geändert, sind Sie sicher dass sich Ihr Name von {nameOld} zu {nameNew} geändert hat?',
        'Sie haben Ihr Geburtsdatum geändert, sind Sie sicher dass sich Ihr Geburtsdatum von {dobOld} zu {dobNew} geändert hat?': 'Sie haben Ihr Geburtsdatum geändert, sind Sie sicher dass sich Ihr Geburtsdatum von {dobOld} zu {dobNew} geändert hat?',
        'Geburtsdatum ist weniger als 6 Monate her, sind Sie sicher?': 'Geburtsdatum ist weniger als 6 Monate her, sind Sie sicher?',
        Fragen: 'Fragen',
        Unterschrift: 'Unterschrift',
        // AnamneseQuestion
        Optional: 'Optional',
        Ja: 'Ja',
        Nein: 'Nein',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Kommentar darf nicht länger als 250 Zeichen sein.',
        Kommentar: 'Kommentar',
        Auswahl: 'Auswahl',
        Zahn: 'Zahn',
        'Leistung hinzufügen': 'Leistung hinzufügen',
        Summe: 'Summe',
        'Unterschrift unten Auswählen': 'Unterschrift unten Auswählen',
        'Unterschrift Auswählen': 'Unterschrift Auswählen',
        // PageFlowLayout
        Zurück: 'Zurück',
        Weiter: 'Weiter',
        Absenden: 'Absenden',
        // Countries
        Deutschland: 'Deutschland',
        Großbritannien: 'Großbritannien',
        Frankreich: 'Frankreich',
        Schweiz: 'Schweiz',
        Italien: 'Italien',
        Griechenland: 'Griechenland',
        Österreich: 'Österreich',
        Belgien: 'Belgien',
        Niederlande: 'Niederlande',
        Canada: 'Canada',
        Brasilien: 'Brasilien',
        Amerika: 'Amerika',
        Liechtenstein: 'Liechtenstein',
        Polen: 'Polen',
        Dänemark: 'Dänemark',
        Schweden: 'Schweden',
        Norwegen: 'Norwegen',
        Türkei: 'Türkei',
        'United Kingdom': 'United Kingdom',
        Lettland: 'Lettland',
        Litauen: 'Litauen',
        Tschechisch: 'Tschechisch',
        Französisch: 'Französisch',
        Rumänien: 'Rumänien',
        Mauritius: 'Mauritius',
        Finnland: 'Finnland',
        Spanien: 'Spanien',
        // final doc
        Adresse: 'Adresse',
        Kontakt: 'Kontakt',
        'Hier fügen wir Ihre Unterschrift ein': 'Hier fügen wir Ihre Unterschrift ein',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.',
    },
    en: {
        // AnamnesePatientDetailsForm
        'Kein Titel': 'No Title',
        Patientendaten: 'Patient Data',
        optional: 'Optional',
        Vorname: 'First Name',
        Nachname: 'Last Name',
        Geburtstag: 'Date of Birth',
        Geschlecht: 'Gender',
        Straße: 'Street',
        Postleitzahl: 'Postal Code',
        Stadt: 'City',
        Land: 'Country',
        Geburtsort: 'Place of Birth',
        Handy: 'Mobile',
        Mobilfunk: 'Mobile',
        Festnetz: 'Phone',
        'Straße, Hausnummer': 'Street, House Number',
        Telefon: 'Phone',
        Email: 'Email',
        männlich: 'male',
        weiblich: 'female',
        divers: 'divers',
        unbekannt: 'unknown',
        'Hier ausfüllen': 'Fill in here',
        'Am Handy ausfüllen': 'Fill in on mobile',
        Patient: 'Patient',
        'Ich möchte keine Email angeben': 'I dont want to specify an email',
        'Wollen Sie wirklich keine Email angeben?': "Are you sure you don't want to provide an email?",
        // AnamnesePatientFlow
        Fragebogen: 'Questionnaire',
        für: 'for',
        'Tippen um zu starten': 'Tap to Start',
        'Fragebogen starten': 'Start Questionnaire',
        'Hiermit bestätige ich, dass ich {name} bin': 'I hereby confirm that I am {name}',
        'Sie haben Ihren Namen geändert, sind Sie sicher dass sich Ihr Name von {nameOld} zu {nameNew} geändert hat?': 'You have changed your name, are you sure that your name has changed from {nameOld} to {nameNew}?',
        'Sie haben Ihr Geburtsdatum geändert, sind Sie sicher dass sich Ihr Geburtsdatum von {dobOld} zu {dobNew} geändert hat?': 'You have changed your date of birth, are you sure that your date of birth has changed from {dobOld} to {dobNew}?',
        'Geburtsdatum ist weniger als 6 Monate her, sind Sie sicher?': 'Date of birth is less than 6 months ago, are you sure?',
        Fragen: 'Questions',
        Unterschrift: 'Signature',
        // AnamneseQuestion
        Optional: 'Optional',
        Ja: 'Yes',
        Nein: 'No',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Comment cannot be longer than 250 characters.',
        Kommentar: 'Comment',
        Auswahl: 'Selection',
        Zahn: 'Tooth',
        'Leistung hinzufügen': 'Add Service',
        Summe: 'Total',
        'Unterschrift unten Auswählen': 'Select Signature Below',
        'Unterschrift Auswählen': 'Select Signature',
        // PageFlowLayout
        Zurück: 'Back',
        Weiter: 'Next',
        Absenden: 'Submit',
        // Countries
        Deutschland: 'Germany',
        Großbritannien: 'Great Britain',
        Frankreich: 'France',
        Schweiz: 'Switzerland',
        Italien: 'Italy',
        Griechenland: 'Greece',
        Österreich: 'Austria',
        Belgien: 'Belgium',
        Niederlande: 'Netherlands',
        Canada: 'Canada',
        Brasilien: 'Brazil',
        Amerika: 'America',
        Liechtenstein: 'Liechtenstein',
        Polen: 'Poland',
        Dänemark: 'Denmark',
        Schweden: 'Sweden',
        Norwegen: 'Norway',
        Türkei: 'Turkey',
        'United Kingdom': 'United Kingdom',
        Lettland: 'Latvia',
        Litauen: 'Lithuania',
        Tschechisch: 'Czech Republic',
        Französisch: 'French',
        Rumänien: 'Romania',
        Mauritius: 'Mauritius',
        Finnland: 'Finland',
        Spanien: 'Spain',
        // final doc
        Adresse: 'Address',
        Kontakt: 'Contact',
        'Hier fügen wir Ihre Unterschrift ein': 'Here we insert your signature',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Questionnaire successfully sent! You can now close this tab.',
    },
    tr: {
        'Kein Titel': 'Başlık Yok',
        Patientendaten: 'Hasta Verileri',
        optional: 'Opsiyonel',
        Vorname: 'İlk İsim',
        Nachname: 'Soyadı',
        Geburtstag: 'Doğum Tarihi',
        Geschlecht: 'Cinsiyet',
        Straße: 'Sokak',
        Postleitzahl: 'Posta Kodu',
        Stadt: 'Şehir',
        Land: 'Ülke',
        Geburtsort: 'Doğum Yeri',
        Handy: 'Mobil',
        Telefon: 'Telefon',
        Email: 'E-posta',
        männlich: 'erkek',
        weiblich: 'kadın',
        divers: 'dalgıçlar',
        unbekannt: 'Bilinmiyor',
        Fragebogen: 'Anket',
        für: 'için',
        'Tippen um zu starten': 'Başlamak için dokunun',
        Fragen: 'Sorular',
        Unterschrift: 'İmza',
        Optional: 'Opsiyonel',
        Ja: 'Evet',
        Nein: 'Hayır',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Yorum 250 karakterden uzun olamaz.',
        Kommentar: 'Yorum',
        Auswahl: 'Seçim',
        Zahn: 'Diş',
        'Leistung hinzufügen': 'Hizmet Ekleme',
        Summe: 'Toplam',
        'Unterschrift unten Auswählen': 'Aşağıdaki İmzayı Seçin',
        'Unterschrift Auswählen': 'İmza Seçiniz',
        Zurück: 'Geri',
        Weiter: 'Sonraki',
        Absenden: 'Gönder',
        Deutschland: 'Almanya',
        Großbritannien: 'Büyük Britanya',
        Frankreich: 'Fransa',
        Schweiz: 'İsviçre',
        Italien: 'İtalya',
        Griechenland: 'Yunanistan',
        Österreich: 'Avusturya',
        Belgien: 'Belçika',
        Niederlande: 'Hollanda',
        Canada: 'Kanada',
        Brasilien: 'Brezilya',
        Amerika: 'Amerika',
        Liechtenstein: 'Lihtenştayn',
        Polen: 'Polonya',
        Dänemark: 'Danimarka',
        Schweden: 'İsveç',
        Norwegen: 'Norveç',
        Türkei: 'Türkiye',
        'United Kingdom': 'Birleşik Krallık',
        Lettland: 'Letonya',
        Litauen: 'Litvanya',
        Tschechisch: 'Çek Cumhuriyeti',
        Französisch: 'Fransızca',
        Rumänien: 'Romanya',
        Mauritius: 'Mauritius',
        Finnland: 'Finlandiya',
        Spanien: 'İspanya',
        Adresse: 'Adres',
        Kontakt: 'İletişim',
        'Hier fügen wir Ihre Unterschrift ein': 'Burada imzanızı ekliyoruz',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Anket başarıyla gönderildi! Şimdi bu sekmeyi kapatabilirsiniz.',
        Mobilfunk: 'Mobil radyo',
        Festnetz: 'Sabit Hat',
        'Straße, Hausnummer': 'Sokak, ev numarası',
        'Hier ausfüllen': 'Burayı doldurun',
        'Am Handy ausfüllen': 'Cep telefonunuzdan doldurun',
        Patient: 'Hasta',
        'Ich möchte keine Email angeben': 'Bir e-posta adresi girmek istemiyorum',
        'Wollen Sie wirklich keine Email angeben?': 'E-posta adresi vermek istemediğinize emin misiniz?',
    },
    ar: {
        'Kein Titel': 'لا عنوان',
        Patientendaten: 'بيانات المريض',
        optional: 'اختياري',
        Vorname: 'الاسم الأول',
        Nachname: 'اسم العائلة',
        Geburtstag: 'تاريخ الميلاد',
        Geschlecht: 'الجنس',
        Straße: 'الشارع',
        Postleitzahl: 'الرمز البريدي',
        Stadt: 'المدينة',
        Land: 'البلد',
        Geburtsort: 'مكان الميلاد',
        Handy: 'الهاتف المحمول',
        Telefon: 'رقم الهاتف',
        Email: 'البريد الإلكتروني',
        männlich: 'ذكر',
        weiblich: 'أنثى',
        divers: 'غواصون',
        unbekannt: 'غير معروف',
        Fragebogen: 'استبيان',
        für: 'ل',
        'Tippen um zu starten': 'اضغط للبدء',
        Fragen: 'الأسئلة',
        Unterschrift: 'التوقيع',
        Optional: 'اختياري',
        Ja: 'نعم',
        Nein: 'لا يوجد',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'لا يمكن أن يكون التعليق أطول من 250 حرفاً.',
        Kommentar: 'التعليق',
        Auswahl: 'اختيار',
        Zahn: 'سن',
        'Leistung hinzufügen': 'إضافة خدمة',
        Summe: 'المجموع',
        'Unterschrift unten Auswählen': 'حدد التوقيع أدناه',
        'Unterschrift Auswählen': 'اختر التوقيع',
        Zurück: 'رجوع',
        Weiter: 'التالي',
        Absenden: 'إرسال',
        Deutschland: 'ألمانيا',
        Großbritannien: 'بريطانيا العظمى',
        Frankreich: 'فرنسا',
        Schweiz: 'سويسرا',
        Italien: 'ايطاليا',
        Griechenland: 'اليونان',
        Österreich: 'النمسا',
        Belgien: 'بلجيكا',
        Niederlande: 'هولندا',
        Canada: 'كندا',
        Brasilien: 'البرازيل',
        Amerika: 'الولايات المتحدة الأمريكية',
        Liechtenstein: 'ليختنشتاين',
        Polen: 'بولندا',
        Dänemark: 'الدنمارك',
        Schweden: 'السويد',
        Norwegen: 'النرويج',
        Türkei: 'بولندا',
        'United Kingdom': 'المملكة المتحدة',
        Lettland: 'لاتفيا',
        Litauen: 'ليتوانيا',
        Tschechisch: 'جمهورية التشيك',
        Französisch: 'فرنسا',
        Rumänien: 'رومانيا',
        Mauritius: 'موريشيوس',
        Finnland: 'فنلندا',
        Spanien: 'اسبانيا',
        Adresse: 'العنوان',
        Kontakt: 'اتصل بنا',
        'Hier fügen wir Ihre Unterschrift ein': 'هنا ندرج توقيعك',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'تم إرسال الاستبيان بنجاح! يمكنك الآن إغلاق علامة التبويب هذه.',
        Mobilfunk: 'جهاز لاسلكي متنقل',
        Festnetz: 'الخط الأرضي',
        'Straße, Hausnummer': 'الشارع، رقم المنزل',
        'Hier ausfüllen': 'املأ هنا',
        'Am Handy ausfüllen': 'املأ البيانات على هاتفك المحمول',
        Patient: 'المريض',
        'Ich möchte keine Email angeben': 'لا أريد إدخال عنوان بريد إلكتروني',
        'Wollen Sie wirklich keine Email angeben?': 'هل أنت متأكد من أنك لا تريد تقديم عنوان بريد إلكتروني؟',
    },
    ru: {
        'Kein Titel': 'Нет заголовка',
        Patientendaten: 'Данные о пациенте',
        optional: 'Дополнительно',
        Vorname: 'Имя',
        Nachname: 'Фамилия',
        Geburtstag: 'Дата рождения',
        Geschlecht: 'Пол',
        Straße: 'Улица',
        Postleitzahl: 'Почтовый индекс',
        Stadt: 'Город',
        Land: 'Страна',
        Geburtsort: 'Место рождения',
        Handy: 'Мобильный',
        Telefon: 'Телефон',
        Email: 'Электронная почта',
        männlich: 'мужчина',
        weiblich: 'женщина',
        divers: 'дайверы',
        unbekannt: 'неизвестно',
        Fragebogen: 'Анкета',
        für: 'для',
        'Tippen um zu starten': 'Нажмите, чтобы начать',
        Fragen: 'Вопросы',
        Unterschrift: 'Подпись',
        Optional: 'Дополнительно',
        Ja: 'Да',
        Nein: 'Нет',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Комментарий не может быть длиннее 250 символов.',
        Kommentar: 'Комментарий',
        Auswahl: 'Выбор',
        Zahn: 'Зуб',
        'Leistung hinzufügen': 'Добавить услугу',
        Summe: 'Всего',
        'Unterschrift unten Auswählen': 'Выберите подпись ниже',
        'Unterschrift Auswählen': 'Выберите подпись',
        Zurück: 'Назад',
        Weiter: 'Следующий',
        Absenden: 'Отправить',
        Deutschland: 'Германия',
        Großbritannien: 'Великобритания',
        Frankreich: 'Франция',
        Schweiz: 'Швейцария',
        Italien: 'Италия',
        Griechenland: 'Греция',
        Österreich: 'Австрия',
        Belgien: 'Бельгия',
        Niederlande: 'Нидерланды',
        Canada: 'Канада',
        Brasilien: 'Бразилия',
        Amerika: 'Америка',
        Liechtenstein: 'Лихтенштейн',
        Polen: 'Польша',
        Dänemark: 'Дания',
        Schweden: 'Швеция',
        Norwegen: 'Норвегия',
        Türkei: 'Турция',
        'United Kingdom': 'Великобритания',
        Lettland: 'Латвия',
        Litauen: 'Литва',
        Tschechisch: 'Чешская Республика',
        Französisch: 'Франция',
        Rumänien: 'Румыния',
        Mauritius: 'Маврикий',
        Finnland: 'Финляндия',
        Spanien: 'Испания',
        Adresse: 'Адрес',
        Kontakt: 'Контакт',
        'Hier fügen wir Ihre Unterschrift ein': 'Здесь мы ставим вашу подпись',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Анкета отправлена успешно! Теперь вы можете закрыть эту вкладку.',
        Mobilfunk: 'Мобильная радиостанция',
        Festnetz: 'Наземная линия',
        'Straße, Hausnummer': 'Улица, номер дома',
        'Hier ausfüllen': 'Заполните здесь',
        'Am Handy ausfüllen': 'Заполните форму с помощью мобильного телефона',
        Patient: 'Пациент',
        'Ich möchte keine Email angeben': 'Я не хочу вводить адрес электронной почты',
        'Wollen Sie wirklich keine Email angeben?': 'Вы уверены, что не хотите указывать электронную почту?',
    },
    it: {
        'Kein Titel': 'Nessun titolo',
        Patientendaten: 'Dati del paziente',
        optional: 'Opzionale',
        Vorname: 'Nome',
        Nachname: 'Cognome',
        Geburtstag: 'Data di nascita',
        Geschlecht: 'Genere',
        Straße: 'Via',
        Postleitzahl: 'Codice postale',
        Stadt: 'Città',
        Land: 'Paese',
        Geburtsort: 'Luogo di nascita',
        Handy: 'Cellulare',
        Telefon: 'Telefono',
        Email: 'e-mail',
        männlich: 'uomo',
        weiblich: 'donna',
        divers: 'subacqueo',
        unbekannt: 'sconosciuto',
        Fragebogen: 'Questionario',
        für: 'per',
        'Tippen um zu starten': 'Toccare per iniziare',
        Fragen: 'Domande',
        Unterschrift: 'Firma',
        Optional: 'Facoltativo',
        Ja: 'Sì',
        Nein: 'No',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Il commento non può essere più lungo di 250 caratteri.',
        Kommentar: 'Commento',
        Auswahl: 'Selezione',
        Zahn: 'Dente',
        'Leistung hinzufügen': 'Aggiungi servizio',
        Summe: 'Totale',
        'Unterschrift unten Auswählen': 'Seleziona la firma qui sotto',
        'Unterschrift Auswählen': 'Seleziona la firma',
        Zurück: 'Indietro',
        Weiter: 'Avanti',
        Absenden: 'Invia',
        Deutschland: 'Germania',
        Großbritannien: 'Gran Bretagna',
        Frankreich: 'Francia',
        Schweiz: 'Svizzera',
        Italien: 'Italia',
        Griechenland: 'Grecia',
        Österreich: 'Austria',
        Belgien: 'Belgio',
        Niederlande: 'Paesi Bassi',
        Canada: 'Canada',
        Brasilien: 'Brasile',
        Amerika: 'America',
        Liechtenstein: 'Liechtenstein',
        Polen: 'Polonia',
        Dänemark: 'Danimarca',
        Schweden: 'Svezia',
        Norwegen: 'Norvegia',
        Türkei: 'Turchia',
        'United Kingdom': 'Regno Unito',
        Lettland: 'Lettonia',
        Litauen: 'Lituania',
        Tschechisch: 'Repubblica Ceca',
        Französisch: 'Francia',
        Rumänien: 'Romania',
        Mauritius: 'Mauritius',
        Finnland: 'Finlandia',
        Spanien: 'Spagna',
        Adresse: 'Indirizzo',
        Kontakt: 'Contatto',
        'Hier fügen wir Ihre Unterschrift ein': 'Qui inseriamo la vostra firma',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Questionario inviato con successo! Ora è possibile chiudere questa scheda.',
        Mobilfunk: 'Radio mobile',
        Festnetz: 'Linea fissa',
        'Straße, Hausnummer': 'Via, numero civico',
        'Hier ausfüllen': 'Compila qui',
        'Am Handy ausfüllen': 'Compila sul cellulare',
        Patient: 'Paziente',
        'Ich möchte keine Email angeben': 'Non voglio specificare un indirizzo email',
        'Wollen Sie wirklich keine Email angeben?': 'Sei sicuro di non voler fornire un indirizzo email?',
    },
    pl: {
        'Kein Titel': 'Brak tytułu',
        Patientendaten: 'Dane pacjenta',
        optional: 'Opcjonalnie',
        Vorname: 'Imię',
        Nachname: 'Nazwisko',
        Geburtstag: 'Data urodzenia',
        Geschlecht: 'Płeć',
        Straße: 'Ulica',
        Postleitzahl: 'Kod pocztowy',
        Stadt: 'Miasto',
        Land: 'Kraj',
        Geburtsort: 'Miejsce urodzenia',
        Handy: 'Telefon komórkowy',
        Telefon: 'Telefon',
        Email: 'E-mail',
        männlich: 'mężczyzna',
        weiblich: 'kobieta',
        divers: 'nurkowie',
        unbekannt: 'nieznany',
        Fragebogen: 'Kwestionariusz',
        für: 'dla',
        'Tippen um zu starten': 'Dotknij, aby rozpocząć',
        Fragen: 'Pytania',
        Unterschrift: 'Podpis',
        Optional: 'Opcjonalnie',
        Ja: 'Tak',
        Nein: 'Nie',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Komentarz nie może być dłuższy niż 250 znaków.',
        Kommentar: 'Komentarz',
        Auswahl: 'Wybór',
        Zahn: 'Ząb',
        'Leistung hinzufügen': 'Dodaj usługę',
        Summe: 'Razem',
        'Unterschrift unten Auswählen': 'Wybierz podpis poniżej',
        'Unterschrift Auswählen': 'Wybierz podpis',
        Zurück: 'Wstecz',
        Weiter: 'Dalej',
        Absenden: 'Wyślij',
        Deutschland: 'Niemcy',
        Großbritannien: 'Wielka Brytania',
        Frankreich: 'Francja',
        Schweiz: 'Szwajcaria',
        Italien: 'Włochy',
        Griechenland: 'Grecja',
        Österreich: 'Austria',
        Belgien: 'Belgia',
        Niederlande: 'Holandia',
        Canada: 'Kanada',
        Brasilien: 'Brazylia',
        Amerika: 'Ameryka',
        Liechtenstein: 'Liechtenstein',
        Polen: 'Polska',
        Dänemark: 'Dania',
        Schweden: 'Szwecja',
        Norwegen: 'Norwegia',
        Türkei: 'Turcja',
        'United Kingdom': 'Wielka Brytania',
        Lettland: 'Łotwa',
        Litauen: 'Litwa',
        Tschechisch: 'Czechy',
        Französisch: 'Francja',
        Rumänien: 'Rumunia',
        Mauritius: 'Mauritius',
        Finnland: 'Finlandia',
        Spanien: 'Hiszpania',
        Adresse: 'Adres',
        Kontakt: 'Kontakt',
        'Hier fügen wir Ihre Unterschrift ein': 'Tutaj wstawiamy Twój podpis',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Kwestionariusz został pomyślnie wysłany! Możesz teraz zamknąć tę kartę.',
        Mobilfunk: 'Telefon komórkowy',
        Festnetz: 'Telefon stacjonarny',
        'Straße, Hausnummer': 'Ulica, numer domu',
        'Hier ausfüllen': 'Wypełnij tutaj',
        'Am Handy ausfüllen': 'Wypełnij na telefonie komórkowym',
        Patient: 'Pacjent',
        'Ich möchte keine Email angeben': 'Nie chcę wprowadzać adresu e-mail',
        'Wollen Sie wirklich keine Email angeben?': 'Czy na pewno nie chcesz podawać adresu e-mail?',
    },
    fr: {
        'Kein Titel': 'Sans titre',
        Patientendaten: 'Données sur les patients',
        optional: 'Facultatif',
        Vorname: 'Prénom',
        Nachname: 'Nom de famille',
        Geburtstag: 'Date de naissance',
        Geschlecht: 'Sexe',
        Straße: 'Rue',
        Postleitzahl: 'Code postal',
        Stadt: 'Ville',
        Land: 'Pays',
        Geburtsort: 'Lieu de naissance',
        Handy: 'Téléphone portable',
        Telefon: 'téléphone',
        Email: 'Courriel',
        männlich: 'homme',
        weiblich: 'femme',
        divers: 'plongeurs',
        unbekannt: 'inconnu',
        Fragebogen: 'Questionnaire',
        für: 'pour',
        'Tippen um zu starten': 'Tapez pour commencer',
        Fragen: 'Questions',
        Unterschrift: 'Signature',
        Optional: 'Facultatif',
        Ja: 'Oui',
        Nein: 'Non',
        'Kommentar darf nicht länger als 250 Zeichen sein.': 'Le commentaire ne doit pas dépasser 250 caractères.',
        Kommentar: 'Commentaire',
        Auswahl: 'Sélection',
        Zahn: 'Dent',
        'Leistung hinzufügen': 'Ajouter un service',
        Summe: 'Total',
        'Unterschrift unten Auswählen': 'Sélectionner la signature ci-dessous',
        'Unterschrift Auswählen': 'Sélectionner la signature',
        Zurück: 'Retour',
        Weiter: 'Suivant',
        Absenden: 'Soumettre',
        Deutschland: 'Allemagne',
        Großbritannien: 'Grande-Bretagne',
        Frankreich: 'France',
        Schweiz: 'Suisse',
        Italien: 'Italie',
        Griechenland: 'Grèce',
        Österreich: 'Autriche',
        Belgien: 'Belgique',
        Niederlande: 'Pays-Bas',
        Canada: 'Canada',
        Brasilien: 'Brésil',
        Amerika: 'Amérique du Nord',
        Liechtenstein: 'Liechtenstein',
        Polen: 'Pologne',
        Dänemark: 'Danemark',
        Schweden: 'Suède',
        Norwegen: 'Norvège',
        Türkei: 'Turquie',
        'United Kingdom': 'Royaume-Uni',
        Lettland: 'Lettonie',
        Litauen: 'Lituanie',
        Tschechisch: 'République Tchèque',
        Französisch: 'France',
        Rumänien: 'Roumanie',
        Mauritius: 'Maurice',
        Finnland: 'Finlande',
        Spanien: 'Espagne',
        Adresse: 'Adresse de contact',
        Kontakt: 'Contact',
        'Hier fügen wir Ihre Unterschrift ein': 'Ici nous insérons votre signature',
        'Fragebogen erfolgreich gesendet! Sie können diesen Tab nun schließen.': 'Questionnaire envoyé avec succès! Vous pouvez maintenant fermer cet onglet',
        Mobilfunk: 'Téléphonie mobile',
        Festnetz: 'Ligne fixe',
        'Straße, Hausnummer': 'Rue, numéro',
        'Hier ausfüllen': 'Remplir ici',
        'Am Handy ausfüllen': 'Remplir sur mobile',
        Patient: 'Patient',
        'Ich möchte keine Email angeben': 'Je ne veux pas spécifier d’adresse e-mail',
        'Wollen Sie wirklich keine Email angeben?': 'Voulez-vous vraiment ne pas fournir d’adresse e-mail ?',
    },
};
