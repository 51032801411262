import { render, staticRenderFns } from "./OnboardingR4C.vue?vue&type=template&id=55e30e5d&scoped=true&lang=pug&"
import script from "./OnboardingR4C.vue?vue&type=script&lang=ts&"
export * from "./OnboardingR4C.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingR4C.vue?vue&type=style&index=0&id=55e30e5d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e30e5d",
  null
  
)

export default component.exports