"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCALicenceApi = void 0;
function createCALicenceApi(api) {
    return {
        getLicences: () => api.metrics.get(`/admin/calicence`),
        createLicence: (licence) => api.metrics.put(`/admin/calicence`, { licence }),
        updateLicence: (licence) => api.metrics.post(`/admin/calicence`, { licence }),
        deleteLicence: (licence) => api.metrics.delete(`/admin/calicence/${licence.serialnumber}/${licence.tenant}`),
    };
}
exports.createCALicenceApi = createCALicenceApi;
