"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConnectorCheckApi = exports.createBotApi = void 0;
function createBotApi(roseApi) {
    return {
        resolveNames: (params) => roseApi.collector.post(`/api/names`, params),
        resolveNotizen: (patids) => roseApi.collector.post(`/api/notizen`, { ids: patids }),
        resolveTerminInfos: (patids) => roseApi.collector.post(`/api/termininfos`, { ids: patids }),
        resolveHKPs: (patids) => roseApi.collector.post(`/api/hkps`, { ids: patids }),
        resolveDiagnosen: (date, patids) => roseApi.collector.post(`/api/diagnosen`, { date, patids }),
        bot: () => roseApi.collector.get(`/api/bot`),
        startImport: (full) => roseApi.collector.get(`/api/startimport/${full}`),
        getPatientenEmails: (params) => roseApi.collector.post(`/api/patientenemails`, params),
        searchPatients: (params) => roseApi.collector.post(`/api/patient/suche`, params),
        getPatientDetails: (patid, fakeData = false) => roseApi.collector.post(`/api/patient/details`, { patId: patid, fakeData }),
        getLiveTermineUpdate: (patid, lastchange) => roseApi.collector.get(`/api/terminupdate/${patid}`, { query: { lastchange } }),
        updateExternalIp: (cid) => roseApi.collector.get(`/api/updateexternalip/${cid}`),
        getPatientHashes: (patids) => roseApi.collector.post(`/api/hashpatients`, patids),
    };
}
exports.createBotApi = createBotApi;
function createConnectorCheckApi(roseApi) {
    return {
        newClientInfos: (ip) => roseApi.collector.get(`/newclientinfos?ip=${ip}`),
        createFirstUser: (cid, email, mode) => roseApi.collector.post(`/createfirstuser`, { cid, email, mode }),
    };
}
exports.createConnectorCheckApi = createConnectorCheckApi;
