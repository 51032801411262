"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHVMApi = void 0;
function createHVMApi(api) {
    return {
        getHVMNordrhein: (queryParams) => api.metrics.get(`/api/hvm/nordrhein`, {
            query: queryParams,
        }),
        getHVMNordrheinBehandler: (queryParams) => api.metrics.get(`/api/hvm/nordrhein/behandler`, {
            query: queryParams,
        }),
        getHVMNordrheinVerlauf: (queryParams) => api.metrics.get(`/api/hvm/nordrhein/verlauf`, {
            query: queryParams,
        }),
        getHVMNordrheinPatientDetails: (von, bis, altervon, alterbis, ignorekzv, part, partdetail, behandlerId) => api.metrics.post(`/api/hvm/nordrhein/patientdetails`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            partdetail,
            behandlerId,
        }),
        getHVMNordrhein2: (queryParams) => api.metrics.get(`/api/hvm/nordrhein2`, {
            query: queryParams,
        }),
        getHVMNordrheinDetails2: (von, bis, altervon, alterbis, ignorekzv, part, partdetail, behandlerId) => api.metrics.post(`/api/hvm/nordrhein2/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            partdetail,
            behandlerId,
        }),
        getHVMBayern: (queryParams) => api.metrics.get(`/api/hvm/bayern`, {
            query: queryParams,
        }),
        getHVMBayernBehandler: (queryParams) => api.metrics.get(`/api/hvm/bayern/behandler`, {
            query: queryParams,
        }),
        getHVMBayern2: (queryParams) => api.metrics.get(`/api/hvm/bayern2`, {
            query: queryParams,
        }),
        getHVMBayernPatientDetails: (von, bis, altervon, alterbis, ignorekzv, part, fallgruppe, partdetail, behandlerId) => api.metrics.post(`/api/hvm/bayern/patientdetails`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            fallgruppe,
            partdetail,
            behandlerId,
        }),
        getHVMBayern2PatientDetails: (von, bis, altervon, alterbis, ignorekzv, part, fallgruppe, partdetail, behandlerId) => api.metrics.post(`/api/hvm/bayern2/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            part,
            fallgruppe,
            partdetail,
            behandlerId,
        }),
        getHVMWestfalenLippe: (params) => api.metrics.post(`/api/hvm/westfalen-lippe`, params),
        getHVMWestfalenLippe2: (params) => api.metrics.post(`/api/hvm/westfalen-lippe2`, params),
        getHVMWestfalenLippeDetails2: (von, bis, altervon, alterbis, ignorekzv, teil, row, fallgruppe, behandlerId) => api.metrics.post(`/api/hvm/westfalen-lippe2/details`, {
            von,
            bis,
            altervon,
            alterbis,
            ignorekzv,
            teil,
            row,
            fallgruppe,
            behandlerId,
        }),
    };
}
exports.createHVMApi = createHVMApi;
