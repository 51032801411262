"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientApi = void 0;
const lodash_1 = require("lodash");
function createPatientApi(roseApi) {
    return {
        getPatientDetails: (params) => roseApi.metrics.get(`/api/patient/details/${params.patid}`, {
            query: (0, lodash_1.omit)(params, ['patid']),
        }),
        getPatienten: (patids) => roseApi.metrics.post('/api/patient/patienten', { patids }),
    };
}
exports.createPatientApi = createPatientApi;
