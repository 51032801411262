"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigRawOld = void 0;
const benchmark_config_hkp_1 = require("./benchmark.config.hkp");
const benchmark_config_leistungen_1 = require("./benchmark.config.leistungen");
const benchmark_config_papzr_1 = require("./benchmark.config.papzr");
const benchmark_config_patienten_1 = require("./benchmark.config.patienten");
const benchmark_config_termine_1 = require("./benchmark.config.termine");
const benchmark_config_tz_1 = require("./benchmark.config.tz");
const benchmark_config_umsatz_1 = require("./benchmark.config.umsatz");
const benchmark_config_rezepteHmvAuKt_1 = require("./benchmark.config.rezepteHmvAuKt");
const benchmark_config_behandlungen_1 = require("./benchmark.config.behandlungen");
const benchmark_config_tbv2_1 = require("./benchmark.config.tbv2");
const benchmark_config_stichworte_1 = require("./benchmark.config.stichworte");
function benchmarkConfigRawOld(settings, topStichworte) {
    return {
        name: 'KPI',
        isHeader: true,
        isRootNode: true,
        childs: {
            behandeltePatienten: (0, benchmark_config_patienten_1.benchmarkConfigPatienten)(),
            papzr: (0, benchmark_config_papzr_1.benchmarkConfigPaPzr)(),
            _termine: (0, benchmark_config_termine_1.benchmarkConfigTermine)(),
            umsatz: (0, benchmark_config_umsatz_1.benchmarkConfigUmsatz)(),
            leistungen: (0, benchmark_config_leistungen_1.benchmarkConfigLeistungen)(),
            behandlungen: (0, benchmark_config_behandlungen_1.benchmarkConfigBehandlungen)(settings.leistungen.implantat),
            hkp: (0, benchmark_config_hkp_1.benchmarkConfigHKP)(),
            terminbuchv2: (0, benchmark_config_tbv2_1.benchmarkConfigTBv2)(),
            terminzeiten: (0, benchmark_config_tz_1.benchmarkConfigTerminzeiten)(),
            rezepteHmvAuKt: (0, benchmark_config_rezepteHmvAuKt_1.benchmarkConfigRezepteHmvAuKt)(),
            stichworte: (0, benchmark_config_stichworte_1.benchmarkConfigStichworte)(topStichworte),
        },
    };
}
exports.benchmarkConfigRawOld = benchmarkConfigRawOld;
