"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultDaterangeNoMoment = exports.convertToBsCustomDates = exports.getDefaultRangesNoMoment = exports.defaultRanges = exports.allRangesKeyValue = exports.allRanges = exports.olderThanOneYear = exports.lastYear = exports.currentYear = exports.lastQuarter = exports.currentQuarter = exports.last12Months = exports.last3Months = exports.lastMonth = exports.currentMonth = exports.last365Days = exports.last30Days = exports.next7Days = exports.last7Days = exports.yesterday = exports.today = void 0;
const lodash_1 = require("lodash");
const base_1 = require("../../base");
exports.today = [(0, base_1.roseDayjs)().startOf('day').toDate(), (0, base_1.roseDayjs)().endOf('day').toDate()];
exports.yesterday = [
    (0, base_1.roseDayjs)().subtract(1, 'days').startOf('day').toDate(),
    (0, base_1.roseDayjs)().subtract(1, 'days').endOf('day').toDate(),
];
exports.last7Days = [
    (0, base_1.roseDayjs)().subtract(6, 'days').startOf('day').toDate(),
    (0, base_1.roseDayjs)().endOf('day').toDate(),
];
exports.next7Days = [
    (0, base_1.roseDayjs)().startOf('day').toDate(),
    (0, base_1.roseDayjs)().add(7, 'days').endOf('day').toDate(),
];
exports.last30Days = [
    (0, base_1.roseDayjs)().subtract(29, 'days').startOf('day').toDate(),
    (0, base_1.roseDayjs)().endOf('day').toDate(),
];
exports.last365Days = [
    (0, base_1.roseDayjs)().subtract(365, 'day').startOf('day').toDate(),
    (0, base_1.roseDayjs)().endOf('day').toDate(),
];
exports.currentMonth = [(0, base_1.roseDayjs)().startOf('month').toDate(), (0, base_1.roseDayjs)().endOf('month').toDate()];
exports.lastMonth = [
    (0, base_1.roseDayjs)().subtract(1, 'month').startOf('month').toDate(),
    (0, base_1.roseDayjs)().subtract(1, 'month').endOf('month').toDate(),
];
exports.last3Months = [(0, base_1.roseDayjs)().subtract(3, 'month').toDate(), (0, base_1.roseDayjs)().toDate()];
exports.last12Months = [(0, base_1.roseDayjs)().subtract(12, 'month').toDate(), (0, base_1.roseDayjs)().toDate()];
exports.currentQuarter = [
    (0, base_1.roseDayjs)().startOf('quarter').toDate(),
    (0, base_1.roseDayjs)().endOf('quarter').toDate(),
];
exports.lastQuarter = [
    (0, base_1.roseDayjs)().subtract(1, 'quarter').startOf('quarter').toDate(),
    (0, base_1.roseDayjs)().subtract(1, 'quarter').endOf('quarter').toDate(),
];
exports.currentYear = [(0, base_1.roseDayjs)().startOf('year').toDate(), (0, base_1.roseDayjs)().endOf('year').toDate()];
exports.lastYear = [
    (0, base_1.roseDayjs)().subtract(1, 'year').startOf('year').toDate(),
    (0, base_1.roseDayjs)().subtract(1, 'year').endOf('year').toDate(),
];
exports.olderThanOneYear = [
    (0, base_1.roseDayjs)().subtract(40, 'year').startOf('year').toDate(),
    (0, base_1.roseDayjs)().subtract(1, 'year').toDate(),
];
exports.allRanges = {
    heute: { name: 'Heute', range: exports.today },
    last7Days: { name: 'Letzten 7 Tage', range: exports.last7Days },
    next7Days: { name: 'Nächsten 7 Tage', range: exports.next7Days },
    currentMonth: { name: 'Dieser Monat', range: exports.currentMonth },
    lastMonth: { name: 'Letzter Monat', range: exports.lastMonth },
    last3Months: { name: 'Letzte 3 Monate', range: exports.last3Months },
    currentQuarter: { name: 'Dieses Quartal', range: exports.currentQuarter },
    lastQuarter: { name: 'Letztes Quartal', range: exports.lastQuarter },
    currentYear: { name: 'Dieses Jahr', range: exports.currentYear },
    lastYear: { name: 'Letztes Jahr', range: exports.lastYear },
    last12Months: { name: 'Letzte 12 Monate', range: exports.last12Months },
    olderThanOneYear: { name: 'Älter als ein Jahr', range: exports.olderThanOneYear },
};
exports.allRangesKeyValue = {
    Heute: exports.today,
    'Letzten 7 Tage': exports.last7Days,
    'Nächsten 7 Tage': exports.next7Days,
    'Dieser Monat': exports.currentMonth,
    'Letzter Monat': exports.lastMonth,
    'Letzte 3 Monate': exports.last3Months,
    'Dieses Quartal': exports.currentQuarter,
    'Letztes Quartal': exports.lastQuarter,
    'Dieses Jahr': exports.currentYear,
    'Letztes Jahr': exports.lastYear,
    'Letzte 12 Monate': exports.last12Months,
    'Älter als ein Jahr': exports.olderThanOneYear,
};
function defaultRanges() {
    let ranges = {};
    for (const rkey in exports.allRanges) {
        if (Object.prototype.hasOwnProperty.call(exports.allRanges, rkey)) {
            const r = exports.allRanges[rkey];
            ranges[r.name] = r.range;
        }
    }
    return ranges;
}
exports.defaultRanges = defaultRanges;
function getDefaultRangesNoMoment() {
    return convertToBsCustomDates(exports.allRanges);
}
exports.getDefaultRangesNoMoment = getDefaultRangesNoMoment;
function convertToBsCustomDates(ranges) {
    return (0, lodash_1.values)(ranges).map(range => ({
        label: range.name,
        value: [range.range[0].toDate(), range.range[1].toDate()],
    }));
}
exports.convertToBsCustomDates = convertToBsCustomDates;
function getDefaultDaterangeNoMoment() {
    return [exports.lastMonth[0], exports.lastMonth[1]];
}
exports.getDefaultDaterangeNoMoment = getDefaultDaterangeNoMoment;
