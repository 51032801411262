"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const onboardingStore_1 = require("@/state/onboardingStore");
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    components: {},
    computed: {},
    data: () => ({
        isR4cOnboarding: onboardingStore_1.onboardingStore.state.isR4cOnboarding,
    }),
    methods: {
        /**
         * This gets called every time when this view gets active
         */
        async refreshHook() {
            // clear unload handler if exist
            window.onbeforeunload = null;
        },
    },
});
