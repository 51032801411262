"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTeamMemberShips = void 0;
const lodash_1 = require("lodash");
/**
 * @returns the teams in which the behandler is a member
 */
function getTeamMemberShips(memberId, teams, typ) {
    return teams.filter(t => t.typ === typ).filter(t => (0, lodash_1.includes)(t.mitglieder, memberId));
}
exports.getTeamMemberShips = getTeamMemberShips;
