"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerNumeralLocale = exports.germanLocale = void 0;
function germanLocale() {
    let de = {
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: () => {
            return '.';
        },
        currency: {
            symbol: '€',
        },
    };
    return de;
}
exports.germanLocale = germanLocale;
function registerNumeralLocale(numeral) {
    if (!numeral.locales.de) {
        numeral.register('locale', 'de', germanLocale());
        numeral.locale('de');
    }
}
exports.registerNumeralLocale = registerNumeralLocale;
