"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadPrimus = void 0;
function loadScript(url, callback) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    // There are several events for cross browser compatibility.
    // @ts-ignore
    script.onreadystatechange = callback;
    script.onload = callback;
    // Fire the loading
    document.head.appendChild(script);
}
let primusPendingPromise;
async function loadPrimus(baseUrl) {
    // do this only once
    if (!primusPendingPromise) {
        primusPendingPromise = new Promise((resolve, reject) => {
            const primusScriptUrl = baseUrl + '/primus/primus.js';
            loadScript(primusScriptUrl, () => {
                // check if Primus is avaiable
                if (Primus && typeof Primus === 'function') {
                    resolve(Primus);
                }
                else {
                    reject();
                }
            });
        });
    }
    return primusPendingPromise;
}
exports.loadPrimus = loadPrimus;
