"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDocumentsApi = void 0;
function createDocumentsApi(api) {
    return {
        sendDocumentsPerMail: ({ r4chost, token, patMail, files, taskId, }) => api.r4c.post(r4chost, `/tablet/sendDocumentsPerMail`, {
            patMail,
            files,
            tabletToken: token,
            taskId,
        }),
        transferTaskToTabletForSignature: ({ r4chost, token, files, taskId, }) => api.r4c.post(r4chost, `/tablet/transferTaskToTabletForSignature`, {
            files,
            tabletToken: token,
            taskId,
        }),
        createSignatureRectPreviews: (r4chost, token, files) => api.r4c.post(r4chost, `/tablet/createSignatureRectPreviews`, {
            files,
            tabletToken: token,
        }),
        assignTabletDocumentTask: (r4chost, cid, tabletId, patient, fileInfo, start) => api.r4c.post(r4chost, `/tablet/task/document`, { cid, tabletId, patient, fileInfo, start }),
        createDocumentTaskFromDesktopR4c: (patient) => api.r4cInstance.post(`/tablet/task/document/fromDesktopR4C`, {
            patient,
        }),
        createFilePreview: (params) => api.r4cInstance.post(`/tablet/createFilePreview`, params),
        updateFileListing: ({ taskId }) => api.r4cInstance.post(`/tablet/task/fileListing/`, {
            taskId,
        }),
        acceptSignedDocument: (params) => api.r4cInstance.post(`/tablet/task/acceptSignedDocument`, params),
        acceptSignedDocumentShared: (params) => api.r4cInstance.post(`/documents/task/acceptSignedDocumentShared`, params),
        acceptSignedDocumentTakeover: (params) => api.r4cInstance.post(`/documents/task/acceptSignedDocumentTakeover`, params),
        // predefined signatures CRUD
        createPredefinedSignature: (r4chost, token, data) => api.r4c.post(r4chost, `/tablet/documents/predefined-signatures`, data),
        deletePredefinedSignature: (r4chost, token, data) => api.r4c.delete(r4chost, `/tablet/documents/predefined-signatures`, data),
        getPredefinedSignatures: () => api.r4cInstance.get(`/tablet/documents/predefined-signatures`),
        setPredefinedSignaturesOrder: (params) => api.r4cInstance.post(`/tablet/documents/setPredefinedSignaturesOrder`, params),
        setCharlySharedAblageFiles: (params) => api.r4cInstance.post(`/tablet/setCharlySharedAblageFiles`, params),
        getDocumentRenderingTestsResults: (r4chost) => api.r4c.get(r4chost, `/tablet/getDocumentRenderingTestsResults`),
        runDocumentsRenderingTests: (r4chost, testId) => api.r4c.post(r4chost, `/tablet/runDocumentsRenderingTests`, { testId }),
    };
}
exports.createDocumentsApi = createDocumentsApi;
