"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConf = exports.r4cDispatchUrl = exports.r4cUrl = exports.desktopR4cUrl = exports.onboardingUrl = exports.papoUrl = exports.uiUrl = exports.vuiUrl = exports.doctosyncUrl = exports.saverUrl = exports.collectorUrl = exports.metricsUrl = exports.apiUrl = exports.isLocalhost = exports.env = exports.getStripePublicKey = exports.getDoctosyncUrl = exports.getSaverUrl = exports.getCollectorUrl = exports.getPapoUrl = exports.getOnboardingUrl = exports.getVuiUrl = exports.getUiUrl = exports.getDesktopR4cUrl = exports.getR4cUrl = exports.getR4cDispatcherUrl = exports.getR4cApiUrl = exports.getMetricsUrl = exports.getEnvironment = exports.isDemo = exports.isReview = exports.isStaging = exports.isProd = exports.isDev = exports.Environment = void 0;
const host = location && location.host.split(':')[0];
const ROSE_STATUS_API_URL = 'https://status.rose.dental/api.json';
const LOCAL_STORAGE_ROSE_STATUS_API_KEY = 'ROSE_STATUS_API_STATUS';
let currentRoseApiStatus = null;
// safe guard for when process is not defined
if (typeof process === 'undefined') {
    // @ts-ignore
    window.process = { env: {} };
}
async function checkRoseApiStatusUpdate() {
    try {
        let status = await fetch(ROSE_STATUS_API_URL).then(a => a.json());
        // check status change
        let statusChanged = JSON.stringify(currentRoseApiStatus) !== JSON.stringify(status);
        if (statusChanged) {
            // api config changed persist and reload
            console.warn('Rose status has changed going to reload', status);
            localStorage.setItem(LOCAL_STORAGE_ROSE_STATUS_API_KEY, JSON.stringify(status));
            location.reload();
        }
    }
    catch (e) {
        console.warn('could not fetch rose status', e);
    }
}
function periodicallyCheckRoseStatus() {
    const interval = 1000 * 60 * 5; // 5 minutes
    void checkRoseApiStatusUpdate();
    setInterval(() => checkRoseApiStatusUpdate(), interval);
}
var Environment;
(function (Environment) {
    Environment["DEV"] = "dev";
    Environment["STAGING"] = "staging";
    Environment["REVIEW"] = "review";
    Environment["DEMO"] = "demo";
    Environment["PROD"] = "prod";
    Environment["DEVTUNNELS"] = "devtunnels";
})(Environment || (exports.Environment = Environment = {}));
exports.isDev = getEnvironment() === Environment.DEV;
exports.isProd = getEnvironment() === Environment.PROD;
exports.isStaging = getEnvironment() === Environment.STAGING;
exports.isReview = getEnvironment() === Environment.REVIEW;
exports.isDemo = getEnvironment() === Environment.DEMO;
if (exports.isProd) {
    currentRoseApiStatus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROSE_STATUS_API_KEY) || '{}');
    periodicallyCheckRoseStatus();
}
function getDevtunnelsAddressByPort(port) {
    return host.replace(/-\d+/, `-${port}`);
}
function getEnvironment() {
    if (host.includes('-staging') || host.includes('.staging')) {
        return Environment.STAGING;
    }
    if (host.includes('-review') || host.includes('.review')) {
        return Environment.REVIEW;
    }
    if (host.includes('devtunnels') || host.includes('devtunnels')) {
        return Environment.DEVTUNNELS;
    }
    if (host.includes('-demo') || host.includes('.demo')) {
        return Environment.DEMO;
    }
    if (host.includes('-prod') || host.includes('.prod')) {
        return Environment.PROD;
    }
    if (host.includes('localhost') || host.startsWith('192.168') || host === 'r4c') {
        return Environment.DEV;
    }
    return Environment.PROD;
}
exports.getEnvironment = getEnvironment;
function getMetricsUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_METRICS_URL) {
        return process.env.VUE_APP_METRICS_URL;
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:4000';
    }
    if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
        return currentRoseApiStatus.fallback.metrics;
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://api.rose.dental',
        [Environment.STAGING]: 'https://api.staging.rose.dental',
        [Environment.REVIEW]: 'https://api.review.rose.dental',
        [Environment.DEMO]: 'https://api.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:4000',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4000),
    };
    return envToUrlMap[exports.env];
}
exports.getMetricsUrl = getMetricsUrl;
function getR4cApiUrl(r4chost) {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_FACTORING_URL) {
        return process.env.VUE_APP_FACTORING_URL;
    }
    if (currentEnv === Environment.DEV || (currentEnv === Environment.DEMO && host.includes('localhost'))) {
        return `http://${r4chost}:5000`;
    }
    return `https://${r4chost}`;
}
exports.getR4cApiUrl = getR4cApiUrl;
function getR4cDispatcherUrl() {
    const envToUrlMap = {
        [Environment.PROD]: `https://r4cdispatcher.rose.dental`,
        [Environment.STAGING]: 'https://factoring.staging.rose.dental',
        [Environment.REVIEW]: 'https://factoring.review.rose.dental',
        [Environment.DEMO]: `https://factoring.staging.rose.dental`,
        [Environment.DEV]: 'http://localhost:5000',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(5000),
    };
    const currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_FACTORING_URL) {
        return process.env.VUE_APP_FACTORING_URL;
    }
    // special case localhost-prod-dev: prod server, dev ui
    if (currentEnv === Environment.PROD && host.includes('localhost-prod-dev')) {
        return envToUrlMap[currentEnv];
    }
    // special case localhost-demo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:5000';
    }
    // special case staging
    if (currentEnv === Environment.DEMO && host.includes('staging')) {
        return 'https://factoring.staging.rose.dental';
    }
    if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
        return currentRoseApiStatus.fallback.factoring;
    }
    return envToUrlMap[exports.env];
}
exports.getR4cDispatcherUrl = getR4cDispatcherUrl;
function getR4cUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_R4C_URL) {
        return process.env.VUE_APP_R4C_URL;
    }
    // special case localhost-prod-dev
    if (currentEnv === Environment.PROD && host.includes('localhost-prod-dev')) {
        return 'http://localhost-prod-dev:8081';
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost-demo:8081';
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://r4c.rose.dental',
        [Environment.STAGING]: 'https://r4c.staging.rose.dental',
        [Environment.REVIEW]: 'https://r4c.review.rose.dental',
        [Environment.DEMO]: 'https://r4c.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:8081',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8081),
    };
    return envToUrlMap[currentEnv];
}
exports.getR4cUrl = getR4cUrl;
function getDesktopR4cUrl() {
    let currentEnv = getEnvironment();
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost-demo:8084';
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://desktop-r4c.rose.dental',
        [Environment.STAGING]: 'https://desktop-r4c.staging.rose.dental',
        [Environment.REVIEW]: 'https://desktop-r4c.review.rose.dental',
        [Environment.DEMO]: 'https://desktop-r4c.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:8084',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8084),
    };
    return envToUrlMap[currentEnv];
}
exports.getDesktopR4cUrl = getDesktopR4cUrl;
function getUiUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_UI_URL) {
        return process.env.VUE_APP_UI_URL;
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:4200';
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://ui.rose.dental',
        [Environment.STAGING]: 'https://ui.staging.rose.dental',
        [Environment.REVIEW]: 'https://ui.review.rose.dental',
        [Environment.DEMO]: 'https://ui.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:4200',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4200),
    };
    return envToUrlMap[currentEnv];
}
exports.getUiUrl = getUiUrl;
function getVuiUrl() {
    let currentEnv = getEnvironment();
    // special case localhost-staging & localhost-prod
    if (currentEnv === Environment.STAGING && host.includes('localhost-staging')) {
        return 'http://localhost-staging:4201';
    }
    if (currentEnv === Environment.PROD && host.includes('localhost-prod')) {
        return 'http://localhost-prod:4201';
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://vui.rose.dental',
        [Environment.STAGING]: 'https://vui.staging.rose.dental',
        [Environment.REVIEW]: 'https://vui.review.rose.dental',
        [Environment.DEMO]: 'https://vui.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:4201',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4201),
    };
    return envToUrlMap[currentEnv];
}
exports.getVuiUrl = getVuiUrl;
function getOnboardingUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_ONBOARDING_URL) {
        return process.env.VUE_APP_ONBOARDING_URL;
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:8083';
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://onboarding.rose.dental',
        [Environment.STAGING]: 'https://onboarding.staging.rose.dental',
        [Environment.REVIEW]: 'https://onboarding.review.rose.dental',
        [Environment.DEMO]: 'https://onboarding.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:8083',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8083),
    };
    return envToUrlMap[currentEnv];
}
exports.getOnboardingUrl = getOnboardingUrl;
function getPapoUrl() {
    let currentEnv = getEnvironment();
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:8085';
    }
    if (process.env.VUE_APP_PAPO_URL) {
        return process.env.VUE_APP_PAPO_URL;
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://patient.rose.dental',
        [Environment.STAGING]: 'https://patient.staging.rose.dental',
        [Environment.REVIEW]: 'https://patient.review.rose.dental',
        [Environment.DEMO]: 'https://patient.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:8085',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8085),
    };
    return envToUrlMap[currentEnv];
}
exports.getPapoUrl = getPapoUrl;
function getCollectorUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_COLLECTOR_URL) {
        return process.env.VUE_APP_COLLECTOR_URL;
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:3001';
    }
    if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
        return currentRoseApiStatus.fallback.collector;
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://collector.rose.dental',
        [Environment.STAGING]: 'https://collector.staging.rose.dental',
        [Environment.REVIEW]: 'https://collector.review.rose.dental',
        [Environment.DEMO]: 'https://collector.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:3001',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(3001),
    };
    return envToUrlMap[currentEnv];
}
exports.getCollectorUrl = getCollectorUrl;
function getSaverUrl() {
    let currentEnv = getEnvironment();
    let envToUrlMap = {
        [Environment.PROD]: 'https://saver.rose.dental',
        [Environment.STAGING]: 'https://saver.staging.rose.dental',
        [Environment.REVIEW]: 'https://saver.review.rose.dental',
        [Environment.DEMO]: 'https://saver.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:3002',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(3002),
    };
    return envToUrlMap[currentEnv];
}
exports.getSaverUrl = getSaverUrl;
function getDoctosyncUrl() {
    let currentEnv = getEnvironment();
    // special case: DEMO
    if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_COLLECTOR_URL) {
        return process.env.VUE_APP_COLLECTOR_URL;
    }
    // special case localhostdemo
    if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
        return 'http://localhost:6001';
    }
    if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
        return currentRoseApiStatus.fallback.collector;
    }
    let envToUrlMap = {
        [Environment.PROD]: 'https://doctosync.rose.dental',
        [Environment.STAGING]: 'https://doctosync.staging.rose.dental',
        [Environment.REVIEW]: 'https://doctosync.review.rose.dental',
        [Environment.DEMO]: 'https://doctosync.staging.rose.dental',
        [Environment.DEV]: 'http://localhost:6001',
        [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(6001),
    };
    return envToUrlMap[currentEnv];
}
exports.getDoctosyncUrl = getDoctosyncUrl;
function getStripePublicKey() {
    const live_stripe_public_key = 'pk_live_MhA2TvVuMG0wLQW9ffiV9Zn2';
    const test_stripe_public_key = 'pk_test_wNcMlRJ6VnwiVXAFyz3iGugV';
    if (getEnvironment() === Environment.PROD) {
        return live_stripe_public_key;
    }
    return test_stripe_public_key;
}
exports.getStripePublicKey = getStripePublicKey;
exports.env = getEnvironment();
exports.isLocalhost = window.location.host.indexOf('localhost') >= 0;
function toLocalStoreageWrapper(urlGetter, key) {
    return () => {
        const url = urlGetter();
        localStorage.setItem(key, url);
        return url;
    };
}
/**
 * @deprecated Use metricsUrl instead
 */
exports.apiUrl = toLocalStoreageWrapper(getMetricsUrl, 'apiurl')();
exports.metricsUrl = toLocalStoreageWrapper(getMetricsUrl, 'metricsurl')();
exports.collectorUrl = getCollectorUrl();
exports.saverUrl = getSaverUrl();
exports.doctosyncUrl = getDoctosyncUrl();
exports.vuiUrl = getVuiUrl();
exports.uiUrl = getUiUrl();
exports.papoUrl = getPapoUrl();
exports.onboardingUrl = getOnboardingUrl();
exports.desktopR4cUrl = getDesktopR4cUrl();
exports.r4cUrl = getR4cUrl();
exports.r4cDispatchUrl = getR4cDispatcherUrl();
function getConf(r4chost) {
    return { env: exports.env, metricsUrl: exports.metricsUrl, vuiUrl: exports.vuiUrl, r4cApiUrl: getR4cApiUrl(r4chost), collectorUrl: exports.collectorUrl, uiUrl: exports.uiUrl, saverUrl: exports.saverUrl };
}
exports.getConf = getConf;
