"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeralFilter = void 0;
const lodash_1 = require("lodash");
const numeral_1 = require("../numeral");
exports.numeralFilter = {
    filters: {
        numeral(value, fractionDigits = 1, fallback = '-') {
            if ((0, lodash_1.isNil)(value)) {
                return fallback;
            }
            if ((0, lodash_1.isNumber)(value)) {
                value += 0.00001;
            }
            return (0, numeral_1.numeral)(value, { style: 'decimal', fractionDigits: fractionDigits });
        },
    },
};
