"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAbrechnungslabelsApi = void 0;
function createAbrechnungslabelsApi(api) {
    return {
        saveAbrechnungslabels: (patid, tag, regel, selectedLabels) => api.metrics.post(`/api/abrechnungslabels/saveabrechnungslabels`, {
            patid,
            tag,
            regel,
            selectedLabels,
        }),
        saveLabelConfig: (labelConfig) => api.metrics.post(`/api/abrechnungslabels/savelabelconfig`, { labelConfig }),
        getAbrechnungslabelsForPatient: (patid) => api.metrics.get(`/api/abrechnungslabels/getAbrechnungsLabelsForPat/${patid}`),
    };
}
exports.createAbrechnungslabelsApi = createAbrechnungslabelsApi;
