"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getXLSXFromBuffer = exports.getXLSXAsBase64String = exports.setRange = exports.setColumnWidths = exports.createMetaWorkSheet = exports.createEmptyWorkSheet = exports.getWorkSheetNames = exports.finalizeWorksheet = exports.finalizeSimpleXLSXWithMetadata = exports.setColumnOutlineLevel = exports.setRowOutlineLevel = exports.setRowOutlineGripOnTop = exports.mergeCells = exports.createCell = exports.zeroValueLightFGStyle = exports.valueCellStyle = exports.boldCellStyle = exports.h2CellStyle = exports.h1CellStyle = void 0;
const types_1 = require("../../../types");
// styles
exports.h1CellStyle = { bg: 'C0C0C0', bold: true, fontSize: 14 };
exports.h2CellStyle = { bg: 'E9E9E9', bold: true };
exports.boldCellStyle = { bold: true };
const valueCellStyle = (v, threshold = 0, lessThanZeroStyle = {}, zeroStyle = {}, greaterThanZeroStyle = {}) => {
    if (v === threshold) {
        return zeroStyle;
    }
    if (v > threshold) {
        return greaterThanZeroStyle;
    }
    return lessThanZeroStyle;
};
exports.valueCellStyle = valueCellStyle;
const zeroValueLightFGStyle = (v) => (0, exports.valueCellStyle)(v, 0, undefined, { fg: 'C0C0C0' });
exports.zeroValueLightFGStyle = zeroValueLightFGStyle;
function createCell(worksheet, col, row, val, unit, style) {
    let cell = worksheet.getRow(row + 1).getCell(col + 1);
    cell.value = val;
    switch (unit) {
        case types_1.Units.EURO:
            cell.numFmt = '#,##0.00 €';
            break;
        case types_1.Units.EUROROUNDED:
            cell.numFmt = '#,##0 €';
            break;
        case types_1.Units.PROZENTE:
            cell.numFmt = '#,##0.00 %';
            break;
        case types_1.Units.PROZENTEROUNDED:
            cell.numFmt = '#,##0 %';
            break;
        case types_1.Units.MINUTEN:
            cell.numFmt = '#,##0 "min"';
            break;
        case types_1.Units.STUNDEN:
            cell.numFmt = '#,##0.00 "h"';
            break;
        case types_1.Units.STUNDENROUNDED:
            cell.numFmt = '#,##0 "h"';
            break;
        case types_1.Units.DAYS:
            cell.numFmt = '#,##0.00 "D"';
            break;
        case types_1.Units.DAYSROUNDED:
            cell.numFmt = '#,##0 "d"';
            break;
        case types_1.Units.DATUM:
            if (val !== undefined && typeof val === 'string' && val.length > 0) {
                // should be date
                try {
                    const d = new Date(Date.parse(val.substring(0, 10)));
                    if (d instanceof Date && !isNaN(d.getTime())) {
                        cell.value = d;
                    }
                    else {
                        // console.log('dd', val, cell.value);
                    }
                }
                catch (e) {
                    console.log('error while dateconvert', cell.value);
                }
            }
            cell.numFmt = 'mm-dd-yy';
            break;
        case types_1.Units.TIME:
            if (val !== undefined && typeof val === 'string' && val.length > 0) {
                // should be date
                try {
                    const d = new Date(Date.parse(val.substring(0, 10)));
                    if (d instanceof Date && !isNaN(d.getTime())) {
                        cell.value = d;
                    }
                    else {
                        console.log('tt', val, cell.value);
                    }
                }
                catch (e) {
                    console.log('error while timeconvert', cell.value);
                }
            }
            cell.numFmt = 'h:mm';
            break;
        case types_1.Units.EURO_PRO_STUNDE:
        case types_1.Units.EURO_PRO_ZIMMER:
        case types_1.Units.EURO_PRO_TERMIN:
        case types_1.Units.FLOAT:
            cell.numFmt = '#,##0.00';
            break;
        case types_1.Units.TERMINE:
        case types_1.Units.PATIENTEN:
        case types_1.Units.IMPLANTATE:
        case types_1.Units.INTEGER:
            if (isNaN(cell.value)) {
                cell.value = 0;
            }
            cell.numFmt = '#,##0';
            break;
        case types_1.Units.ENTFERNUNG:
            cell.numFmt = '#,##0.00 "km"';
            break;
        case types_1.Units.NONE:
            break;
    }
    let useStyle = {};
    if (typeof style === 'function') {
        useStyle = style(val);
    }
    else if (style) {
        useStyle = style;
    }
    if (useStyle) {
        if (useStyle?.fg || useStyle?.bg) {
            // background color is foreground color of fill
            if (useStyle.bg) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: useStyle.bg },
                };
            }
            // foreground color is font color
            if (useStyle.fg) {
                cell.font = {
                    color: { argb: useStyle.fg },
                };
            }
        }
        if (useStyle?.bold) {
            cell.font = {
                ...cell.font,
                bold: true,
            };
        }
        if (useStyle?.fontSize) {
            cell.font = {
                ...cell.font,
                size: useStyle.fontSize,
            };
        }
    }
}
exports.createCell = createCell;
function mergeCells(worksheet, rowstart, colstart, rowend, colend) {
    try {
        worksheet.mergeCells(rowstart + 1, colstart + 1, rowend + 1, colend + 1);
    }
    catch (err) {
        console.error(`failed to merge cells`, rowstart, colstart, rowend, colend);
    }
}
exports.mergeCells = mergeCells;
function setRowOutlineGripOnTop(ws) {
    ws.properties.outlineProperties = {
        summaryBelow: false,
    };
}
exports.setRowOutlineGripOnTop = setRowOutlineGripOnTop;
function setRowOutlineLevel(worksheet, row, level) {
    if (level > 0) {
        worksheet.getRow(row + 1).outlineLevel = level;
    }
}
exports.setRowOutlineLevel = setRowOutlineLevel;
function setColumnOutlineLevel(worksheet, column, level) {
    if (level > 0) {
        worksheet.getColumn(column + 1).outlineLevel = level;
    }
}
exports.setColumnOutlineLevel = setColumnOutlineLevel;
function finalizeSimpleXLSXWithMetadata(workbook, worksheet, metaData, maxCol, maxRow, widths) {
    if (metaData) {
        createMetaWorkSheet(workbook, metaData);
    }
    finalizeWorksheet(worksheet, maxCol, maxRow, widths);
}
exports.finalizeSimpleXLSXWithMetadata = finalizeSimpleXLSXWithMetadata;
function finalizeWorksheet(worksheet, maxCol, maxRow, widths) {
    setColumnWidths(worksheet, widths);
    setRange(worksheet, maxCol, maxRow);
}
exports.finalizeWorksheet = finalizeWorksheet;
function getWorkSheetNames(workbook) {
    const names = [];
    workbook.eachSheet((worksheet, name) => {
        names.push(name);
    });
    return names;
}
exports.getWorkSheetNames = getWorkSheetNames;
function createEmptyWorkSheet(workbook, name) {
    // remove forbidden chars: * ? : \ / [ ]
    name = name?.replace(/[*?:\\/[\]]/g, '_') || '-';
    return workbook.addWorksheet(name);
}
exports.createEmptyWorkSheet = createEmptyWorkSheet;
/**
 * Creates a worksheet with metadata information.
 * @param workbook The workbook where the worksheet will be added.
 * @param meta The metadata object to be added to the worksheet.
 */
function createMetaWorkSheet(workbook, meta) {
    const worksheetTitle = 'Metadaten';
    const worksheet = workbook.addWorksheet(worksheetTitle, { properties: { tabColor: { argb: '337ab7' } } });
    // Setting up column titles and widths
    worksheet.columns = [
        { header: 'Information', key: 'key', width: 40 },
        { header: 'Wert', key: 'value', width: 80 },
    ];
    // Formatting the header for better visibility
    worksheet.getRow(1).font = { bold: true };
    // Adding metadata entries to the worksheet
    Object.entries(meta).forEach(([key, value], index) => {
        worksheet.addRow({ key, value });
        // Optional: Adjust cell formatting here
    });
    // Optional: Auto-adjust column widths (not directly supported by exceljs, but you can implement a function for it)
    autoAdjustColumnWidths(worksheet);
}
exports.createMetaWorkSheet = createMetaWorkSheet;
// Example of an auto-adjust column widths function (implementation depends on your needs)
function autoAdjustColumnWidths(worksheet) {
    // @ts-ignore
    worksheet.columns.forEach(column => {
        let maxLength = 0;
        // @ts-ignore
        column.eachCell({ includeEmpty: true }, cell => {
            let cellLength = cell.value ? cell.value.toString().length : 10;
            if (cellLength > maxLength)
                maxLength = cellLength;
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
    });
}
function setColumnWidths(worksheet, widths) {
    if (widths) {
        worksheet.columns = widths.map(w => ({ width: w.wpx * 0.2 }));
    }
    return [];
}
exports.setColumnWidths = setColumnWidths;
function setRange(worksheet, c, r) {
    worksheet.properties.columnCount = c;
    worksheet.properties.rowCount = r;
}
exports.setRange = setRange;
async function getXLSXAsBase64String(wb) {
    const buffer = await wb.xlsx.writeBuffer();
    return buffer.toString('base64');
}
exports.getXLSXAsBase64String = getXLSXAsBase64String;
async function getXLSXFromBuffer(wb, buffer) {
    return await wb.xlsx.load(buffer);
}
exports.getXLSXFromBuffer = getXLSXFromBuffer;
