"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHelpdeskApi = void 0;
function createHelpdeskApi(api) {
    return {
        getCompany: (id) => api.metrics.get(`/api/helpdesk/companies/${id}`),
        getCompanies: () => api.metrics.get(`/api/helpdesk/companies`),
        getContacts: (companyId) => api.metrics.get(`/api/helpdesk/contacts/${companyId}`),
        getTickets: (companyId) => api.metrics.get(`/api/helpdesk/tickets/${companyId}`),
        createTicket: (ticket) => api.metrics.post(`/api/helpdesk/tickets`, ticket),
        updateContact: (contact) => api.metrics.put(`/api/helpdesk/contact`, contact),
        updateCompany: (company) => api.metrics.put(`/api/helpdesk/company`, company),
    };
}
exports.createHelpdeskApi = createHelpdeskApi;
