"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createContactApi = void 0;
function createContactApi(api) {
    return {
        getContacts: () => api.metrics.get(`/api/contact`),
        createContact: (contact) => api.metrics.put(`/api/contact`, { contact }),
        updateContact: (contactEmail, contact) => api.metrics.post(`/api/contact`, { contact, contactEmail }),
        deleteContact: (contactMail) => api.metrics.delete(`/api/contact`, { contactMail }),
    };
}
exports.createContactApi = createContactApi;
