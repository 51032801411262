"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const vue_1 = require("vue");
const onboardingStore_1 = require("@/state/onboardingStore");
const pakete_1 = require("@/state/pakete");
const common_ui_1 = require("../../../common-ui");
const types_1 = require("../../../types");
const tasks_1 = require("@/state/tasks");
exports.default = (0, vue_1.defineComponent)({
    props: ['viewParams'],
    components: {},
    data: () => ({
        selectedFeatures: {
            [types_1.R4CAPP.FACTORING]: types_1.FEATURES.NONE,
            [types_1.R4CAPP.DOCUMENTS]: types_1.FEATURES.NONE,
            [types_1.R4CAPP.ANAMNESE]: types_1.FEATURES.NONE,
        },
        onboardingStore: onboardingStore_1.onboardingStore,
        isDev: common_ui_1.isDev,
        isStaging: common_ui_1.isStaging,
        isProd: common_ui_1.isProd,
        FEATURES: types_1.FEATURES,
        R4CAPP: types_1.R4CAPP,
        currentTabIdx: 0,
        lastTab: types_1.R4CAPP.DOCUMENTS,
        proceedToRechenzentren: false,
    }),
    mounted() {
        console.log('query', this.$route.query.d);
        console.log('viewParams', this.viewParams);
        if (this.editR4cApps) {
            // set current packages
            if (onboardingStore_1.onboardingStore.getters.factoringActive) {
                this.selectedFeatures[types_1.R4CAPP.FACTORING] = onboardingStore_1.onboardingStore.getters.factoringBasicActive
                    ? types_1.FEATURES.FACTORING
                    : types_1.FEATURES.FACTORINGEWE;
                // if we already have rz, then do not enable rz step
                onboardingStore_1.onboardingStore.commit.setSkipStepByStepId({ stepId: tasks_1.StepId.r4cRechenzentren, skip: true });
            }
            if (onboardingStore_1.onboardingStore.getters.documentsActive) {
                if (onboardingStore_1.onboardingStore.getters.documentsSmallActive) {
                    this.selectedFeatures[types_1.R4CAPP.DOCUMENTS] = types_1.FEATURES.DOCUMENTS_SMALL;
                }
                if (onboardingStore_1.onboardingStore.getters.documentsBigActive) {
                    this.selectedFeatures[types_1.R4CAPP.DOCUMENTS] = types_1.FEATURES.DOCUMENTS_BIG;
                }
                if (onboardingStore_1.onboardingStore.getters.documentsFlatActive) {
                    this.selectedFeatures[types_1.R4CAPP.DOCUMENTS] = types_1.FEATURES.DOCUMENTS_FLAT;
                }
            }
        }
        this.setSkipStep();
        // this view cannot be invalid
        this.$emit('viewValidUpdate', true);
    },
    computed: {
        paketeR4cToShow() {
            return (0, lodash_1.filter)(pakete_1.paketeR4c, app => !common_ui_1.isProd || app.live);
        },
        tabs() {
            const tabs = [];
            if (!this.editR4cApps) {
                tabs.push('highlights');
            }
            for (const app of this.paketeR4cToShow) {
                tabs.push(app.id);
            }
            return tabs;
        },
        currentTab() {
            return this.tabs[this.currentTabIdx];
        },
        selectedApp() {
            return pakete_1.paketeR4cMap[this.currentTab];
        },
        selectedFeatureMatrix() {
            if (this.currentTab !== 'highlights') {
                return (0, pakete_1.featureMatrix)(pakete_1.paketeR4cMap[this.currentTab]);
            }
            return undefined;
        },
        hasFactoringAndAnamnese() {
            return (this.selectedFeatures[types_1.R4CAPP.ANAMNESE] === types_1.FEATURES.ANAMNESE_DIAMOND &&
                this.selectedFeatures[types_1.R4CAPP.FACTORING] === types_1.FEATURES.FACTORINGEWE);
        },
        priceFactoringAndAnamnese() {
            const factoringEweChoice = (0, pakete_1.findAppChoice)(types_1.R4CAPP.FACTORING, types_1.FEATURES.FACTORINGEWE) || { price: 0 };
            const anamneseChoice = (0, pakete_1.findAppChoice)(types_1.R4CAPP.ANAMNESE, types_1.FEATURES.ANAMNESE_DIAMOND) || { price: 0 };
            return (factoringEweChoice === null || factoringEweChoice === void 0 ? void 0 : factoringEweChoice.price) + (anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price);
        },
        priceAnamneseDiamond() {
            const anamneseChoice = (0, pakete_1.findAppChoice)(types_1.R4CAPP.ANAMNESE, types_1.FEATURES.ANAMNESE_DIAMOND) || { price: 0 };
            return anamneseChoice === null || anamneseChoice === void 0 ? void 0 : anamneseChoice.price;
        },
        priceFactoring() {
            const factoringChoice = (0, pakete_1.findAppChoice)(types_1.R4CAPP.FACTORING, types_1.FEATURES.FACTORINGEWE) || { price: 0 };
            return factoringChoice === null || factoringChoice === void 0 ? void 0 : factoringChoice.price;
        },
        priceDocuments() {
            const docsChoice = (0, pakete_1.findAppChoice)(types_1.R4CAPP.DOCUMENTS, types_1.FEATURES.DOCUMENTS_FLAT) || { price: 0 };
            return docsChoice === null || docsChoice === void 0 ? void 0 : docsChoice.price;
        },
        editR4cApps() {
            var _a;
            return !!((_a = this.viewParams) === null || _a === void 0 ? void 0 : _a.editR4cApps);
        },
        somethingMeaningfulWasSelected() {
            const res = !(0, lodash_1.chain)(this.selectedFeatures)
                .values()
                .filter(f => f !== types_1.FEATURES.NONE)
                .isEmpty()
                .value() || this.editR4cApps;
            console.log('somethingMeaningfulWasSelected', this.selectedFeatures, res);
            return res;
        },
    },
    methods: {
        selectFeature(appId, feature, reset = false) {
            this.proceedToRechenzentren = false;
            if (reset) {
                for (const appId in this.selectedFeatures) {
                    if (Object.prototype.hasOwnProperty.call(this.selectedFeatures, appId)) {
                        this.selectedFeatures[appId] = types_1.FEATURES.NONE;
                    }
                }
            }
            this.selectedFeatures[appId] = feature;
            this.setSkipStep();
        },
        selectFactoringAndAnamnese() {
            this.selectedFeatures[types_1.R4CAPP.FACTORING] = types_1.FEATURES.FACTORINGEWE;
            this.selectedFeatures[types_1.R4CAPP.ANAMNESE] = types_1.FEATURES.ANAMNESE_DIAMOND;
            this.proceedToRechenzentren = true;
            console.log('selectFactoringAndAnamnese', this.selectedFeatures, this.proceedToRechenzentren);
            this.setSkipStep();
        },
        hasApp(app) {
            return (0, onboardingStore_1.hasApp)(app);
        },
        hasAppFeature(app, feature) {
            return (0, onboardingStore_1.hasAppFeature)(app, feature);
        },
        hasSelectedFeatures(appId) {
            var _a;
            // check if other include features from this app
            const thisAppsFeatures = (0, lodash_1.map)((_a = pakete_1.paketeR4cMap[appId]) === null || _a === void 0 ? void 0 : _a.choices, c => c.feature);
            const otherApps = (0, lodash_1.chain)(pakete_1.paketeR4cMap)
                .keys()
                .filter(k => k !== appId)
                .value();
            const otherAppSelectedFeatures = (0, lodash_1.chain)(otherApps)
                .map(oa => this.selectedFeatures[oa])
                .compact()
                .filter(f => f !== types_1.FEATURES.NONE)
                .value();
            const otherAppCheck = (0, lodash_1.chain)(otherAppSelectedFeatures)
                .flatMap(f => (0, pakete_1.findAppChoiceByFeature)(f))
                .compact()
                .flatMap(c => c.includesOtherFeatures)
                .compact()
                .value();
            // console.log(
            //   'otherAppSelectedFeatures',
            //   appId,
            //   thisAppsFeatures,
            //   otherApps,
            //   otherAppSelectedFeatures,
            //   otherAppCheck,
            // );
            if (!(0, lodash_1.isEmpty)((0, lodash_1.intersection)(thisAppsFeatures, otherAppCheck))) {
                return true;
            }
            return this.selectedFeatures[appId] !== types_1.FEATURES.NONE;
        },
        isFeatureSelected(appId, feature) {
            return this.selectedFeatures[appId] === feature || this.isFeatureAlreadySelectedElseWhere(appId, feature);
        },
        isFeatureAlreadySelectedElseWhere(appId, feature) {
            return (0, pakete_1.isFeatureAlreadySelectedElseWhere)(appId, feature, this.selectedFeatures).included;
        },
        setSkipStep() {
            const skip = this.selectedFeatures[types_1.R4CAPP.FACTORING] === types_1.FEATURES.NONE || (0, lodash_1.isNil)(this.selectedFeatures[types_1.R4CAPP.FACTORING]);
            if (!onboardingStore_1.onboardingStore.getters.factoringActive) {
                onboardingStore_1.onboardingStore.commit.setSkipStepByStepId({ stepId: tasks_1.StepId.r4cRechenzentren, skip });
            }
        },
        persistData() {
            onboardingStore_1.onboardingStore.commit.setFactoringFeature(this.selectedFeatures[types_1.R4CAPP.FACTORING]);
            onboardingStore_1.onboardingStore.commit.setDocumentFeature(this.selectedFeatures[types_1.R4CAPP.DOCUMENTS]);
            onboardingStore_1.onboardingStore.commit.setAnamneseFeature(this.selectedFeatures[types_1.R4CAPP.ANAMNESE]);
        },
        goto(id) {
            const e = document === null || document === void 0 ? void 0 : document.getElementById(id);
            console.log('goto', document, e);
            e === null || e === void 0 ? void 0 : e.scrollIntoView();
        },
        nextTab() {
            this.currentTabIdx++;
        },
        next() {
            this.$emit('next');
            // since this was the first interaction add unload handler here
            if (common_ui_1.isProd || common_ui_1.isStaging) {
                window.onbeforeunload = () => {
                    return 'Sind sie sicher, dass Sie den Vorgang abbrechen wollen?';
                };
            }
        },
    },
});
