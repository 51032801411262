var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.gutscheinInfo ? _c('div', {
    staticClass: "gutschein-info"
  }, [_c('v-alert', {
    attrs: {
      "type": _vm.gutscheinInfo.type
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.gutscheinInfo.msg))])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }