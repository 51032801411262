"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.betterChartColors = exports.superChartColors = exports.superChartOrange = exports.superChartBlau = exports.chartColorsReverse = exports.chartColors = exports.twentySimpleDistinctColors = exports.black = exports.white = exports.grey = exports.navy = exports.coral = exports.olive = exports.mint = exports.maroon = exports.beige = exports.brown = exports.lavender = exports.teal = exports.pink = exports.lime = exports.magenta = exports.cyan = exports.purple = exports.orange = exports.blue = exports.yellow = exports.green = exports.red = void 0;
const lodash_1 = require("lodash");
// https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
exports.red = { hex: '#e6194b', rgb: { r: 230, g: 25, b: 75 } };
exports.green = { hex: '#3cb44b', rgb: { r: 60, g: 180, b: 75 } };
exports.yellow = { hex: '#ffe119', rgb: { r: 255, g: 225, b: 25 } };
exports.blue = { hex: '#0082c8', rgb: { r: 0, g: 130, b: 200 } };
exports.orange = { hex: '#f58231', rgb: { r: 245, g: 130, b: 48 } };
exports.purple = { hex: '#911eb4', rgb: { r: 145, g: 30, b: 180 } };
exports.cyan = { hex: '#46f0f0', rgb: { r: 70, g: 240, b: 240 } };
exports.magenta = { hex: '#f032e6', rgb: { r: 240, g: 50, b: 230 } };
exports.lime = { hex: '#d2f53c', rgb: { r: 210, g: 245, b: 60 } };
exports.pink = { hex: '#fabebe', rgb: { r: 250, g: 190, b: 190 } };
exports.teal = { hex: '#008080', rgb: { r: 0, g: 128, b: 128 } };
exports.lavender = { hex: '#e6beff', rgb: { r: 230, g: 190, b: 255 } };
exports.brown = { hex: '#aa6e28', rgb: { r: 170, g: 110, b: 40 } };
exports.beige = { hex: '#fffac8', rgb: { r: 255, g: 250, b: 200 } };
exports.maroon = { hex: '#800000', rgb: { r: 128, g: 0, b: 0 } };
exports.mint = { hex: '#aaffc3', rgb: { r: 170, g: 255, b: 195 } };
exports.olive = { hex: '#808000', rgb: { r: 128, g: 128, b: 0 } };
exports.coral = { hex: '#ffd8b1', rgb: { r: 255, g: 215, b: 180 } };
exports.navy = { hex: '#000080', rgb: { r: 0, g: 0, b: 128 } };
exports.grey = { hex: '#808080', rgb: { r: 128, g: 128, b: 128 } };
exports.white = { hex: '#FFFFFF', rgb: { r: 255, g: 255, b: 255 } };
exports.black = { hex: '#000000', rgb: { r: 0, g: 0, b: 0 } };
exports.twentySimpleDistinctColors = [
    exports.red,
    exports.green,
    exports.yellow,
    exports.blue,
    exports.orange,
    exports.purple,
    exports.cyan,
    exports.magenta,
    exports.lime,
    exports.pink,
    exports.teal,
    exports.lavender,
    exports.brown,
    exports.beige,
    exports.maroon,
    exports.mint,
    exports.olive,
    exports.coral,
    exports.navy,
    exports.grey,
    // white,
    exports.black,
];
// from https://blog.graphiq.com/finding-the-right-color-palettes-for-data-visualizations-fcd4e707a283
// fg does not like these
exports.chartColors = [
    '#d8edc6',
    '#a6dfcb',
    '#75cfcf',
    '#38c0d5',
    '#00acd2',
    '#0092c3',
    '#0e77b3',
    '#1b5da3',
    '#1b4293',
    '#192984',
    '#151e69',
    '#10154e',
];
exports.chartColorsReverse = (0, lodash_1.reverse)([...exports.chartColors]); // copy array, reverse changes it
// better but not enough
exports.superChartBlau = '#006590';
exports.superChartOrange = '#f2a538';
exports.superChartColors = [exports.superChartBlau, exports.superChartOrange];
// likable chart colors
exports.betterChartColors = ['#724F73', '#7C6C8C', '#03658C', '#5EA4BF', '#ADCCD9', '#dedede'];
