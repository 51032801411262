"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStripeApi = void 0;
function createStripeApi(api) {
    return {
        loadStripeCustomers: () => api.metrics.get(`/api/stripe/customers`),
        loadStripeCustomer: (id) => api.metrics.get(`/api/stripe/customer/${id}`),
        loadStripeCustomerInvoices: (id, nocache) => api.metrics.get(`/api/stripe/invoices/customer/${id}`, { query: { nocache } }),
        loadStripeSubscriptionInvoices: (id, nocache) => api.metrics.get(`/api/stripe/invoices/subscription/${id}`, { query: { nocache } }),
        loadStripeInfoComplete: (id, nocache) => api.metrics.get(`/api/stripe/info/${id}`, { query: { nocache } }),
        loadStripeSubscription: (id, nocache) => api.metrics.get(`/api/stripe/subscription/${id}`, { query: { nocache } }),
        loadStripeCustomerPaymentMethods: (stripeCustomerId) => api.metrics.get(`/api/stripe/paymentmethods/${stripeCustomerId}`),
        loadStripeCustomerDefaultPaymentMethod: (stripeCustomerId) => api.metrics.get(`/api/stripe/paymentmethod/${stripeCustomerId}`),
        changeSubscriptionTrialEnd: (id, trialend) => api.metrics.post(`/api/stripe/subscription/${id}/changetrialend`, { trialend }),
        changeClientIban: (id, paymentMethod) => api.metrics.post(`/api/stripe/changeiban/${id}`, { paymentMethod }),
        changeClientEmail: (id, newemail) => api.metrics.post(`/api/stripe/changeemail/${id}`, { newemail }),
        changeClientName: (id, name) => api.metrics.post(`/api/stripe/changename/${id}`, { name }),
        changeClientAddress: (id, address) => api.metrics.post(`/api/stripe/changeaddress/${id}`, { address }),
        moveStripeSubscriptions: (sourceCustomerId, targetCustomerId) => api.metrics.post(`/api/stripe/movesubscriptions`, { sourceCustomerId, targetCustomerId }),
        createPaymentIntent: () => api.metrics.get(`/api/onboarding/createPaymentIntent`),
        deleteStripeSubscription: (subscriptionId, immediate, endOfTurnus, deleteDate) => api.metrics.delete(`/api/stripe/subscription/${subscriptionId}`, {
            immediate,
            endOfTurnus,
            deleteDate,
        }),
        rewriteInvoice: (invoiceid, gutschrifttext, rechnungstext) => api.metrics.post(`/api/stripe/rewriteinvoice/${invoiceid}`, { gutschrifttext, rechnungstext }),
        remailInvoice: (invoiceid) => api.metrics.get(`/api/stripe/remailinvoice/${invoiceid}`),
        createMetricsStripeSubscription: (cid, stripeCustomerId, startDate, gutscheincode, multiclientlogin, savesetupfee) => api.metrics.post(`admin/metricsAboAnlegen/${cid}`, {
            stripeCustomerId,
            startDate,
            gutscheincode,
            multiclientlogin,
            savesetupfee,
        }),
        createR4CStripeSubscription: (cid, factoringFeature, trialDays, startDate, additionalFeatures = []) => api.r4cDispatch.post('admin/r4caboanlegen', {
            cid,
            factoringFeature,
            trialDays,
            startDate,
            additionalFeatures,
        }),
        createStripeAccount4OnboardingEntry: (id, reuseExistingStripeCustomer) => api.metrics.post(`admin/onboarding/createstripeaccount`, { id, reuseExistingStripeCustomer }),
        createStripeAccount4CID: (cid, reuseExistingStripeCustomer) => api.metrics.post(`/api/stripe/customer/create`, { cid, reuseExistingStripeCustomer }),
    };
}
exports.createStripeApi = createStripeApi;
