"use strict";
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeral = void 0;
function numeral(value, options) {
    var _a, _b;
    return new Intl.NumberFormat('de-DE', {
        style: options === null || options === void 0 ? void 0 : options.style,
        currency: options === null || options === void 0 ? void 0 : options.currency,
        minimumFractionDigits: (_a = options === null || options === void 0 ? void 0 : options.fractionDigits) !== null && _a !== void 0 ? _a : 2,
        maximumFractionDigits: (_b = options === null || options === void 0 ? void 0 : options.fractionDigits) !== null && _b !== void 0 ? _b : 2,
    }).format(Number(value));
}
exports.numeral = numeral;
